<template lang="pug">
widget-wrapper(:wait="allocation" disclaimer="Croissance Diversifiée - FR0007480389 - Part R/A (EUR)")
  .flex.flex-col.bg-white.min-h-screen.p-4.gap-4
    .flex.flex-row.items-center.justify-between.mb-4
      .flex.flex-row.items-center.justify-start
        h2 {{ $root.t['Fund allocation for'] }}:
        select.border.border-gray-200.rounded-md.pl-4.ml-4.py-1.text-lg(style="height: min-content" class="focus:ring-primary" v-model="activeDimension")
          option(v-for="dim in dimensions" :value="dim") {{ t[dim] || dim }}
      .flex.flex-row.items-center.gap-8
        date-picker.left(v-model="activeDate" :domain="[startDate, endDate]")
          template(#default="{ date, showModal }")
            .flex.flex-row.items-center.gap-2.cursor-pointer.py-1.px-2.border.border-gray-200.rounded-md(style="height: 38px;" @click="showModal")
              span.font-bold {{ date.format('day, mon, year', $root.lang) }}
              svg-icon.text-gray-400.fill-current(name="ic_date_range")
        download-button(:items="downloadItems")
    .flex.flex-col.h-full.items-center.justify-center.flex-1(v-if="['init', 'loading_breakdown'].includes(state)")
      loader
    .flex.flex-row(v-else)
      dice-pie-chart.flex-1(:data="pieData" @hover="ev => hover = ev.i" @chart="ev => chart = ev")
      .flex.flex-col.items-start.p-4.justify-start(style="flex: 1.61;")
        dice-table.small.table(:metadata="legendMetadata" :columns="legendColumns" :data="sortedAllocation" style="max-width: 560px;")
          template(#body="{ tableData, metadata, columns, getCell, expands, expanded }")
            //- div {{ tableData }}
            template(v-for="row, idx in tableData" :key="idx")
              .parent-row.flex.flex-col.border-l-4.cursor-pointer(
                :style="{ 'border-left-color' : metadata?.borderLeftColor(row, idx) }" 
                :class="{ active: metadata?.isRowActive?.(row, idx), expanded: expanded === idx }" 
                @mouseover="onRowHover(row, row.idx)"
                @mouseleave="onRowHover(null, row.idx)"
                @click="focusedRow = row;"
              )    
                tr.main-content
                  template(v-for="col, colIdx in columns" :key="colIdx")
                    td.px-4.py-2.whitespace-nowrap.default(v-if="col.key === 'key'")
                      .flex.flex-row.gap-2.items-center
                        .text-gray-400.text-right(style="min-width: 20px;") {{ idx+1 }}.
                        .color.w-4.h-4.rounded-sm.bg-black(:style="{ background: row.color }")
                        span.flex-1(v-html="getCell(col, row)")
                    td.px-6.py-2(colspan="2" v-else-if="isHovered(row) && colIdx === 1")
                      .text-right.text-gray-600.underline {{$root.t['Click to see history']}}
                    td.text-center.px-6.py-2.whitespace-nowrap.default(v-else-if="!isHovered(row)")
                      span.text-center(v-html="getCell(col, row)")
                tr.hovered.grid-cols-1
                  td(colspan="3")
                    .flex.flex-col.gap-1(class="w-3/4")
                      .flex.flex-row.h-4.items-center.gap-1
                        div(style="min-width: 20px;")
                        div(style="min-width: 64px;") {{ $root.t.ptfWeight }}
                        .bar.h-full(:style="{ background: row.color, width: getBarWidth(row) }")
                        div {{ format('.2%')(row.ptfWeight) }}
                      .flex.flex-row.h-4.items-center.gap-1
                        div(style="min-width: 20px;")
                        div(style="min-width: 64px;") Index
                        .bar.h-full.bg-gray-300(:style="{ width: getBarWidth(row, true) }")
                        div {{ format('.2%')(row.bmkWeight) }}    
        .chart-disclaimer.w-full(style="max-width: 560px;") {{ $root.t['Weight in % of AUM']}}
    transition(name="modal-slide" appear)
      widget-modal(v-model="showHistory")
        .flex.flex-col.h-full.items-center.justify-center(v-if="state === 'loading_history'")
          loader
        .chart.p-4.flex.flex-col.h-full(v-else-if="focusedRow")
          h1.mb-4 {{ $root.t['Allocation history for'] }} {{ focusedRow.key }}
          cjs-wrapper.no-tooltip.bg-white.h-full.flex-1(v-if="historyMetadata" :data="historyData" :metadata="historyMetadata")

</template>
<script>
import { useEmbedComponent } from '@/composables/embed.js'
// import { getDimension, getBreakdownLevel, apiCall, getClassification } from '@/composables/bone'
import { getCssVar } from '@/composables/utils'

import { computed, ref, watch, reactive } from 'vue'

async function getAllocation(portfCode, _dimension, date, drilldown) {
  const dimension = 'Country'
  const level = getBreakdownLevel(dimension)
  const _date = new Date(date).format('YYYYMMDD')
  console.log('level', level)
  const rawData = await apiCall('allocation', { portfCode, date: _date }, null)
  window.rawData = rawData
  const axes = ['ptfWeight', 'bmkWeight']
  if (rawData.error) return { error: rawData.error }
  const data = getClassification(rawData, 1)
    .filter(d => axes.some(axis => d[axis] && d[axis] >= 0))
    .group(v => (v.dimension === 'Unassigned Group' ? 'NA' : v.dimension))
  const allocationData = data.__.map((values, dim) => {
    return axes.reduce((acc, axis) => {
      acc[axis] = values.sum(axis)
      return acc
    }, {})
  })
  if (!drilldown) return { allocationData }
  const drilldownLevel = drilldownLevels[dimension] || level + 1
  const _drilldownData = getClassification(rawData, drilldownLevel)
  const drilldownData = data.__.map((values, key) => {
    const value = values.sum('ptfWeight')
    // debugger
    const children = _drilldownData
      .filter(d => d.classification['level' + level] === key && d['ptfWeight'])
      .group(d => (d.dimension === 'Unassigned Group' ? 'NA' : d.dimension))
      .__.map((values, dim) => ({
        key: dim,
        value: values.sum('ptfWeight'),
      }))
    return {
      key,
      value,
      children,
    }
  })
    .__.v()
    .sort(d => -d.value)
  return { allocationData, drilldownData }
}

function getBarWidth(row, benchmark = false) {
  const max = Math.max(row.ptfWeight || 0, row.bmkWeight || 0)
  // const min = Math.max(row.ptfWeight || 0, row.bmkWeight || 0)
  if (max === 0) return 0
  const key = benchmark ? 'bmkWeight' : 'ptfWeight'
  return format('.2%')(row[key])
  return format('.2%')(row[key] / max)
}
export default {
  setup() {
    const state = ref('init') // init - ready - loading_breakdown - loading_history
    const hover = ref()
    const chart = ref()
    const config = useEmbedComponent('widgets.breakdown')
    const { dimensions, activeDimension, portfCode, date, watcher } = config
    activeDimension.value = activeDimension.value || dimensions.value[0]
    const endDate = date.value
    const startDate = endDate.minus('5 years')
    const activeDate = ref(endDate)

    const allocation = ref()
    const sortedAllocation = computed(() => {
      return allocation?.value
        ?.map((d, i) => {
          d.ptfWeight = d.ptfWeight || 0
          return d
        })
        .sort('-ptfWeight')
        .map((d, i) => {
          d.color = `var(--cat${i + 1})`
          d.idx = i
          return d
        })
    })
    const pieData = computed(() => {
      return allocation?.value
        ?.filter(d => d?.ptfWeight)
        .map(d => {
          return {
            key: d.key,
            value: d.ptfWeight,
          }
        })
    })
    const legendColumns = [
      { key: 'key', name: activeDimension.value, size: 3 },
      { key: 'ptfWeight', name: 'ptfWeight', format: d => (!!d ? format('.2%')(d) : '-') },
      { key: 'bmkWeight', name: 'bmkWeight', format: d => (!!d ? format('.2%')(d) : '-') },
    ]
    const legendMetadata = {
      sortKey: 'ptfWeight',
      borderLeftColor: (row, idx) => (idx === hover.value ? row.color : 'transparent'),
      isRowActive: (row, idx) => idx === hover.value,
      max: 9,
    }
    function onRowHover(row, idx) {
      if (!chart?.value) return
      if (row) {
        if (hover.value === idx) return
        hover.value = idx
        chart.value?.handleMouseOver(row, idx)
        return
      }
      chart.value?.handleMouseOut(row, idx)
      hover.value = null
    }
    async function getData() {
      if (!activeDimension.value || !portfCode.value) return
      state.value = 'loading_breakdown'
      const { error, allocationData, drilldownData } = await getAllocation(portfCode.value, activeDimension.value, activeDate.value)
      if (error) {
        allocation.value = error
        // drilldownTable.value = null
        return
      }
      allocation.value = Object.entries(allocationData).reduce((acc, entry) => {
        const [key, value] = entry
        acc.push({ key, ...value })
        return acc
      }, [])
      state.value = 'ready'
      // drilldownTableData.value = drilldownData
    }
    async function getAllocationHistory() {
      console.log('starting history')
      state.value = 'loading_history'
      const dates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(d => activeDate.value.minus(d + ' days'))
      const dataArray = await Promise.all(dates.map(date => getAllocation(portfCode.value, 'Country', date)))
      window.dataArray = dataArray
      state.value = 'ready'
      console.log('done history')
    }

    const showHistory = ref(false)
    const focusedRow = ref()
    watch(focusedRow, () => {
      if (focusedRow.value) showHistory.value = true
    })
    watch(showHistory, () => {
      if (!showHistory.value) focusedRow.value = null
      if (showHistory.value) getAllocationHistory()
    })

    let previous = 0
    function randomWalk(a, b) {
      if (previous < a || previous > b) previous = 0
      // 1/2 chance tos tay the same
      if (previous && Math.random() < 0.5) return previous
      const rand = Math.random()
      const range = b - a
      const value = rand * range + a
      previous = value
      return previous
    }
    const historyData = [1, 2, 3, 4, 5, 6, 7, 8].map(d => {
      const date = 2013 + d + '-01-01'
      const ptfWeight = randomWalk(0.13, 0.16)
      const bmkWeight = randomWalk(0.12, 0.17)
      return { date, ptfWeight, bmkWeight }
    })
    const historyMetadata = computed(() => {
      if (!focusedRow.value) return
      const plots = [
        {
          type: 'line',
          yAxisKey: 'ptfWeight',
          color: getCssVar(focusedRow?.value?.color),
          options: {
            tension: 0,
            radius: 4,
            borderWidth: 4,
          },
        },
        {
          type: 'line',
          yAxisKey: 'bmkWeight',
          color: 'rgba(209, 213, 219)',
          options: {
            tension: 0,
            radius: 4,
          },
        },
      ]

      return {
        xAxisKey: 'date',
        xAxisType: 'time',
        yAxisFormat: format('.2%'),
        plots,
        grid: {
          x: {
            color: 'rgba(0,0,0,0.1)',
            style: 'dash',
          },
          y: {
            color: 'rgba(0,0,0,0.1)',
            style: 'dash',
          },
        },
        zoom: {
          onZoom: d => console.log('zoom', d),
        },
        guideline: true,
        options: {
          animation: {
            duration: 0,
          },
        },
      }
    })

    getData()
    watcher(getData)
    watch(activeDate, getData)

    function isHovered(row) {
      return row.idx === hover.value
    }
    const downloadItems = computed(() => {
      return [{ name: $root.t.allocation, data: sortedAllocation.value }]
    })

    return {
      chart,
      onRowHover,
      isHovered,
      hover,
      allocation,
      sortedAllocation,
      pieData,
      ...config,
      activeDimension,
      legendColumns,
      legendMetadata,
      getBarWidth,
      endDate,
      startDate,
      activeDate,
      showHistory,
      focusedRow,
      historyData,
      historyMetadata,
      downloadItems,
      state,
      getAllocationHistory,
    }
  },
}
</script>
<style scoped>
.table tr {
  display: grid;
  grid-template-columns: minmax(120px, 3fr) repeat(auto-fit, minmax(100px, 1fr));
  width: 100%;
}

.table tr.active {
  box-shadow: 0 -2px 4px -1px rgba(0, 0, 0, 0.1), 0 -1px 2px -1px rgba(0, 0, 0, 0.06);
}
.table td span {
  max-width: 220px;
}
.table .parent-row .hovered td {
  /* opacity: 0; */
  /* transition: padding-top 0.15s ease-in, padding-bottom 0.15s ease-in, max-height 0.15s ease-in; */
  transition: opacity 0s;
  max-height: 0;
  overflow: hidden;
  @apply p-0;
}
.table .parent-row:hover,
.table .parent-row.active {
  @apply shadow-md;
}
.table .parent-row:hover tr.main-content,
.table .parent-row.active tr.main-content {
  grid-template-columns: 2fr 2fr;
}
.table .parent-row:hover .hovered td,
.table .parent-row.active .hovered td {
  /* opacity: 1; */
  /* transition: opacity 0.2s ease-in, max-height 0s; */
  max-height: 400px;
  @apply p-2;
}
.table .parent-row .hovered td .bar {
  max-width: 0;
  transition: max-width 0.3s ease-out;
}
.table .parent-row:hover .hovered td .bar,
.table .parent-row.active .hovered td .bar {
  max-width: 100%;
}
.wait-transition-enter-active,
.wait-transition-leave-active {
  overflow: hidden;
  transition: none 2s;
  /* transition-delay: 2s; */
}
.wait-transition-enter,
.wait-transition-leave-to {
  opacity: 0;
}
.slide-bar-enter-active,
.slide-bar-leave-active,
.slide-bar-enter-to {
  overflow: hidden;
  transition: opacity 2s;
  opacity: 1;
  /* max-width: 400px; */
}
.slide-bar-enter,
.slide-bar-leave-to {
  opacity: 0;
}
.slide-bar-enter-active .bar,
.slide-bar-leave-active .bar {
  overflow: hidden;
  transition: opacity 2s;
  /* max-width: 400px; */
}
.slide-bar-enter .bar,
.slide-bar-leave-to .bar {
  opacity: 0;
}

.modal-slide-leave-active,
.modal-slide-enter-active {
  transition: all 0.2s ease-in-out;
}

.modal-slide-leave-to,
.modal-slide-enter-from {
  @apply border-l border-gray-500;
  transform: translateX(100%);
  overflow: hidden;
}
</style>
