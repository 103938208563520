<template lang="pug">
pre {{ performance }} ?
</template>
<script>
import { getPerformance } from '@/composables/bone'
export default {
  setup() {
    const performance = getPerformance('IFM_111')
    return {
      performance,
    }
  },
}
</script>
