<template lang="pug">
pre
  div TAG: {{ tag }}
  div BACKEND_URL: {{ backendUrl }}
</template>
<script>
export default {
  setup() {
    const backendUrl = localStorage.BACKEND_URL || 'Not set'
    const tag = localStorage.TAG_VERSION || 'not set'
    return { backendUrl, tag }
  },
}
</script>
