import dotenv from 'dotenv'
import axios from 'axios'
const ENV_VARIABLES = ['BACKEND_URL']

export default async function useEnv() {
  const rawConfig = await axios.get('/config.properties').then(r => r.data)
  const config = dotenv.parse(rawConfig)
  Object.entries(config).map(([k, v]) => {
    localStorage.setItem(k, v)
  })
}
