import { onMounted, ref } from 'vue'
import { apiCall } from './api'

/**
 * Fetch convertibles mocks
 * @return {{loaded: Ref<UnwrapRef<boolean>>, performances: ToRef<*[]>}}
 */
export default function (endpoint = 'performances') {
  const loaded = ref(false)
  const performances = ref([])

  const getPerformances = async () => {
    performances.value = await apiCall(endpoint, { portfCode: 'convertibles-FR0000000001' })
  }
  onMounted(async () => {
    await getPerformances()
    loaded.value = true
  })

  return {
    performances,
    loaded,
  }
}
