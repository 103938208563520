import { useRoute } from '@100-m/hauru'
import { computed } from 'vue'

function getDomain(query, dataset, period = 'inception') {
  // []
  const find_domain = closing => {
    return { Q1: 'XXXX-01|XXXX-03', Q2: 'XXXX-04|XXXX-06', Q3: 'XXXX-07|XXXX-09', Q4: 'XXXX-10|XXXX-12' }[closing.slice(5, 7)].replace(
      /XXXX/g,
      closing.slice(0, 4),
    )
  }
  let d = (query.domain && (query.domain.includes('Q') ? find_domain(query.domain).split('|') : query.domain.split('|'))) || []
  const dates = dataset.dates.__.keys()
  const first_date = dates.first()
  const last_date = dates.last()
  if (d.length > 0 && !query.domain.includes('Q')) {
    const fmt = d[0].length <= 7 ? 'Y-MM' : 'Y-MM-D'
    const fd = new Date(dates.first())
    const d1 = new Date(d[0]) < fd ? fd.format() : d[0]
    const d2 = new Date(d[1]) <= fd ? fd.plus('2 month').format(fmt) : d[1]
    update_query({ domain: '' + d1 + (d[1] ? '|' + d2 : '') })
  }
  if (d.length === 0 && period === 'inception') return [first_date, last_date]
  if (d.length === 0) d = [last_date.slice(0, 4)]
  if (d.unique().length === 1 && d[0].length === 4)
    d = [new Date(d[0]).start('year').minus('1 day').format(), [new Date(d[0]).end('year').format(), last_date].min()]
  if (d.unique().length === 1 && d[0].length === 7)
    d = [new Date(d[0]).start('month').minus('1 day').format(), [new Date(d[0]).end('month').format(), last_date].min()]
  if (d[0].length === 7) d[0] = new Date(d[0]).start('month').minus('1 day').format()
  if (d.length === 1) d = [new Date(d[0]).start('month').minus('1 day').format(), d[0]]
  if (d[1].length === 7) d[1] = [new Date(d[1]).end('month').format(), last_date].min()
  if (d[0] < first_date) d[0] = first_date
  if (d[0] > last_date) d[0] = last_date
  if (d.unique().length === 2 && d[1] < first_date) d[1] = first_date
  if (d.unique().length === 2 && d[1] > last_date) d[1] = last_date

  if (!dates.includes(d[0])) {
    d[0] = dates.__.filter(p => p < d[0]).last() || d[1]
  }
  if (!dates.includes(d[1])) {
    d[1] = dates.__.filter(p => p < d[1]).last()
  }
  if (period === 'daily' && !query.evolution) return [d[1], d[1]]
  return d
  // } catch (e) {
  //   return [new Date().minus('1 month').format(), new Date().format()]
  // }
}
export function useDomain(dataset, period) {
  const route = useRoute()
  return getDomain(route.query, dataset, period)
}

export function xfilter(data, { inc, post, filters, dimensions }) {
  // prefiltering by date
  const dates = data.dates
  const domain = filters.domain
  if (domain && dates.__.keys().length) {
    const d0 = dates[dates.__.keys().__.find(k => k >= domain[0])] // same date (or next)
    const after_d1 = dates[dates.__.keys().__.find(k => k > domain[1])] // next date
    data = data.slice(d0, after_d1)
  }

  const xf = { data: [] }
  filters = filters.__.filter((v, k) => v && dimensions.includes(k))
  dimensions.__.map(dim => (xf[dim] = {}))
  data.forEach((line, i) => {
    dimensions.__.filter(dim => !xf[dim][line[dim]]).__.map(dim => (xf[dim][line[dim]] = null))
    const filtered = filters.__.reduce((acc, fil, dim) => {
      if (fil.includes(line[dim])) return acc
      acc.push(dim)
      return acc
    }, [])
    if (filtered.length === 1) {
      const dim = filtered[0]
      xf[dim][line[dim]] = inc(xf[dim][line[dim]], line)
    }
    if (filtered.length) return
    xf.data.push(line)
    dimensions.__.map(dim => (xf[dim][line[dim]] = inc(xf[dim][line[dim]], line)))
  })
  if (post) return post(xf)
  return xf
}

// NOTE this is a mutation
export function addDates(data) {
  const dates = {}
  data.__.map((d, idx) => {
    if (d.date && dates[d.date] === undefined) dates[d.date] = idx
  })
  data.dates = dates
  return data
}
// dimensions and filters and data have to be reactive
export function useXfilter(data, { dimensions, filters, inc }) {
  const xf = computed(() => {
    // Init xdata
    console.log('computing xf')
    if (!data.value) return {}
    const xdata = dimensions.value.reduce(
      (acc, dim) => {
        acc[dim] = {}
        return acc
      },
      { data: [] },
    )
    const _filters = filters.value.__.filter((v, k) => v && dimensions.value.includes(k) && v.length)
    console.log('ff', _filters)
    data.value.forEach(row => {
      // for each dimension, add key if it doesnt exist already
      dimensions.value.forEach(dim => {
        if (!xdata[dim][row[dim]]) xdata[dim][row[dim]] = null
      })
      // if (_filters.sector) debugger
      const filteredDimensions = Object.entries(_filters).reduce((acc, [dim, fil]) => {
        if (fil.includes(row[dim])) return acc
        acc.push(dim)
        return acc
      }, [])
      // Not clear but i think its so when u click on a graph this graph does not get filtered
      if (filteredDimensions.length === 1) {
        const dim = filteredDimensions[0]
        xdata[dim][row[dim]] = inc(xdata[dim][row[dim]], row)
      }
      if (filteredDimensions.length) return
      xdata.data.push(row)
      dimensions.value.forEach(dim => (xdata[dim][row[dim]] = inc(xdata[dim][row[dim]], row)))
    })
    return xdata.__.map((v, k) => {
      if (k === 'data') return v
      return Object.entries(v).map(([key, value]) => {
        return { key, ...value }
      })
    })
  })
  return { xf }
}
