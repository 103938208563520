<template lang="pug">
widget-wrapper(:wait="allocation" :portfCode="portfCode")
  //- pre {{ xf && limitToMax(xf.sector) }}
  //- pre {{ xf && limitToMax(xf.asset_class) }}
  //- button(@click="filters.sector = ['OPCVM']") test
  //- pre {{ filters }}
  .bg-white.p-2.flex.flex-col(class="sm:px-6")
    .flex.flex-row.items-center.mb-2.gap-2
      select.border.border-gray-200.rounded-full.py-1(class="focus:ring-primary" v-model="activeAxis")
        option(v-for="axis in ['compare', 'portfolio', 'benchmark']" :value="axis") {{ t[axis] || axis }}
      span Allocation {{ t.asof }}
      //- span.font-bold {{ new Date('2021-04-30').format('day, mon, year', $root.lang).titleize() }}
      date-picker(v-model="activeDate")
    .flex.flex-col.h-full.items-center.justify-center.flex-1(v-if="['init', 'loading'].includes(state)")
      loader
    .flex.flex-row.gap-6.items-start.items-center.p-4(v-else)
      .flex.flex-col.gap-1
        .font-bold.text-lg.text-primary.flex.flex-row.justify-between.gap-4
          div {{ t.allocation }}
          div {{ format('.2%')(totals.ptfWeight) }}
        .text-gray-400.flex.flex-row.justify-between.gap-4
          div {{ t.benchmark }}
          div {{ format('.2%')(totals.bmkWeight) }}
      .flex.flex-row.flex-wrap.items-center.gap-2
        template(v-for="v,k in displayedFilters" v-if="activeFilters")
          .text-sm.text-gray-600 {{ t[k] || k }}:
          .filter-button.flex.flex-row.items-center.justify-center.px-3.py-1.rounded.border.border-gray-300.cursor-pointer.bg-white(
            class="hover:bg-gray-200"
            v-for="fil in v"
            @click="filterDimension(k,fil)"
          )
            svg-icon.cross-icon.h-4.w-4.text-gray-500.fill-current.mr-2(name="pt-icon-cross")
            span {{ fil }}
        .text-sm.text-gray-400(v-else) Click graph to add filters
    .grid.grid-cols-2
      .flex.flex-col.relative.gap-4.p-4(style="height: 40vh" v-for="dimension in dimensions")
          h3 {{ t[dimension] }}
          cjs-wrapper.no-legend.bg-white.h-full.no-tooltip(
            :data="plotData(dimension, filters)" 
            :metadata="barMetadata" 
            @plotclick="ev => filterDimension(dimension, ev.key)")
  //- .min-h-full.bg-white.p-2(class="sm:px-6")
  //-   dice-allocation-chart.mb-6(:data="allocation" :metadata="{ format: '.2%' }")
</template>
<script>
import { getCrossfilterAllocation } from '@/composables/bone'
import { getFundMetadata } from '@/composables/api'
import { useXfilter } from '@/composables/xfilter'
import { useEmbedComponent } from '@/composables/embed.js'

import { computed, ref, watch, reactive } from 'vue'
const mode = import.meta.env.MODE
function getCssVar(cssVar) {
  const styleSheet = getComputedStyle(document.body)
  return styleSheet.getPropertyValue(cssVar)
}
// Axis fund/benchmark/compare
// Crossfilter on click
// Display active filters
function limitToMax(data, max = 5) {
  // return data
  if (!data) return
  const sorted = data.sort((a, b) => {
    return (b.ptfWeight || 0) - (a.ptfWeight || 0)
  })
  // return sorted
  const keys = ['ptfWeight', 'bmkWeight']
  const sliced = sorted.slice(max)
  const other = keys.reduce(
    (acc, k) => {
      acc[k] = sliced.sum(k)
      return acc
    },
    { key: $root.t.other },
  )
  return sorted.slice(0, max).concat(other)
}

export default {
  setup() {
    const state = ref('init') // init - ready - loading
    const allocation = ref()
    const filters = ref({})
    const config = useEmbedComponent('widgets.allocation')
    const { portfCode, axis, watcher, date } = config
    const dimensions = ref()
    const endDate = date.value
    const startDate = endDate.minus('5 years')
    const activeDate = ref(endDate)
    async function getData() {
      state.value = 'loading'
      const fundMetadata = await getFundMetadata(portfCode.value)
      dimensions.value = fundMetadata.allocationDimensions
      const { data, raw } = await getCrossfilterAllocation(portfCode.value, dimensions.value, activeDate.value)
      allocation.value = data
      state.value = 'ready'
    }

    const inc = (acc, v) => {
      if (!acc) acc = { ptfWeight: 0, bmkWeight: 0 }
      acc.ptfWeight += v.ptfWeight || 0
      acc.bmkWeight += v.bmkWeight || 0
      return acc
    }
    const { xf } = useXfilter(allocation, { inc, filters, dimensions })
    window.xf = xf
    const totals = computed(() => {
      if (!xf.value || !xf.value.data) return { ptfWeight: 1, bmkWeight: 1 }
      const ptfWeight = xf.value.data.sum('ptfWeight')
      const bmkWeight = xf.value.data.sum('bmkWeight')
      return { ptfWeight, bmkWeight }
    })
    getData()
    watcher(getData)
    watch(activeDate, getData)
    const activeAxis = ref('compare')
    const barMetadata = computed(() => {
      const plots = []
      if (activeAxis.value === 'compare' || activeAxis.value === 'portfolio') {
        plots.push({
          type: 'bar',
          yAxisKey: 'ptfWeight',
          color: getCssVar('--fund'),
          options: {
            borderRadius: 4,
            borderSkipped: 'bottom',
          },
        })
      }
      if (activeAxis.value === 'compare' || activeAxis.value === 'benchmark') {
        plots.push({
          type: 'bar',
          yAxisKey: 'bmkWeight',
          color: getCssVar('--benchmark'),
          options: {
            borderRadius: 4,
            borderSkipped: 'bottom',
          },
        })
      }
      return {
        xAxisKey: 'key',
        yAxisFormat: format('.1%'),
        datalabels: true,
        plots,
        grid: {
          y: {
            color: 'rgba(0,0,0,0.1)',
            style: 'dash',
          },
        },
      }
    })
    function filterDimension(dimension, key) {
      console.log('filtering', dimension, key)
      // return console.log
      // return ({ key }) => {
      const _filters = filters.value
      _filters[dimension] = _filters[dimension] || []
      if (_filters[dimension].includes(key)) {
        _filters[dimension] = _filters[dimension].filter(d => d !== key)
        return
      }
      _filters[dimension].push(key)
      // }
    }
    const displayedFilters = computed(() => filters.value.__.filter((v, k) => v && v.length))
    const activeFilters = computed(() => Object.keys(displayedFilters.value).length)

    function plotData(dimension, filters) {
      const dataset = xf.value[dimension]
      const _filters = filters[dimension]
      const limitedDataset = limitToMax(dataset)
      if (!_filters || !_filters.length) return limitedDataset
      // const dd = limitToMax(dataset)
      // debugger
      return limitedDataset.map(d => {
        const disabled = !_filters.includes(d.key)
        return { disabled, ...d }
      })
    }
    return {
      state,
      allocation,
      xf,
      filters,
      barMetadata,
      activeAxis,
      limitToMax,
      filterDimension,
      displayedFilters,
      activeFilters,
      domain: [new Date().minus('1 year'), new Date()],
      activeDate,
      totals,
      mode,
      plotData,
      ...config,
      dimensions,
    }
  },
}
</script>
<style>
.svg-icon.cross-icon {
  width: 1rem;
  height: 1rem;
}
</style>
