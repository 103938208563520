let init = false

export function emitEvent(name, data) {
  window.top.postMessage({ name, data }, '*')
}

const _listeners = {}
function listenEvent(name, fn) {
  _listeners[name] = fn
}
export default function useEvents() {
  if (!init) {
    window.onmessage = message => {
      const event = message.data
      console.log('receiving', message.data)
      const listener = _listeners[event.name]
      if (listener) listener(event.data)
    }
    listenEvent('domain', data => update_query({ domain: (data || []).join('|') }))
  }
  return { emitEvent, listenEvent }
}
