<template lang="pug">
audit-tabs
loader(v-if="state === 'init'") Loading available funds...
.flex.flex-col(v-else)
  .flex.flex-row
    .flex.flex-col.gap-2.p-4.flex-1.max-w-md
      fieldset.flex.flex-col.gap-1
        label.font-bold portfCode
        input.input-select.p-2(v-model="portfCode" list="funds")
        datalist#funds
          option(v-for="fund in funds" :value="fund.portfCode") {{ fund.name }}
      .flex.flex-col.gap-1
        .font-bold Date
        .flex.flex-row.items-center.gap-8(v-if="calendarDates")
          date-picker.bg-white(v-model="activeDate" :dates="calendarDates")
            template(#default="{ date, showModal }")
              .flex.flex-row.items-center.gap-2.cursor-pointer.py-1.px-2.border.border-gray-200.rounded-md(style="height: 38px;" @click="showModal")
                span.font-bold {{ date.format('day, mon, year', $root.lang) }}
                svg-icon.text-gray-400.fill-current(name="ic_date_range")
        div(v-else-if="state === 'loading_dates'") Loading...
        div(v-else) Choose a portfCode to see available dates
      button.ml-auto(@click="getData") Valider
  .flex.flex-col.p-4.gap-2
    h2 Details
    .flex.flex-row.items-center.justify-between
      fieldset.flex.flex-col.gap-1.max-w-xs.flex-1
        label filter by name or dimension
        input.p-1(v-model="search")
      button(@click="download") Download data
    dice-table.risk-table.performance-table.small(:data="drilldownDetails" :columns="detailsColumns")
</template>
<script>
import { ref, computed, watch } from 'vue'
import { pickBy } from 'lodash'
import { getDrilldownAudit, getAllocationDates, getFunds } from '@/composables/api'

const demoFunds = [
  { name: 'Loomis Sayles Global Growth Equity Fund', portfCode: 'IFM_3537' },
  { name: 'Mirova Euro Green&Sustainable Bond Fund', portfCode: 'IFM_3040' },
  { name: 'Mirova Euro Sustainable Equity Fund', portfCode: 'IFM_3039' },
  { name: 'Mirova Europe Sustainable Economy Fund', portfCode: 'IFM_3794' },
  { name: 'Natixis Diversified Solution', portfCode: 'IFM_111' },
  { name: 'Ostrum SRI Cash Plus', portfCode: 'IFM_1820' },
  { name: 'Ostrum SRI Euro Souvereign Bonds', portfCode: 'IFM_372' },
  { name: 'SEEYOND Global MinVol', portfCode: 'IFM_3035' },
  { name: 'Seeyond Volatility Strategy', portfCode: 'IFM_3036' },
  { name: 'IFM_3257', portfCode: 'IFM_3257' },
  { name: 'IFM_3264', portfCode: 'IFM_3264' },
]

export default {
  setup() {
    const portfCode = ref()
    const activeDate = ref()
    const calendarDates = ref()
    const drilldownDetails = ref()
    const drilldownData = ref()
    const search = ref()
    const state = ref('init')
    const funds = ref()
    let portfCodes = []
    async function init() {
      const data = await getFunds()
      funds.value = Object.values(data).sort(d => d.name)
      portfCodes = funds.value.map(d => d.portfCode).concat(['IFM_3257', 'IFM_3264'])
      state.value = 'ready'
    }
    init()

    async function getData() {
      state.value = 'loading'
      const res = await getDrilldownAudit(portfCode.value, activeDate.value)
      // debugger
      drilldownDetails.value = res.drilldownDetails.map(row => {
        const datum = pickBy(row, (v, k) => !['StaticAssetRef', 'File'].includes(k))
        return { ...row.StaticAssetRef, ...datum }
      })
      drilldownData.value = res.drilldownData
      state.value = 'ready'
    }

    const detailsColumns = ref([
      {
        key: 'fuid',
        size: 0.5,
        name: 'FUID',
      },
      {
        key: 'name',
        name: 'name',
      },
      {
        key: 'ptfWeight',
        name: 'Weight fund',
        format: d => format('.2%')(d),
      },
      { key: 'level1', name: 'level 1' },
      { key: 'level2', name: 'level 2' },
      { key: 'level3', name: 'level 3' },
      { key: 'level4', name: 'level 4' },
      { key: 'instrument_type_name', name: 'Asset Class' },
      { key: 'groupId', name: 'Group ID' },
    ])
    function download() {
      if (!drilldownDetails.value) return
      drilldownDetails.value.dlCSV('fr', 'holdings.csv')
    }
    watch(portfCode, async () => {
      if (!portfCode.value || !portfCodes.includes(portfCode.value)) {
        calendarDates.value = null
        return
      }
      state.value = 'loading_dates'
      calendarDates.value = await getAllocationDates(portfCode.value)
      activeDate.value = new Date(calendarDates.value[calendarDates.value.length - 1])
      state.value = 'ready'
    })
    return {
      state,
      portfCode,
      getData,
      activeDate,
      drilldownDetails,
      drilldownData,
      detailsColumns,
      search,
      download,
      demoFunds,
      calendarDates,
      funds,
    }
  },
}
</script>
