<template lang="pug">
.mt-1.relative.w-full.border-gray-300.rounded-md.shadow-sm
  .absolute.inset-y-0.left-0.pl-3.flex.items-center.pointer-events-none
    svg-icon.fill-current.text-gray-400(name="ic_search")
  input.w-full.p-4.pl-12.border-gray-300.rounded-md(type="text" class="focus:ring-primary focus:border-primary hover:shadow sm:text-sm md:text-lg" v-model="search" placeholder="Search components by name or category")
div {{ search }}
.divide-y.divide-gray-200
  section.py-6(v-for="group, groupName in components")
    h2.pb-6 {{ groupName.titleize() }}
    .col-span-3.grid.gap-6.h-full(class='sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 sm:gap-y-8 lg:gap-x-8')
      router-link.group.relative.bg-gray-50.rounded-lg.shadow.overflow-hidden.ring-1.ring-black.ring-opacity-5(v-for="component, name in group" :to="`/docs/embed/${groupName}/${name}`")
        figure
          iframe.pointer-events-none.w-full.h-48.border-b.border-gray-200(:src="`/embed/${groupName}/${name}`")
          figcaption.py-3.px-4
            p.text-sm.font-medium.text-gray-900.mb-1 {{ component.name }}
            p.text-xs.font-medium.text-gray-500 {{ component.description }}
</template>
<script>
import { ref, computed } from 'vue'
export default {
  setup() {
    const search = ref()
    const components = computed(() => {
      const _components = $root?.config?.components
      if (!search.value) return _components
      const regexp = new RegExp(search.value, 'i')
      return _components.__.map((group, groupName) => {
        if (regexp.test(groupName)) return group
        return group.__.filter((component, name) => {
          return regexp.test(component.name) || regexp.test(name)
        })
      }).__.filter(v => v.__.keys().length)
    })
    return { components, search }
  },
}
</script>