<template lang="pug">
widget-wrapper(:wait="drilldown" :portfCode="portfCode" :portfShareCode="portfShareCode")
  .flex.flex-col.bg-white.p-4.gap-4.h-full
    .flex.flex-row.items-center.justify-between.mb-4
      h2 Drilldown
      .flex.flex-row.items-center.gap-8
        .flex.flex-row.py-1.px-2.text-gray-600.gap-1(v-if="!embargoDays") {{ $root.t['Details unavalaible for this fund'] }}
        .flex.flex-row.py-1.px-2.text-gray-600.gap-1(v-else-if="!showLastLevel")
            div {{ $root.t['Search only available before'] }} J-{{ embargoDays }}
            span.underline.cursor-pointer(@click.stop.prevent="activeDate = embargoDate") {{$root.t.see }} {{ embargoDate.format('day, mon, year', $root.lang) }}      
        input.search-bar.flex.flex-row.items-center.gap-2.cursor-text.py-1.px-2.border.border-gray-200.rounded-md(
          style="height: 38px;"
          :placeholder="t.drilldown_search_placeholder"
          v-model="search"
          v-else=""
        )
        date-picker.left(v-model="activeDate" :dates="calendarDates" @update:modelValue="ev => persistDate(portfCode, ev)")
          template(#default="{ date, showModal }")
            .flex.flex-row.items-center.gap-2.cursor-pointer.py-1.px-2.border.border-gray-200.rounded-md(style="height: 38px;" @click="showModal")
              span.font-bold {{ date.format('day, mon, year', $root.lang) }}
              svg-icon.text-gray-400.fill-current(name="ic_date_range")
        download-button(:items="downloadItems")
    .flex.flex-col.h-full.items-center.justify-center.flex-1(v-if="['init', 'loading'].includes(state)")
      loader
    .flex.flex-col.text-center.flex-1(v-if="filteredDrilldown.length === 0") {{ t.empty_search }}
    .flex.flex-col(v-else)
      //- div(v-for="row in drilldown") {{ row.ptfWeight || '?' }}
      drilldown-table(:data="filteredDrilldown" :showLast="showLastLevel" :lastLevel="lastLevel" :columns="drilldownColumns")
        template(#last)
          .flex.flex-col.py-2.text-gray-600(v-if="embargoDays")
            div {{ $root.t['Details only available before'] }} J-{{ embargoDays }}
            span.underline.cursor-pointer(@click.stop.prevent="activeDate = embargoDate") {{$root.t['See drilldown at'] }} {{ embargoDate.format('day, mon, year', $root.lang) }}      
          .flex.flex-col.py-2.text-gray-600(v-else) {{ $root.t['Details unavalaible for this fund'] }}
      .totals.flex.flex-row.text-sm.font-bold.py-1
        .flex-1.px-2.text-right {{ $root.t.total }}:
        .text-center.w-28.px-4(v-for="col in drilldownColumns") {{ totals[col] || '' }}
    .chart-disclaimer.flex.flex-col
      div {{ $root.t.embargo_disclaimer }}
      div {{ $root.t.transparency_disclaimer }}
      div(v-if="masterPortfCode") {{ $root.t.master_fund_disclaimer }} ({{ masterName }})
      div(v-if="benchmarkComposition") {{ $root.t.comparison_index }}: {{ benchmarkComposition}}
    //- pre {{ drilldown }}

</template>
<script>
import { useEmbedComponent, useStoredValues } from '@/composables/embed.js'
import { getAllocationDates, getFundMetadata, getDrilldown } from '@/composables/api'

// import { getDrilldown } from '@/composables/bone'
import { findNearestDate } from '@/composables/utils'

import { computed, ref, watch, reactive } from 'vue'

function getBarWidth(row, benchmark = false) {
  const max = Math.max(row.ptfWeight || 0, row.bmkWeight || 0)
  // const min = Math.max(row.ptfWeight || 0, row.bmkWeight || 0)
  if (max === 0) return 0
  const key = benchmark ? 'bmkWeight' : 'ptfWeight'
  return format('.2%')(row[key])
  return format('.2%')(row[key] / max)
}
export default {
  setup() {
    const state = ref('init') // init - ready - loading
    const config = useEmbedComponent('widgets.drilldown')
    const { portfCode, watcher, portfShareCode } = config
    const { getDate, persistDate } = useStoredValues()
    const masterPortfCode = ref()
    const masterName = ref()
    const activeDate = ref()
    const embargoDays = ref()
    const calendarDates = ref()
    const lastLevel = ref()
    const drilldownDetails = ref()
    const search = ref()
    let lastDateAvailable

    const embargoDate = computed(() => {
      if (!embargoDays.value || !calendarDates.value) return
      return findNearestDate(new Date().minus(`${embargoDays.value || 0} days`), calendarDates.value)
    })
    const showLastLevel = computed(() => {
      if (!embargoDate.value) return
      return activeDate.value <= embargoDate.value
    })

    function getLastChildren(row) {
      if (row.children) return getLastChildren(row.chlidren)
    }
    // function filterChildren(children, search) {
    let filterChildren = (row, search) => {
      if (!row.children || !row.children[0]) return row
      if (row.children[0].children) {
        const children = row.children.map(c => filterChildren(c, search))
        return { ...row, children }
      }
      const searchRegexp = new RegExp(search, 'i')
      const children = row.children.filter(d => ['instrument', 'isinCode', 'issuer'].some(col => searchRegexp.test(d[col])))
      return { ...row, children }
    }
    function _filterEmpty(row) {
      // _filterEmpty = (row) => {
      if (!row.children) return true
      if (!row.children.length) return false
      if (!row.children[0].children) return !!row.children.length
      return row.children.some(_filterEmpty)
    }
    function filterEmpty(data) {
      // filterEmpty = (data) => {
      const filtered = data.filter(_filterEmpty)
      if (!filtered[0] || !filtered[0].children) {
        return filtered
      }
      // return .map(d => {
      //   return { ...d, issuer: 'Placeholder' }
      // })
      return filtered.map(row => {
        const children = filterEmpty(row.children)
        return { ...row, children }
      })
    }
    const drilldown = ref()
    const filteredDrilldown = computed(() => {
      if (!drilldown.value) return []
      if (!search.value) return drilldown.value
      const filteredInstruments = drilldown.value.map(d => {
        return filterChildren(d, search.value)
      })
      return filterEmpty(filteredInstruments)
    })
    const totals = computed(() => {
      const ptfWeight = format('.2%')(drilldown.value.sum('ptfWeight'))
      const bmkWeight = format('.2%')(drilldown.value.sum('bmkWeight'))
      return { ptfWeight, bmkWeight, deltaWeight: '-' }
    })

    const benchmarkType = ref()
    const benchmarkName = ref()
    async function init() {
      calendarDates.value = await getAllocationDates(portfCode.value)
      lastDateAvailable = new Date(calendarDates.value[calendarDates.value.length - 1])
      if (!calendarDates.value || !calendarDates.value.length) {
        drilldown.value = []
        return
      }
      // activeDate.value = new Date('2022-02-28')
      const fundMetadata = await getFundMetadata(portfCode.value, portfShareCode.value)
      embargoDays.value = fundMetadata.embargo
      masterPortfCode.value = fundMetadata.masterPortfCode
      masterName.value = fundMetadata.masterName
      benchmarkType.value = fundMetadata.benchmarkType
      benchmarkName.value = fundMetadata.benchmarkName
      const startingDate = getDate(portfCode.value) || embargoDate.value
      activeDate.value = startingDate ? findNearestDate(startingDate, calendarDates.value) : lastDateAvailable
      // activeDate.value = embargoDate.value || new Date(calendarDates.value[calendarDates.value.length - 1])
    }
    const benchmarkComposition = computed(() => {
      if (benchmarkType.value !== 'comparison') return
      return benchmarkName.value
    })

    const drilldownColumns = computed(() => {
      // return ['ptfWeight', 'bmkWeight', 'deltaWeight']
      if (!benchmarkType.value) return
      return benchmarkType.value === 'comparison' || !hasBenchmark.value
        ? ['issuer', 'isinCode', 'ptfWeight']
        : ['issuer', 'isinCode', 'ptfWeight', 'bmkWeight', 'deltaWeight']
      // return ['ptfWeight', benchmarkType.value === 'comparison' ? 'comparison_index' : 'reference_index', 'deltaWeight']
    })
    async function getData() {
      if (!portfCode.value) return
      state.value = 'loading'
      const _portfCode = masterPortfCode.value || portfCode.value
      const data = await getDrilldown(_portfCode, activeDate.value, $root.lang)
      if (data.error) {
        drilldown.value = error
        // drilldownTable.value = null
        return
      }
      drilldown.value = data.drilldownData.sort('-ptfWeight').map((d, i) => {
        d.color = `var(--cat${i + 1})`
        d.idx = i
        return d
      })
      drilldownDetails.value = data.drilldownDetails
      lastLevel.value = data.lastLevel
      state.value = 'ready'
      // drilldownTableData.value = drilldownData
    }

    const hasBenchmark = computed(() => {
      return drilldown?.value?.some(d => d.bmkWeight)
    })
    init()
    watcher(getData)
    watch(activeDate, getData)
    const sortDrilldownDetails = data => {
      const weights = {
        level1: Object.fromEntries(Object.entries(data.group('level1')).map(([k, v]) => [k, v.sum('ptfWeight')])),
        level2: Object.fromEntries(Object.entries(data.group('level2')).map(([k, v]) => [k, v.sum('ptfWeight')])),
        level3: Object.fromEntries(Object.entries(data.group('level3')).map(([k, v]) => [k, v.sum('ptfWeight')])),
        level4: Object.fromEntries(Object.entries(data.group('level4')).map(([k, v]) => [k, v.sum('ptfWeight')])),
      }
      const compareLevel = (a, b, level) => {
        const aLevel = a[level] || ''
        const bLevel = b[level] || ''
        return weights[level][bLevel] - weights[level][aLevel]
      }
      return data.sort((a, b) => {
        if (b.level1 === 'Cash') {
          console.log('CASH')
          return -1
        }
        return compareLevel(a, b, 'level1') || compareLevel(a, b, 'level2') || compareLevel(a, b, 'level3') || compareLevel(a, b, 'level4')
      })
    }
    const downloadItems = computed(() => {
      const drilldownDetailsFilter = search.value
        ? drilldownDetails.value.filter(d =>
            ['tiers', 'instrument_type_name', 'isin_code'].some(col => new RegExp(search.value, 'i').test(d.StaticAssetRef[col])),
          )
        : drilldownDetails.value
      const drilldownFilter = search.value ? filteredDrilldown : drilldown.value
      const data = showLastLevel.value
        ? sortDrilldownDetails(
            drilldownDetailsFilter.map(({ level1, level2, level3, level4, StaticAssetRef, ptfWeight, bmkWeight }) => {
              const { name, isin_code, tiers } = StaticAssetRef
              const translate = x => $root.t[x] || x
              return {
                name,
                isin_code,
                issuer: tiers,
                level1: translate(level1),
                level2: translate(level2),
                level3: translate(level3),
                level4: translate(level4),
                ptfWeight,
                bmkWeight,
              }
            }),
          )
        : drilldownFilter.map(({ dimension, ptfWeight, bmkWeight }) => {
            return { dimension, ptfWeight, bmkWeight }
          })
      return [{ name: $root.t.allocation, data }]
    })

    return {
      state,
      drilldown,
      filteredDrilldown,
      search,
      ...config,
      activeDate,
      embargoDate,
      embargoDays,
      showLastLevel,
      downloadItems,
      calendarDates,
      totals,
      lastLevel,
      drilldownColumns,
      benchmarkComposition,
      hasBenchmark,
      masterName,
      persistDate,
    }
  },
}
</script>
<style scoped>
.table tr {
  display: grid;
  grid-template-columns: minmax(120px, 3fr) repeat(auto-fit, minmax(100px, 1fr));
  width: 100%;
}

.table tr.active {
  box-shadow: 0 -2px 4px -1px rgba(0, 0, 0, 0.1), 0 -1px 2px -1px rgba(0, 0, 0, 0.06);
}
.table td span {
  max-width: 220px;
}
.table .parent-row .hovered td {
  /* opacity: 0; */
  /* transition: padding-top 0.15s ease-in, padding-bottom 0.15s ease-in, max-height 0.15s ease-in; */
  transition: opacity 0s;
  max-height: 0;
  overflow: hidden;
  @apply p-0;
}
.table .parent-row:hover,
.table .parent-row.active {
  @apply shadow-md;
}
.table .parent-row:hover tr.main-content,
.table .parent-row.active tr.main-content {
  grid-template-columns: 2fr 2fr;
}
.table .parent-row:hover .hovered td,
.table .parent-row.active .hovered td {
  /* opacity: 1; */
  /* transition: opacity 0.2s ease-in, max-height 0s; */
  max-height: 400px;
  @apply p-2;
}
.table .parent-row .hovered td .bar {
  max-width: 0;
  transition: max-width 0.3s ease-out;
}
.table .parent-row:hover .hovered td .bar,
.table .parent-row.active .hovered td .bar {
  max-width: 100%;
}
.wait-transition-enter-active,
.wait-transition-leave-active {
  overflow: hidden;
  transition: none 2s;
  /* transition-delay: 2s; */
}
.wait-transition-enter,
.wait-transition-leave-to {
  opacity: 0;
}
.slide-bar-enter-active,
.slide-bar-leave-active,
.slide-bar-enter-to {
  overflow: hidden;
  transition: opacity 2s;
  opacity: 1;
  /* max-width: 400px; */
}
.slide-bar-enter,
.slide-bar-leave-to {
  opacity: 0;
}
.slide-bar-enter-active .bar,
.slide-bar-leave-active .bar {
  overflow: hidden;
  transition: opacity 2s;
  /* max-width: 400px; */
}
.slide-bar-enter .bar,
.slide-bar-leave-to .bar {
  opacity: 0;
}

.modal-slide-leave-active,
.modal-slide-enter-active {
  transition: all 0.2s ease-in-out;
}

.modal-slide-leave-to,
.modal-slide-enter-from {
  @apply border-l border-gray-500;
  transform: translateX(100%);
  overflow: hidden;
}

input.search-bar {
  background: white no-repeat right 10px center / 20px var(--search);
  min-width: 360px;
  font-size: 14px;
}
input.search-bar:focus {
  background: white;
}
</style>
<style>
.drilldown-table .cell-isinCode {
  font-size: 14px;
}
.drilldown-table .cell-issuer {
  width: 20rem;
}
</style>
