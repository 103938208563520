<template lang="pug">
wait-for(:wait="loaded")
  .bg-white.flex.flex-col.min-h-full(class="sm:p-4")
    dx-timeseries-chart(:data="performances" :options="options")
      template(v-slot:tooltip="{tooltip}")
        table-tooltip(v-if="tooltip.shown" :title="formatTitle(tooltip.title)" :rows="formatRows(tooltip.rows)")
</template>

<script>
import WaitFor from "../../../components/WaitFor.vue";
import usePerformanceMocks from "../../../composables/usePerformanceMocks";

export default {
  components: {WaitFor},
  methods: {
    formatTitle(title) {
      return new Date(title).format('day, mon, year', $root.lang).titleize()
    },
    formatRows(rows) {
      return rows.map(r => {
        return {
          label: this.t[r.label] || r.label,
          value: format('.2f')(r.value),
          color: r.color,
        }
      })
    }
  },
  setup() {
    const {performances, loaded} = usePerformanceMocks('bigPerformances')
    const options = {
      labelKey: 'date',
      datasets: [{
        label: 'Fund',
        key: 'fund',
      }, {
        label: 'Benchmark',
        key: 'benchmark'
      }],
      xType: 'time',
    }

    return {
      performances,
      options,
      loaded,
    }
  },
}
</script>
