import * as d3 from 'd3'

const fetchAndTransform = async (url, transform = x => x, options = {}, { $axios }) => {
  // TODO prefer an explicit cache database instead of relying on `global.js` `window.idb`
  const defaultOptions = { cache: true }
  const resolvedOptions = { ...defaultOptions, ...options }
  const cache = await idb.get(['xcache', url].join('.'))
  // TODO feels like the cache never clears
  if (cache) return cache
  const { fetchOptions } = resolvedOptions
  const response = await $axios.get(url, fetchOptions)
  const transformedData = transform(response.data)
  // if (resolvedOptions.cache) {
  idb.set(['xcache', url].join('.'), transformedData)
  // }
  return transformedData
}
const csvTransform = (ds, dimensions, structure, mapping) => data => {
  const dates = {}
  const columns = data.match(/.+/)[0].split(',')
  mapping = mapping || {}
  const structureFns = (structure || {}).__.filter((type, field) => !dimensions || !/^\w+$/.test(type) || columns.concat(dimensions).includes(field))
  const csv = d3.csvParse(data, (l, li) => {
    if (l.date && dates[l.date] === undefined) dates[l.date] = li
    if (dimensions && (l.id || l.isin))
      dimensions.__.filter(dim => !structureFns[dim]).__.map(dim => (l[dim] = (mapping[l.id || l.isin] && mapping[l.id || l.isin][dim]) || 'NA'))
    structureFns.__.map((fn, k) => (l[k] = fn(l, k)))
    return l
  })
  csv.dates = dates
  return csv
}
const download = async (ds, context, dimensions, mapping) => {
  // TODO why have an array if we're gonna take the first one everytime ?
  let url = ds.url_fn(context)[0]
  // const dimensions = (this.userflow.dimensions || (mapping && mapping.__.v() && mapping.__.v()[0] && mapping.__.v()[0].__.keys()) || []).concat(this.screen.dimensions).concat(this.share?.dimensions_pdf).__.filter().unique()
  // TODO make asof work (What project is it on ?)
  const format_asof = d => d
  // ASOF url transformation
  if (url.includes('?path=mapping.csv') && context.query.asof) {
    url = url.replace('?path=mapping.csv', `?path=asof/mapping-${format_asof(context.query.asof)}.csv`)
  }
  const data = await fetchAndTransform(url, csvTransform(ds, dimensions, ds.structure, mapping), { cache: context.query.asof }, context)
  // TODO why is this needed `csv.url` ? Keeping for now to avoid breaking things
  data.url = url
  return data
}
export default {
  data() {
    return {
      x: null,
    }
  },
  computed: {
    xf() {
      if (!this.x) return {}
      const ctx = this.$root.context
      return this.$root.config?.datasets
        .__.filter((ds, name) => (this.$root.screen.datasets || []).includes(name))
        .__.map((ds, name) =>
          this.xfilter(this.x[name], {
            inc: (ds.inc_fn && ds.inc_fn(this)) || (v => v),
            post: ds.post_fn && ds.post_fn(this),
            filters: this.filters,
            dimensions: (ds.dimensions || this.filters.__.keys().__.filter(k => k !== 'domain')).concat(ds.period === 'daily' ? ['date'] : []),
          }),
        )
    },
    urls() {
      if (!this.screen.datasets) return console.log('skipping')
      return this.config.datasets.__.map(ds => ds.url_fn(this.context)[0])
    },
  },
  watch: {
    urls(next, prev) {
      if (eq(next, prev)) return
      this.update(this.$root.config.datasets)
    },
  },
  methods: {
    async update(datasets) {
      console.log('update', this.urls)
      this.x = null
      // TODO clean this up
      // TODO only download the datasets from the screen instead of all of them
      const ctx = { ...this, params: this.$route.params, $axios: this.$axios }
      // TODO manage mapping better (all this complexity comes from the fact that we're trying
      // to deal with a case where tehre is no datasets.__.mapping, does that case really exist ?)
      if (!ctx.params.userflow) return console.log('skipping')
      let mapping
      if (datasets.mapping) {
        const m = await download(datasets.mapping, ctx)
        mapping = m.__.reduce((acc, d) => {
          acc[d.id || d.isin] = d
          return acc
        }, {})
        //  TODO remove this but its used a lot in templates
        window.mapping = mapping
      }
      const dimensions = (this.userflow.dimensions || (mapping && mapping.__.v() && mapping.__.v()[0] && mapping.__.v()[0].__.keys()) || [])
        .concat(this.screen?.dimensions)
        .concat(this.share?.dimensions_pdf)
        .__.filter()
        .unique()
      await Promise.all(
        datasets
          .__.map(async (ds, name) => {
            const data = await download(ds, ctx, dimensions, mapping)
            return [data, name]
          })
          .__.v(),
      )
        .then(downloads => {
          return downloads.__.reduce((acc, result) => {
            const [data, name] = result
            acc[name] = data
            return acc
          }, {})
        })
        .then(d => (this.x = Object.freeze(d)))
    },
    xfilter(data, { inc, post, filters, dimensions }) {
      // prefiltering by date
      const dates = data.dates
      const domain = filters.domain
      if (domain && dates.__.keys().length) {
        const d0 = dates[dates.__.keys().__.find(k => k >= domain[0])] // same date (or next)
        const after_d1 = dates[dates.__.keys().__.find(k => k > domain[1])] // next date
        data = data.slice(d0, after_d1)
      }

      const xf = { data: [] }
      filters = filters.__.filter((v, k) => v && dimensions.includes(k))
      dimensions.__.map(dim => (xf[dim] = {}))

      data.forEach((line, i) => {
        dimensions.__.filter(dim => !xf[dim][line[dim]]).__.map(dim => (xf[dim][line[dim]] = null))
        const filtered = filters.__.reduce((acc, fil, dim) => {
          if (fil.includes(line[dim])) return acc
          acc.push(dim)
          return acc
        }, [])
        if (filtered.length === 1) {
          const dim = filtered[0]
          xf[dim][line[dim]] = inc(xf[dim][line[dim]], line)
        }
        if (filtered.length) return
        xf.data.push(line)
        dimensions.__.map(dim => (xf[dim][line[dim]] = inc(xf[dim][line[dim]], line)))
      })

      if (post) return post(xf)
      return xf
    },
  },
}
