export function getDrawdownDetails(series, metric) {
  const { argmin, argmax, maxDrawdown } = getMaxDrawdown(series, metric)
  const begin = (series[argmax] || {}).date
  const end = (series[argmin] || {}).date
  const beginNav = (series[argmax] || {})[metric]
  const recovery = (
    series
      .filter(d => d.date > end)
      .filter(d => d[metric] >= beginNav)
      .first() || {}
  ).date
  const duration = recovery ? parseInt((new Date(recovery) - new Date(begin)) / 86400000) : '-'
  return {
    drawdown: maxDrawdown,
    end: end,
    endNav: (series[argmin] || {})[metric],
    begin: begin,
    beginNav: beginNav,
    recovery: recovery || 'not_achieved',
    duration: duration,
  }
}

export function getMaxDrawdownValue(series, metric) {
  return getMaxDrawdown(series, metric).maxDrawdown || '-'
}

export function getMaxDrawdown(series, metric) {
  const values = series.map(metric)
  let maxDrawdown = 0.0
  let max = values.first()
  let min = values.first()
  let argmax = 0
  let argmin = 0
  values.map((d, i) => {
    if (d >= max) {
      max = min = d
      // Why cant we just do
      argmax = i
      // it's the only time we change max
    }
    if (d < min) {
      min = d
      if (min / max - 1 < maxDrawdown) {
        maxDrawdown = min / max - 1
        argmin = i
        // This is super slow
        // argmax = values.findIndex(max)
      }
    }
    return null
  })
  return { argmin, argmax, maxDrawdown }
}
