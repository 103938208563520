import { addDates } from './xfilter'
import { computed, ref } from 'vue'
import { getPerformance as getPerformanceBone } from './bone'

// kpis_analytics() {
//   return {
//     performance: [
//       {title: 'performance', value: analytics(this.active_dataset, 'performance.domain.fund')},
//       !$root.benchmark
//         ? {}
//         : {title: 'performance_benchmark', value: analytics(this.active_dataset, 'performance.domain.benchmark')}
//       ],
//     volatility: [
//     ],
//     risk_indicators: [
//       { title: 'tracking_error', value: analytics(this.active_dataset, 'tracking_error.domain') },
//       { title: 'information_ratio', value: analytics(this.active_dataset, 'information_ratio.domain') },
//       { title: 'sharpe_ratio', value: analytics(this.active_dataset, 'sharpe_ratio.domain') }
//     ],

//   }
// },

export function getDomain(dataset, period) {
  // []
  // Remove global var
  const route = $root.$route
  // TODO Manage case with no datasets (kiid dci)
  const find_domain = closing => {
    return { Q1: 'XXXX-01|XXXX-03', Q2: 'XXXX-04|XXXX-06', Q3: 'XXXX-07|XXXX-09', Q4: 'XXXX-10|XXXX-12' }[closing.slice(5, 7)].replace(
      /XXXX/g,
      closing.slice(0, 4),
    )
  }
  let d = (route.query.domain && (route.query.domain.includes('Q') ? find_domain(route.query.domain).split('|') : route.query.domain.split('|'))) || []
  const dates = dataset.__.map('date').unique().sort()
  const first_date = dates.first()
  const last_date = dates.last()
  try {
    if (d.length > 0 && !route.query.domain.includes('Q')) {
      const fmt = d[0].length <= 7 ? 'Y-MM' : 'Y-MM-D'
      const fd = new Date(dates.first())
      const d1 = new Date(d[0]) < fd ? fd.format() : d[0]
      const d2 = new Date(d[1]) <= fd ? fd.plus('2 month').format(fmt) : d[1]
      update_query({ domain: '' + d1 + (d[1] ? '|' + d2 : '') })
    }
    if (d.length === 0 && period === 'inception') return [first_date, last_date]
    if (d.length === 0) d = [last_date.slice(0, 4)]
    if (d.unique().length === 1 && d[0].length === 4)
      d = [new Date(d[0]).start('year').minus('1 day').format(), [new Date(d[0]).end('year').format(), last_date].min()]
    if (d.unique().length === 1 && d[0].length === 7)
      d = [new Date(d[0]).start('month').minus('1 day').format(), [new Date(d[0]).end('month').format(), last_date].min()]
    if (d[0].length === 7) d[0] = new Date(d[0]).start('month').minus('1 day').format()
    if (d.length === 1) d = [new Date(d[0]).start('month').minus('1 day').format(), d[0]]
    if (d[1].length === 7) d[1] = [new Date(d[1]).end('month').format(), last_date].min()
    if (d[0] < first_date) d[0] = first_date
    if (d[0] > last_date) d[0] = last_date
    if (d.unique().length === 2 && d[1] < first_date) d[1] = first_date
    if (d.unique().length === 2 && d[1] > last_date) d[1] = last_date

    if (!dates.includes(d[0])) {
      d[0] = dates.__.filter(p => p < d[0]).last() || d[1]
    }
    if (!dates.includes(d[1])) {
      d[1] = dates.__.filter(p => p < d[1]).last()
    }
    if (period === 'daily' && !route.query.evolution) return [d[1], d[1]]
    return d
  } catch (e) {
    return [first_date, last_date]
  }
}

export function sortMonths() {
  if ($root.lang === 'fr') return d => ['Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'].indexOf(d.x)
  return d => ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].indexOf(d.x)
}

function _getDomainFromPeriod(dates, period, domain) {
  const first_date = new Date(dates.first())
  const last_date = new Date(domain[1])
  if (period === 'ytd') return [last_date.minus('1 year').end('year'), last_date]
  if (period === 'mtd') return [last_date.minus('1 month').end('month'), last_date]
  if (period === '1m') return [last_date.minus('1 month').end('month'), last_date]
  if (period === '3m') return [last_date.minus('3 month').end('month'), last_date]
  if (period === '6m') return [last_date.minus('6 month').end('month'), last_date]
  if (period === '1y') return [last_date.minus('1 year').end('month'), last_date]
  if (period === '3y') return [last_date.minus('3 years').end('month'), last_date]
  if (period === '5y') return [last_date.minus('5 years').end('month'), last_date]
  if (period === '10y') return [last_date.minus('10 years').end('month'), last_date]
  if (period.startsWith('years-'))
    return [
      new Date((last_date.getFullYear() - period.split('-')[1]).toString()).minus('year').end('year'),
      new Date((last_date.getFullYear() - period.split('-')[1] + 1).toString()).minus('year').end('year'),
    ]
  if (period === 'inception') return [first_date, last_date]
  if (period === 'domain') return [new Date(domain[0]), last_date]
}
export function getDomainFromPeriod(dates, period, domain) {
  return _getDomainFromPeriod(dates, period, domain).map(d => d.format())
}
export function analytics(dataset, key, domain = []) {
  const [fn, period, metric, arg1, arg2] = key.split('.')
  if (metric === 'diff') return analytics(key.replace('diff', 'fund')) - analytics(key.replace('diff', 'benchmark')) || '-'
  const dates = dataset.__.map('date').unique()
  // console.log(fn, '-', period, '-', dates, '-', dates.length, )
  if (!dates || !dates.length || !domain[1]) return '-'
  const _domain = getDomainFromPeriod(dates, period, domain)
  if (new Date(_domain[0]) < new Date(dates.first())) return '-'
  //For some indicators, I need before before_d0 to compute returns on 1y / 3y / 5y
  const data = dataset.__.filter(d => d.date >= _domain[0] && d.date <= _domain[1])
  if (['performance', 'performance_annualized', 'performance_weekly', 'volatility', 'max_drawdown_value'].includes(fn)) return data[fn](metric, period)
  if (['tracking_error', 'information_ratio', 'sharpe_ratio', 'alpha', 'beta'].includes(fn)) {
    if (metric) return data[fn](period, metric)
    return data[fn](period)
  }
  if (['var_X'].includes(fn)) return data[fn](metric, period, arg1)
  if (['var_X_Y_days'].includes(fn)) return data[fn](metric, period, arg1, arg2)
}

export default function usePerformance() {
  const performance = ref()
  function getPerformance(portfCode) {
    return getPerformanceBone(portfCode).then(r => (performance.value = addDates(r)))
  }
  const domain = computed(() => {
    const data = performance.value
    if (!data) return []
    return getDomain(data, 'inception')
  })
  const volatility = computed(() => {
    const data = performance.value
    if (!data) return []
    return [
      { title: 'volatility', value: analytics(data, 'volatility.domain.fund', domain.value) },
      { title: 'volatility_benchmark', value: analytics(data, 'volatility.domain.benchmark', domain.value) },
    ]
  })
  const performanceKpi = computed(() => {
    const data = performance.value
    if (!data) return []
    return [
      { title: 'performance', value: analytics(data, 'performance.domain.fund', domain.value) },
      { title: 'performance_benchmark', value: analytics(data, 'performance.domain.benchmark', domain.value) },
    ]
  })

  const getPerformanceTable = periods => {
    return computed(() => {
      const data = performance.value
      if (!data) return
      return ['fund', 'benchmark'].map(metric => {
        return periods.reduce(
          (acc, period) => {
            acc[period] = analytics(data, `performance.${period}.${metric}`, domain.value)
            return acc
          },
          { metric },
        )
      })
    })
  }
  const riskIndicators = computed(() => {
    const data = performance.value
    if (!data) return []
    return [
      { title: 'tracking_error', value: analytics(data, 'tracking_error.domain', domain.value) },
      { title: 'information_ratio', value: analytics(data, 'information_ratio.domain', domain.value) },
      { title: 'sharpe_ratio', value: analytics(data, 'sharpe_ratio.domain', domain.value) },
    ]
  })
  return {
    performance,
    volatility,
    domain,
    performanceKpi,
    riskIndicators,
    getPerformanceTable,
    getPerformance,
  }
}
