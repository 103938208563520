<template lang="pug">
code-block.no-margin(:content="profile" language="js")
.tooltip.absolute.top-2.right-2.border-t.border-primary.bg-white.z-10.shadow.text-sm
  .h-2.w-2.rounded-full.bottom-8.h-3.w-3.px-2.py-1
  .absolute.bottom-8.right-2.flex.flex-col
    .text-gray-400.text-sm Press Shift + Click to pan
    button.bg-gray-100.rounded-sm Reset zoom
  .tooltip.absolute.top-2.right-2.border-t.border-primary.bg-white.z-10.shadow.text-sm
      .flex.flex-col
        .text-gray-500.border-b.px-2.py-1
        .flex.flex-row.px-2.py-1.items-center.gap-2
          .h-2.w-2.rounded-full
  .legend.flex.flex-row.justify-start.items-center.gap-4
    .flex.flex-row.justify-center.items-center.gap-1
      .h-3.w-3.rounded-full
      span 
</template>
<script>
import { ref } from 'vue'
import { useRoute } from '@100-m/hauru'

export default {
  setup() {
    const route = useRoute()
    const token = route.query.token
    // await new Promise()
    const profile = ref()
    fetch('https://natixis-dev-archipel.eu.auth0.com/userinfo', {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then(response => {
        if (!response.ok) {
          window.rr = response
          return response.text().then(r => {
            throw Error(r)
          })
        }
        return response
      })
      // .catch(err => err)
      .then(r => r.json())
      .then(r => (profile.value = r))
      .catch(err => (profile.value = err))
    return {
      token,
      route,
      profile,
    }
  },
}
</script>
<style>
.no-margin pre[class*='language-'] {
  margin: 0;
  color: white !important;
}
</style>
