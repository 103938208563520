<template lang="pug">
wait-for(:wait="loaded")
  .bg-white.flex.flex-col.min-h-full(class="sm:p-4")
    cjs-pie-chart(:data="formattedData" :options="options")
      template(v-slot:tooltip="{tooltip}")
        table-tooltip(v-if="tooltip.shown" :title="tooltip.title" :rows="formatRows(tooltip.rows)")
</template>

<script>
import WaitFor from "../../../components/WaitFor.vue";
import usePerformanceMocks from "../../../composables/usePerformanceMocks";
import {computed} from "vue";

export default {
  components: {WaitFor},
  methods: {
    formatTitle(title) {
      return new Date(title).format('day, mon, year', $root.lang).titleize()
    },
    formatRows(rows) {
      return rows.map(r => {
        return {
          label: this.t[r.label] || r.label,
          value: format('.1%')(r.value),
          color: r.color,
        }
      })
    }
  },
  setup() {
    const {performances, loaded} = usePerformanceMocks('allocationByCountry')
    const formattedData = computed(() => {
      const sorted = performances.value.sort((a, b) => b.value - a.value)
      return [...sorted.slice(0, 5).map(d => ({...d, country: d.country || 'N/A'})), {country: 'Autres', value: 1 - sorted.slice(0, 5).reduce((a, b) => a + b.value, 0)}]
    })
    const options = {
      labelKey: 'country',
      datasets: [{
        label: 'Allocation',
        key: 'value',
      }],
      formatY: (value) => format('.2')(value) + '%',
      labelFormatter: (value) => format('.1f')(value) + '%',
    }

    return {
      performances,
      options,
      loaded,
      formattedData
    }
  },
}
</script>

