<template lang="pug">
component(:is="layout")
</template>

<script>
import context from './features/context.js'
import xfilter from './features/xfilter.js'
import { useTranslation } from './features/translation.js'
import { useRTDB } from './features/firebase.js'
import { provide, ref } from 'vue'
import Layout from './Layout.vue'
export default {
  mixins: [
    context,
    xfilter,
  ],
  setup() {
    const progress = ref(0)
    provide('progress', progress)
    return {
      ...useTranslation(),
      ...useRTDB(),
      progress,
    }
  },
  computed: {
    layout() {
      if (!$root.app || !$root.$config.apps[$root.app]?.layout) return Layout
      return $root.$config.apps[$root.app].layout
    },
  },
  mounted() {
    this.$auth.initAuth()
  },
}
</script>
