import { ref, computed } from 'vue'
import { merge } from '../utils/merge'

const translations = ref({})

export function setTranslation(translation, key = 'default') {
  translations.value[key] = merge(translations.value[key] || {}, translation)
}
export function useTranslation() {
  const lang = ref(localStorage.LANG || 'en')
  const t = computed(() => {
    // TODO don't rely on $root.app
    const app = $root.app || 'default'
    const current = translations.value[app][lang.value] || {}
    const def = translations.value.default[lang.value] || {}
    return { ...def, ...current }
  })
  const langs = computed(() => {
    const app = $root.app || 'default'
    return Object.keys(translations.value[app])
  })
  return {
    lang,
    translations,
    t,
    langs,
  }
}
