<template lang="pug">
wait-for(:wait="loaded")
  .bg-white.flex.flex-col.min-h-full(class="sm:p-4")
    dx-radar-chart(:data="performances" :options="options")
      template(v-slot:tooltip="{tooltip}")
        table-tooltip(v-if="tooltip.shown" :title="tooltip.title" :rows="formatRows(tooltip.rows)")
</template>

<script>
import WaitFor from "../../../components/WaitFor.vue";
import usePerformanceMocks from "../../../composables/usePerformanceMocks";

export default {
  components: {WaitFor},
  methods: {
    formatTitle(title) {
      return new Date(title).format('day, mon, year', $root.lang).titleize()
    },
    formatRows(rows) {
      return rows.map(r => {
        return {
          ...r,
          label: this.t[r.label] || r.label,
          value: format('.2%')(r.value),
        }
      })
    }
  },
  setup() {
    const {performances, loaded} = usePerformanceMocks('allocationByCountry')
    const options = {
      labelKey: 'country',
      datasets: [{
        label: 'Fund',
        key: 'value',
      },{
        label: 'Benchmark',
        key: 'value',
      },],
      formatY: (value) => format('.0%')(value),
      labelFormatter: (value) => format('.1f')(value) + '%',
    }

    return {
      performances,
      options,
      loaded,
    }
  },
}
</script>
<style scoped>

</style>
