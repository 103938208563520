<template lang="pug">
widget-wrapper(:wait="performance" :portfCode="portfCode" :portfShareCode="portfShareCode")
  .flex.flex-col.bg-white.p-4.gap-5
    .flex.flex-row.items-center.gap-4
      .flex.flex-row.gap-2(v-if="activeDomain")
        date-picker(v-model="activeDomain[0]" :dates="calendarDates.filter(d => d <= formattedActiveDomain[1])" @update:modelValue="ev => updateDomain(ev, activeDomain[1])")
        span -
        date-picker(v-model="activeDomain[1]" :dates="calendarDates.filter(d => d >= formattedActiveDomain[0])" @update:modelValue="ev => {updateDomain(activeDomain[0], ev); persistDate(portfCode, ev)}")
      radio-toggle(:options="periods" v-model="selectedPeriod" v-if="periods")
      select.border.border-gray-200.rounded.text-gray-400.text-sm(class="focus:ring-primary" :class="{ active: !!activePeriod }" v-model="activePeriod")
        option(:value="null") {{ $root.t.yearly }}
        option(v-for="period in yearlyPeriods" :value="period") {{ t[period] || period }}
      button.bg-gray-100.rounded-sm(v-if="performance && activeDomain && new Date(activeDomain[1]).format('YYYY-MM-DD') !== performance.last().date" @click="setZoom(null)") {{ $root.t.reset_period }}
      download-button.ml-auto(:items="downloadItems")
    .flex.flex-col.gap-3(v-if="activeDomain")
      .flex.flex-row.items-center.gap-4
        h3 {{ titleize($root.t.performance_indicators) }}
        span.text-gray-400 {{ titleize($root.t.from) }} {{ titleize(activeDomain[0].format('day, mon, year', $root.lang))}} {{ titleize($root.t.to) }} {{ titleize(activeDomain[1].format('day, mon, year', $root.lang))}}
      dice-table.performance-table.small(:data="performanceTableData" :columns="performanceTableColumns" :metadata="performanceTableMetadata" @plotclick="log")
      .flex.flex-col
        .chart-disclaimer {{ $root.t.performance_disclaimer }}
        .chart-disclaimer(v-if="benchmarkComposition") {{ $root.t.comparison_index }}: {{ benchmarkComposition}}
    .flex.flex-col.relative.gap-3.flex-1(v-if="activeDomain")
      .flex.flex-row.items-center.gap-4
        h3 {{ titleize($root.t.historical_performance) }}
        span.text-gray-400 {{ titleize($root.t.from) }} {{ titleize(activeDomain[0].format('day, mon, year', $root.lang))}} {{ $root.t.at }} {{ titleize(activeDomain[1].format('day, mon, year', $root.lang))}}
        radio-toggle(:options="[{ value: false, name: $root.t.fund_and_benchmark }, { value: true, name: $root.t.delta_fund_benchmark }]" v-model="showDelta")
      cjs-wrapper.no-tooltip.bg-white.h-full.flex-1(:data="performance" :metadata="lineMetadata" @chartUpdate="chartUpdate" @zoom="onZoom" style="min-height: 280px; max-height: 320px;")
      .flex.flex-col
        .chart-disclaimer(v-if="benchmarkComposition") {{ $root.t.comparison_index }}: {{ benchmarkComposition}}
        .chart-disclaimer(v-html="fundMetadata?.disclaimer?.global[$root.lang]")
        .chart-disclaimer(v-html="fundMetadata?.disclaimer?.share[$root.lang]")
        //- template(#legend="{ metadata }")
        //-   .flex.flex-row
        //-     .legend.flex.flex-row.justify-start.items-center.gap-4(style="min-width: 280px")
        //-       .flex.flex-row.justify-center.items-center.gap-1(v-for="plot in metadata.plots")
        //-         .h-3.w-3.rounded-full(:style="{ background: plot.color }")
        //-         span {{ plot.name || $root.t[plot.yAxisKey] || plot.yAxisKey }}
        //-     radio-toggle(:options="[{ value: false, name: $root.t.fund_and_benchmark }, { value: true, name: $root.t.delta_fund_benchmark }]" v-model="showDelta")
</template>
<script>
import { computed, ref, watch } from 'vue'
import { differenceInYears } from 'date-fns'
import { getFundMetadata, getPerformance } from '@/composables/api'
import { analytics } from '@/composables/analytics'
import { cellFormatter, titleize, getCssVar, filterPeriods, findNearestDate } from '@/composables/utils'
import { useEmbedComponent, useStoredValues } from '@/composables/embed.js'

export default {
  setup() {
    const config = useEmbedComponent('widgets.performance')
    const { portfCode, portfShareCode, watcher } = config
    const { getDate, persistDate } = useStoredValues()
    const performance = ref()
    const setZoom = ref()
    const calendarDates = ref()
    const fundMetadata = ref()
    const periods = ref()
    const state = ref('init')
    const activePeriod = ref(null)
    const selectedPeriod = ref('inception')
    const activeDomain = ref(null)
    const domain = ref(null)
    const benchmarkType = ref()
    const benchmarkName = ref()
    let lastDateAvailable
    async function getData() {
      state.value = 'loading'
      performance.value = null
      const perf = await getPerformance(portfCode.value, portfShareCode.value)
      if (!perf) {
        performance.value = []
        return
      }
      calendarDates.value = perf.map(d => d.date)
      fundMetadata.value = await getFundMetadata(portfCode.value, portfShareCode.value)
      benchmarkType.value = fundMetadata.value.benchmarkType
      benchmarkName.value = fundMetadata.value.benchmarkName
      periods.value = filterPeriods(fundMetadata.value.periods, calendarDates.value)
      performance.value = perf
      if (!performance.value || performance.value.error || performance.value.length === 0) return
      lastDateAvailable = new Date(performance.value[performance.value.length - 1].date)
      const startingDate = getDate(portfCode.value)
      const endDate = startingDate ? findNearestDate(startingDate, calendarDates.value) : lastDateAvailable
      activeDomain.value = [performance.value[0].date, endDate].map(d => new Date(d))
      domain.value = [performance.value[0].date, endDate].map(d => new Date(d))
      // selectedPeriod.value = periods.value.last()
      // state.value = 'ready'
    }
    const benchmarkComposition = computed(() => {
      if (benchmarkType.value !== 'comparison') return
      return benchmarkName.value
    })
    getData()
    watcher(getData)
    const showAnnualizedPerformance = computed(() => {
      // If fund is not short_term and domain < 1y dont show annualized
      if (!activeDomain.value) return 0
      const numberOfDays = (activeDomain.value.last() - activeDomain.value.first()) / (1000 * 60 * 60 * 24)
      return numberOfDays > 365 || fundMetadata?.value?.perfType === 'short_term'
    })
    const displayedDomain = computed(() => {
      if (activeDomain.value) return activeDomain.value.map(d => d.format('day, mon, year', $root.lang))
      return [performance.value[0].date, performance.value[performance.value.length - 1].date].map(d => new Date(d).format('day, mon, year', $root.lang))
    })
    const formattedActiveDomain = computed(() => {
      if (activeDomain.value) return activeDomain.value.map(d => d.format('YYYY-MM-DD'))
      return [performance.value[0].date, performance.value[performance.value.length - 1].date].map(d => new Date(d).format('YYYY-MM-DD'))
    })
    const volatility = computed(() => {
      if (!performance.value || performance.value.error) return
      return analytics(performance.value, 'performance', 'fund', activeDomain.value)
    })
    const onZoom = ev => {
      // if (ev === activeDomain.value) return
      activeDomain.value = ev || [domain.value[0], domain.value[1]]
      if (!ev && !!activePeriod.value) activePeriod.value = null
      if (!ev && !!selectedPeriod.value) selectedPeriod.value = null
      if (!ev) return
      // if (ev[0].format('YYYY') === ev[1].format('YYYY')) activePeriod.value = ev[0].format('YYYY')
    }
    watch(activePeriod, () => {
      if (!activePeriod.value) return setZoom.value(null)
      selectedPeriod.value = null
      const start = findNearestDate(new Date(activePeriod.value, 0, 1).minus('1 day'), calendarDates.value)
      const end = findNearestDate(new Date(activePeriod.value, 11, 31), calendarDates.value)
      setZoom.value(start, end)
    })
    const isEom = (date, dates) => {
      const oneDayInMs = 1000 * 60 * 60 * 24
      if (new Date(date.getTime() + oneDayInMs).getDate() === 1) {
        return true
      }
      // If date is last date available in data, skip eom check
      if (dates.last() === date.format('YYYY-MM-DD')) {
        return false
      }
      const month = date.format('YYYY-MM')
      const monthDates = dates.filter(d => d.slice(0, 7) === month)
      return monthDates[monthDates.length - 1] === date.format('YYYY-MM-DD')
    }
    function rewindDate(date, value, dates) {
      if (value === 'ytd') return date.start('year').minus('1 day').start('day')
      if (value === 'mtd') return date.start('month').minus('1 day').start('day')
      if (value === '1w') return date.minus('7 days').start('day')
      const last = value.slice(-1)
      if (last === 'y') return date.minus(value.replace('y', ' years'))
      if (last === 'm') {
        const newDate = date.minus(value.replace('m', ' months'))
        if (isEom(date, dates)) return newDate.end('month')
        return newDate
      }
      return date
    }
    watch(selectedPeriod, () => {
      if (!selectedPeriod.value) return
      activePeriod.value = null
      const lastDate = activeDomain.value[1] || calendarDates.value.last()
      const end = new Date(lastDate)
      if (selectedPeriod.value === 'inception') return setZoom.value(new Date(calendarDates.value[0]), end)
      const start = findNearestDate(rewindDate(end, selectedPeriod.value, calendarDates.value), calendarDates.value)
      // const start = end.start('year')
      setZoom.value(start, end)
    })
    function updateDomain(start, end) {
      selectedPeriod.value = null
      if (start > end || end < start) return setZoom.value(end, start)
      setZoom.value(start, end)
    }
    watch(activeDomain, (newDomain, oldDomain) => {
      // if (!setZoom.value) return
      if (!lastDateAvailable || !activeDomain.value || !activeDomain.value[1]) return
      // if (startingDate.value || lastDateAvailable.getTime() !== activeDomain.value[1].getTime()) {
      //   startingDate.value = activeDomain.value[1]
      // }
      // setZoom.value(activeDomain.value[0], activeDomain.value[1])
    })
    const showDelta = ref(false)
    const getPlots = (diff, benchmarkType) => {
      if (diff) {
        return [
          {
            type: 'line',
            yAxisKey: 'diff',
            color: getCssVar('--diff'),
          },
        ]
      }
      return [
        {
          type: 'line',
          yAxisKey: 'fund',
          color: getCssVar('--fund'),
        },
        {
          type: 'line',
          yAxisKey: 'benchmark',
          name: benchmarkType === 'comparison' ? $root.t['comparison_index'] : $root.t['reference_index'],
          color: getCssVar('--benchmark'),
        },
      ]
    }
    const domainYears = computed(() => {
      if (!activeDomain.value) return
      return differenceInYears(activeDomain.value[1], activeDomain.value[0])
    })
    function getUnitFromYears(years) {
      if (years <= 1) return 'month'
      if (years <= 5) return 'quarter'
      return 'year'
    }
    const lineMetadata = computed(() => {
      const plots = getPlots(showDelta.value, benchmarkType.value)
      // console.log('d', activeDomain.value, domainYears.value)
      return {
        xAxisKey: 'date',
        xAxisType: 'time',
        plots,
        grid: {
          x: {
            color: 'rgba(0,0,0,0.1)',
            style: 'dash',
          },
          y: {
            color: 'rgba(0,0,0,0.1)',
            style: 'dash',
          },
        },
        zoom: {
          onZoom: d => ({}),
        },
        guideline: true,
        locale: $root.lang,
        timeAxisFormat: {
          unit: getUnitFromYears(domainYears.value),
          displayFormats: {
            quarter: 'MM/yyyy',
            month: 'MM/yyyy',
          },
        },
      }
    })

    const _indicators = ['performance', 'performance_annualized', 'volatility', 'max_drawdown_value']
    const fundIndicators = ['tracking_error', 'information_ratio']
    const indicators = _indicators.concat(fundIndicators)
    const metrics = ['fund', 'benchmark', 'diff']
    const indicatorFormats = {
      information_ratio: '.2f',
      tracking_error: '.2%',
      max_drawdown_value: '.2%',
    }
    const evolutionColumns = ['performance', 'performance_annualized']
    const performanceTableColumns = computed(() => {
      return [
        {
          key: 'metric',
          name: null,
          format: metric => {
            return benchmarkType.value === 'comparison' && (metric.toLowerCase().includes('index') || metric.toLowerCase().includes('indicateur'))
              ? $root.t['comparison_index']
              : metric
          },
          size: 1,
        },
      ].concat(
        indicators.map(indicator => ({
          key: indicator,
          name: indicator,
          format: evolutionColumns.includes(indicator)
            ? cellFormatter(format(indicatorFormats[indicator] || '.2%'))
            : format(indicatorFormats[indicator] || '.2%'),
        })),
      )
    })

    const performanceTableMetadata = {
      borderLeftColor: row => getCssVar(`--${row.metric}`),
    }
    const performanceTableData = ref(
      metrics.map(metric => {
        return indicators.reduce(
          (acc, indicator) => {
            acc[indicator] = '-'
            return acc
          },
          { metric },
        )
      }),
    )
    // const performanceTableData = computed(() => {
    //   return metrics.map(metric => {
    //     const name = benchmarkType.value === 'comparison' && metric === 'benchmark' ? 'comparison_index' : metric
    //     return indicators.reduce(
    //       (acc, indicator) => {
    //         acc[indicator] = '-'
    //         return acc
    //       },
    //       { metric, name },
    //     )
    //   })
    // })
    function dateIsEom(date) {
      const last = calendarDates.value.last()
      if (new Date(last).format('YYYY-MM-DD') === date.format('YYYY-MM-DD')) {
        return false
      }
      return calendarDates.value.filter(d => new Date(d).format('YYYY-MM') === date.format('YYYY-MM')).last() === date.format('YYYY-MM-DD')
    }
    const updateIndicator = (metric, indicator) => {
      if (!performance.value || performance.value.error || state.value !== 'ready') return
      const rowIndex = metrics.indexOf(metric || 'fund')
      if (indicator === 'performance_annualized') {
        if (!showAnnualizedPerformance.value) return (performanceTableData.value[rowIndex][indicator] = '-')
        if (activeDomain.value) {
          const end = activeDomain.value.last()
          const isEom = dateIsEom(end)
          if (isEom) {
            return (performanceTableData.value[rowIndex][indicator] = analytics(
              performance.value,
              'performance_annualized_calendar',
              metric,
              activeDomain.value,
            ))
          }
        }
      }
      performanceTableData.value[rowIndex][indicator] = analytics(performance.value, indicator, metric, activeDomain.value)
    }
    const updateIndicators = () => {
      const updateQueue = indicators
        .map(indicator => {
          if (fundIndicators.includes(indicator)) return [[null, indicator]]
          return metrics.map(metric => [metric, indicator])
        })
        .flat()
      // We do this so analytics computing does not block the thread, resulting in janky animations
      let i = 0
      function loop() {
        if (i < updateQueue.length) {
          const [metric, indicator] = updateQueue[i]
          updateIndicator(metric, indicator)
          i++
          window.requestAnimationFrame(loop)
        }
      }
      loop()
    }

    watch(activeDomain, updateIndicators)
    watch(performance, updateIndicators)
    const yearlyPeriods = computed(() => {
      if (!performance.value || performance.value.error) return
      return performance.value.map(d => new Date(d.date).format('YYYY')).unique()
    })
    const activePerformance = computed(() => {
      if (!activeDomain.value || !performance.value || performance.value.error) return performance.value
      const domain = activeDomain.value.map(d => d.format('YYYY-MM-DD'))
      return performance.value.filter(d => domain[0] <= d.date && d.date <= domain[1])
    })
    const downloadItems = computed(() => {
      return [
        { name: titleize($root.t.performance_indicators), data: performanceTableData.value },
        { name: titleize($root.t.historical_performance), data: activePerformance.value },
      ]
    })
    function chartUpdate(ev) {
      setZoom.value = ev.setZoom
      state.value = 'ready'
      selectedPeriod.value = periods.value.filter(d => d !== 'inception').last()
    }
    return {
      performance,
      setZoom,
      chartUpdate,
      updateDomain,
      persistDate,
      onZoom,
      periods,
      yearlyPeriods,
      activePeriod,
      activeDomain,
      domain,
      displayedDomain,
      lineMetadata,
      volatility,
      log: console.log,
      performanceTableColumns,
      performanceTableData,
      performanceTableMetadata,
      ...config,
      selectedPeriod,
      downloadItems,
      titleize,
      activePerformance,
      showDelta,
      fundMetadata,
      formattedActiveDomain,
      calendarDates,
      benchmarkComposition,
    }
  },
}
</script>
<style scoped>
select.active {
  @apply border-accent border-2 text-black;
}
</style>
<style>
.perf-cell .chevron {
  @apply text-green-600;
}
.perf-cell.negative .chevron {
  @apply text-red-600 transform rotate-180;
}
</style>
