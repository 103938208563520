<template lang="pug">
vue-markdown-it.markdown.prose(:source="content" :plugins="plugins")
</template>
<script>
import { ref } from 'vue'
import { useAxios } from '@100-m/hauru/src/utils/axios.js'
import 'highlight.js/styles/monokai.css';
import MarkdownItHighlightjs from 'markdown-it-highlightjs';


export default {
  // components: {
  //   gettingStarted,
  // },
  setup() {
    const content = ref('')
    const axios = useAxios()
    axios.get('/content/getting-started.md').then(r => content.value = r.data)
    return { 
      content,
      plugins: [
        {
          plugin: MarkdownItHighlightjs
        }
      ]
    }
  },
}
</script>
<style>
.markdown.prose {
  max-width: 100%;
}
.prose code::before, 
.prose code::after {
  display: none;
}
</style>