<template lang="pug">
section.p-6.bg-gray-100.w-full.h-full.container.mx-auto.demo-page
  .flex.flex-col.gap-4
    h2.mb-2 Fond actif
    .flex.flex-row.gap-4
      fieldset.period.flex.flex-col
        label.text-gray-500.mb-1.text-sm Choisissez un fond
        select.border.border-gray-200.rounded-full(class="focus:ring-primary" v-model="activeFundId")
          option(v-for="fund, fundId in funds" :value="fundId") {{ fund.name }}
      fieldset.period.flex.flex-col(v-if="activeFund")
        label.text-gray-500.mb-1.text-sm Choisissez une part
        select.border.border-gray-200.rounded-full(class="focus:ring-primary" v-model="activeShareIsin")
          option(v-for="shareCode, shareIsin in activeFund.shares" :value="shareIsin") {{ shareIsin }}
    h1 {{ activeFund.name }} - {{ activeShareIsin }}
    iframe#test.w-full.bg-gray-400.border.shadow-sm.h-screen(style="height: 700px;" ref="performanceWidget" :src="`${rootLocation}/embed/widgets/performance`")
</template>
<script>
import { ref, computed, watch } from 'vue'
import funds from './funds.yml'
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
export default {
  setup() {
    const activeFundId = ref('IFM_111')
    const activeShareIsin = ref('FR0007480389')
    const activeFund = computed(() => {
      return funds[activeFundId.value]
    })
    const activeShareCode = computed(() => {
      if (!activeFund.value || !activeShareIsin.value) return
      return activeFund.value.shares[activeShareIsin.value]
    })
    watch(activeFund, () => {
      if (activeFund.value.shares[activeShareIsin.value]) return
      activeShareIsin.value = Object.keys(activeFund.value.shares)[0]
    })
    const rootLocation = window.location.origin
    const performanceWidget = ref()
    const allocationWidget = ref()
    const components = [performanceWidget] //, allocationWidget]
    // window.allocationWidget = allocationWidget
    function postEvent(name, data) {
      console.log('posting', name, data)
      components.map(component => component.value.contentWindow.postMessage({ name, data }, '*'))
    }
    watch(activeShareCode, () => {
      postEvent('portfShareCode', activeShareCode.value)
    })
    return {
      performanceWidget,
      rootLocation,
      funds,
      activeFundId,
      activeShareCode,
      activeShareIsin,
      activeFund,
    }
  },
}
</script>
<style>
.mobile-widget {
  min-width: 375px;
  min-height: 667px;
  max-width: 375px;
  max-height: 667px;
}
.demo-page .tab.active {
  @apply border-accent;
}
</style>
