<template lang="pug">
section.p-4.bg-gray-100.w-full.h-full
  .flex.flex-row.items-center.gap-4.mb-4
    fieldset.flex.flex-col
      label Fund
      select(v-model="activeFund")
        option(v-for="fund in displayedFunds" :value="fund") {{ fund.name }}
    fieldset.flex.flex-col(v-if="activeFund")
      label Share
      select(v-model="activeShare")
        option(v-for="share in activeFund.shares" :value="share.portfShareCode") {{ share.isin }}
    fieldset.gap-2.flex.flex-row.items-center
      input#showall(name="showall" type="checkbox" v-model="showAllFunds")
      label(for="showall") Afficher les fonds hors périmètre de test
    fieldset.flex.flex-col
      label Language
      select(v-model="lang")
        option(value="fr") FR
        option(value="it") IT
        option(value="en") EN
  .flex.flex-row.tabs
    .tab.p-4.bg-gray-100.cursor-pointer(@click="activeTab = 'performance'" :class="{ active: activeTab === 'performance' }") Performance
    .tab.p-4.bg-gray-100.cursor-pointer(@click="activeTab = 'riskIndicators'" :class="{ active: activeTab === 'riskIndicators' }") Risk Indicators
    .tab.p-4.bg-gray-100.cursor-pointer(@click="activeTab = 'holdings'" :class="{ active: activeTab === 'holdings' }") Holdings
    .tab.p-4.bg-gray-100.cursor-pointer(@click="activeTab = 'breakdown'" :class="{ active: activeTab === 'breakdown' }") Breakdown
    .tab.p-4.bg-gray-100.cursor-pointer(@click="activeTab = 'drilldown'" :class="{ active: activeTab === 'drilldown' }") Drilldown
  iframe#test(:src="activeUrl" style="min-height: 1400px;")
</template>
<script>
import { ref, computed, watch } from 'vue'
import { getFunds, getPerformance } from '@/composables/api'

export default {
  setup() {
    const funds = ref()
    const activeFund = ref()
    const activeShare = ref()
    const test = ref()
    const activeTab = ref('performance')
    const showAllFunds = ref(false)
    const lang = ref($root.lang)
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('token')
    async function init() {
      const data = await getFunds()
      funds.value = Object.values(data).sort(d => d.name) //.sort(d => ['Mirova Europe Sustainable Economy Fund', 'Natixis Diversified Solution'].indexOf(d.name))
    }
    init()
    async function getData() {
      if (!activeShare.value) return
      // test.value = await getPerformance(activeFund.value.portfCode, activeShare.value)
    }
    const currentTab = ref()
    const activeUrl = computed(() => {
      if (!activeFund.value) return
      let url = `${window.location.origin}/embed/widgets/${activeTab.value}?portfCode=${activeFund.value.portfCode}&portfShareCode=${activeShare.value}&lang=${lang.value}`
      if (token) return url + `&token=${token}`
      return url
    })
    const displayedFunds = computed(() => {
      if (showAllFunds.value) return funds.value
      return funds.value
        ?.map(d => {
          const shares = d.shares.filter(share => share.hasPerf)
          return { ...d, shares }
        })
        .filter(d => d.shares.length)
    })
    watch(displayedFunds, () => {
      // if (!displayedFunds.value) return
      // if (activeFund.value && displayedFunds.value.map(d => d.portfCode).includes(activeFund.value.portfCode)) return
      activeFund.value = displayedFunds.value[0]
    })
    watch(activeShare, getData)
    watch(activeFund, () => (activeShare.value = activeFund.value.shares[0].portfShareCode))
    watch(lang, () => ($root.lang = lang.value))
    return {
      funds,
      test,
      activeShare,
      activeFund,
      activeTab,
      activeUrl,
      showAllFunds,
      displayedFunds,
      lang,
    }
  },
}
</script>
<style>
.tab.active {
  @apply border-b-2 border-primary bg-white;
}
</style>
