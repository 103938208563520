<template lang="pug">
.flex.flex-col
  h3 Api test
  pre {{ test || 'loading...' }}
  h3 Performance call
  pre {{ performance || 'loading...' }}
</template>
<script>
import { onMounted, ref } from 'vue'
import axios from 'axios'
import { getPerformance } from '@/composables/bone'
export default {
  setup() {
    const test = ref()
    const performance = ref()
    onMounted(() => {
      axios.get(localStorage.API_BONE + '/ping').then(r => (test.value = r.data))
      getPerformance({
        portfShareCode: '410089_',
        fromDate: new Date('2011-02-26'),
        toDate: new Date('2021-04-30'),
      }).then(r => (performance.value = r))
    })
    return { test, performance }
  },
}
</script>
