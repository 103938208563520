<template lang="pug">
section.p-6.bg-gray-100.w-full.h-full.container.mx-auto.demo-page
  h1.mb-6 DEMO: Croissance Diversifiée - FR0007480389 - Part R/A (EUR)
  //- iframe.shadow-sm.border.mb-6(style="border-top-color: var(--primary); height: 140px;" :src="`${rootLocation}/embed/kpi/kpiList?title=Key fund info`" ref="kpiListWidget")
  .flex.flex-row
    .tab.text-inactive.border-b-2.border-transparent.px-4.py-3.cursor-pointer(
      v-for="tab in ['performance_analysis', 'riskIndicators', 'Breakdown', 'Allocation', 'Drilldown', 'holdings']"
      :key="tab"
      :class="{ active: activeTab === tab }"
      @click="activeTab = tab"
    ) {{ $root.t[tab] || tab }}
  iframe#test.w-full.bg-gray-400.border.mb-4.shadow-sm.h-screen(style="height: 740px;" v-if="activeTab === 'performance_analysis'" ref="performanceWidget" :src="`${rootLocation}/embed/widgets/performance`")
  iframe.w-full.bg-gray-400.border.mb-4.shadow-sm(v-else-if="activeTab === 'riskIndicators'" ref="riskWidget"  style="height: 1140px;" :src="`${rootLocation}/embed/widgets/riskIndicators`")
  iframe.w-full.bg-gray-400.border.mb-4.shadow-sm(v-else-if="activeTab === 'Breakdown'" ref="breakdownWidget"  style="height: 630px;" :src="`${rootLocation}/embed/widgets/breakdown`")
  iframe.w-full.bg-gray-400.border.mb-4.shadow-sm.h-screen(v-if="activeTab === 'Allocation'" ref="allocationWidget" :src="`${rootLocation}/embed/widgets/allocation`")
  iframe.w-full.bg-gray-400.border.mb-4.shadow-sm(v-else-if="activeTab === 'Drilldown'" ref="holdingsWidget"  style="height: 640px;" :src="`${rootLocation}/embed/widgets/drilldown`")
  iframe.w-full.bg-gray-400.border.mb-4.shadow-sm(v-else-if="activeTab === 'holdings'" ref="holdingsWidget"  style="height: 640px; max-width: 720px;" :src="`${rootLocation}/embed/widgets/holdings`")


  //- .flex.flex-col.justify-center.items-center
  //-   h2.mb-2 Performance
  //-   iframe#test.w-full.bg-gray-400.border.max-w-6xl.mb-4(ref="performanceWidget" style="height: 700px;" :src="`${rootLocation}/embed/performance/historical`")
  //-   h2.mb-2 Allocation breakdown portfolio
  //-   iframe.w-full.bg-gray-400.border.max-w-6xl.mb-4(ref="allocationWidget" :src="`${rootLocation}/embed/allocation/allocationBreakdown?dimensions=asset_class,sector,country,currency,maturity,region,zone`")
  //-   h2.mb-2 Allocation breakdown benchmark
  //-   iframe.w-full.bg-gray-400.border.max-w-6xl.mb-4(ref="allocationWidget" :src="`${rootLocation}/embed/allocation/allocationBreakdown?dimensions=asset_class,sector,country,currency,maturity,region,zone&axis=benchmark`")

    //- h1.mb-4 Mobile version
    //- .flex.flex-row
    //-   .flex.flex-col
    //-     h2.mb-2 Performance
    //-     iframe#test.w-full.bg-gray-400.border.max-w-6xl.mb-4.mobile-widget(ref="performanceWidget" style="height: 700px;" :src="`${rootLocation}/embed/performance/historical`")
    //-   .flex.flex-col
    //-     h2.mb-2 Allocation breakdown
    //-     iframe.w-full.bg-gray-400.border.max-w-6xl.mb-4.mobile-widget(ref="allocationWidget" :src="`${rootLocation}/embed/allocation/allocationBreakdown`")
</template>
<script>
import { ref, computed, watch } from 'vue'
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
export default {
  setup() {
    const activeTab = ref('performance_analysis')
    const rootLocation = window.location.origin
    const performanceWidget = ref()
    const allocationWidget = ref()
    const components = [performanceWidget] //, allocationWidget]
    // window.allocationWidget = allocationWidget
    function postEvent(name, data) {
      console.log('posting', name, data)
      components.map(component => component.value.contentWindow.postMessage({ name, data }, '*'))
    }
    return {
      rootLocation,
      activeTab,
      performanceWidget,
      allocationWidget,
    }
  },
}
</script>
<style>
.mobile-widget {
  min-width: 375px;
  min-height: 667px;
  max-width: 375px;
  max-height: 667px;
}
.demo-page .tab.active {
  @apply border-accent;
}
</style>
