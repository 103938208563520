<template lang="pug">
.min-h-full.bg-white.p-2(class="sm:px-6")
  .container
    .flex.flex-row.justify-between.items-center
      .flex.flex-row.mb-4
        .tab.p-2.border-b.border-gray-200.text-gray-500.cursor-pointer(v-for="dimension in dimensions" class="hover:text-bold sm:py-3 sm:px-5" :class="{ active: activeDimension === dimension }" @click="activeDimension = dimension") {{ t[dimension] || dimension }}
      select.border.border-gray-200.rounded-full.pl-4(style="height: min-content" class="focus:ring-primary" v-model="axis")
        option(v-for="ax in ['portfolio', 'benchmark']" :value="ax") {{ t[ax] }}
    wait-for(:wait="allocation")
      dice-allocation-chart.mb-6(:data="allocation" :metadata="{ format: '.2%' }")
      dice-table.table.w-full(v-if="drilldown" :data="drilldownTableData" :columns="drilldownTableColumns" :metadata="drilldownTableMetadata")
        template(v-slot:cell-key="{ row, col }")
          .flex.flex-row.items-center
            .color
            div {{ t[row[col.key]] || row[col.key] }}
</template>
<script>
import { useRoute } from '@100-m/hauru'
import { useEmbedComponent } from '@/composables/embed.js'
import { getAllocation } from '@/composables/bone'

import { computed, ref, watch, reactive } from 'vue'
export default {
  setup() {
    const route = useRoute()
    const config = useEmbedComponent('allocation.allocationBreakdown')
    const { dimensions, activeDimension, portfCode, axis, drilldown, watcher } = config
    activeDimension.value = activeDimension.value || dimensions.value[0]

    const allocation = ref()
    const drilldownTableColumns = [
      { key: 'key', name: $root.t.fund },
      { key: 'value', name: $root.t.weight, format: '.2%' },
    ]
    const drilldownTableData = ref()
    const drilldownTableMetadata = {
      borderLeftColor: (row, idx) => `var(--cat${idx + 1})`,
      // isRowActive: (row, idx) => this.hover === idx,
    }

    async function getData() {
      if (!activeDimension.value || !portfCode.value) return
      const { error, allocationData, drilldownData } = await getAllocation(portfCode.value, activeDimension.value, axis.value, drilldown.value)
      if (error) {
        allocation.value = error
        drilldownTable.value = null
        return
      }
      allocation.value = allocationData
      drilldownTableData.value = drilldownData
    }
    getData()
    watcher(getData)
    return {
      allocation,
      ...config,
      activeDimension,
      drilldownTableColumns,
      drilldownTableData,
      drilldownTableMetadata,
    }
  },
}
</script>
