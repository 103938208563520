<template lang="pug">
//- block.performance-historical(type="plot-line" :data="performanceHistorical" style="height: 100%;" @brush="onBrush" :metadata="metadata")
wait-for(:wait="performance")
  .bg-white.flex.flex-col.min-h-full(class="sm:p-4")
    block.performance-historical.rounded-none
      template(#title)
        fieldset.period.flex.flex-col.mb-4
          label.text-gray-500.mb-1.text-sm View by date:
          select.border.border-gray-200.rounded-full(class="focus:ring-primary" v-model="activePeriod")
            option(v-for="period in periods" :value="period") {{ t[period] || period }}
      dx-chart-wrapper(type="dx-line-chart" :data="performanceHistorical" :metadata="metadata" @onSeriesHover="ev => activeKey = ev")
        template(#tooltip="{ tooltip }")
          .tooltip-table.shadow.border-t(v-if="tooltip && tooltip.date ")
            div.text-gray-500.border-b.border-b-gray-200
              | {{ '' + new Date(tooltip.date) === 'Invalid Date' || (metadata && metadata.xAxisNumeric) ? tooltip.date : new Date(tooltip.date).format('day, mon, year', $root.lang).titleize() }}
            div
              div.py-1(v-for="v, k in tooltip.__.filter((v,k) => k !== 'date')" :key="k")
                .flex.flex-row.items-center
                  .color.mr-2(:style="{ background: findPlotColor(k) }")
                  div {{ t[k] || k }}
                div(v-html="unit(format((metadata && metadata.format) || '.2f')(+v))")
    dice-table.performance-table(:data="performanceTableData" :columns="performanceTableColumns" :metadata="performanceTableMetadata" @rowHover="ev => activeKey = ev?.metric" v-if="showTable")
</template>
<script>
import usePerformance from '@/composables/performanceOld.js'
import { getDomainFromPeriod } from '@/composables/performanceOld.js'
import useEvents from '@/composables/events.js'
import { useRoute } from '@100-m/hauru'
import { useEmbedComponent } from '@/composables/embed.js'
import { nextTick } from 'vue'

import { computed, ref, unref, watch } from 'vue'
export default {
  setup() {
    const { performance, getPerformance, getPerformanceTable } = usePerformance()
    const { listenEvent, emitEvent } = useEvents()

    const onBrush = ev => {
      const domain = ev.__.map(d => new Date(d).format('YYYY-MM-DD'))
      emitEvent('domain', domain)
    }
    const route = useRoute()
    const _metadata = {
      plots: [
        {
          valueField: 'fund',
          color: 'var(--graph-1)',
        },
        {
          valueField: 'benchmark',
          color: 'var(--graph-2)',
        },
      ],
      argumentType: 'datetime',
      argumentField: 'date',
    }
    const metadata = computed(() => {
      return {
        ..._metadata,
        ...route.query,
      }
    })
    const findPlotColor = k => {
      const plot = _metadata.plots.find(d => d.valueField === k)
      return plot?.color
    }
    const config = useEmbedComponent('performance.historical')
    const { periods, activePeriod, portfCode } = config
    const _periods = unref(periods)
    const performanceTableData = getPerformanceTable(_periods)
    async function getData() {
      if (!portfCode.value) return
      await getPerformance(portfCode.value)
    }
    getData()
    watch(portfCode, getData)
    watch(periods, () => {
      if (!periods.value.includes(activePeriod.value)) nextTick().then(() => (activePeriod.value = periods.value.last()))
    })
    const performanceTableColumns = [
      {
        key: 'metric',
        name: null,
      },
    ].concat(
      _periods.map(period => ({
        key: period,
        name: period,
        format: '.2%',
      })),
    )
    const performanceTableMetadata = {
      borderLeftColor: row => findPlotColor(row.metric),
      isRowActive: row => activeKey.value === row.metric,
    }
    // const performance = await axios.get('/performance.json').then(r => r.data)
    if (!activePeriod.value) update_query({ activePeriod: _periods.last() })
    const performanceHistorical = computed(() => {
      const fullData = performance.value
      if (!fullData) return
      const dates = fullData.__.map('date').unique()
      const fullDomain = [dates.first(), dates.last()]
      const domain = getDomainFromPeriod(dates, activePeriod.value, fullDomain)
      window.ddd = domain
      const data = fullData.filter(d => d.date >= domain[0] && d.date <= domain[1])
      const first = data.first()
      return data.__.map(v => {
        return {
          benchmark: (+v.benchmark / +first.benchmark) * 100,
          fund: (+v.fund / +first.fund) * 100,
          date: v.date,
        }
      })
    })

    const activeKey = ref(null)
    return {
      performance,
      performanceHistorical,
      onBrush,
      metadata,
      findPlotColor,
      performanceTableData,
      performanceTableColumns,
      performanceTableMetadata,
      activeKey,
      ...config,
      route,
    }
  },
}
</script>
<style scoped>
.period {
  max-width: 140px;
}
.tooltip-table {
  border-top-color: var(--primary);
}
.tooltip-table .color {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.block {
  box-shadow: none;
}
</style>
