<template lang="pug">
//- block.performance-historical(type="plot-line" :data="performanceHistorical" style="height: 100%;" @brush="onBrush" :metadata="metadata")
wait-for(:wait="kpis")
  .bg-white.flex.flex-col.min-h-full(class="sm:p-4")
    .flex.flex-row.gap-2.items-center.mb-4
      .font-bold.uppercase.text-sm.text-primary {{ title }} 
      .text-inactive.text-sm {{ t.asof }} {{ new Date(date).format('day, mon, year', $root.lang).titleize() }}
    .flex.flex-row.flex-wrap.gap-3.justify-between.items-center
      .flex.flex-col(v-for="kpi in kpiList" :key="kpi.key")
        .text-sm.text-inactive {{ t[kpi.key] || kpi.key.titleize() }}
        .font-bold {{ t[kpi.value] || kpi.value }}
</template>
<script>
import { getPerformanceKpis, getNav, getRiskIndicators } from '@/composables/bone'
import { useEmbedComponent } from '@/composables/embed.js'
import { ref, unref } from 'vue'
const tempKpis = {
  isin: 'FR0007480389',
  shareClass: 'I/A',
}
const formats = {
  performanceFund: '+.2%',
  benchmarkFund: '+.2%',
  volatilityFund: '.2%',
  volatilityBenchmark: '.2%',
  aum: '.4s€',
  nav: '.2f',
}
const navKpiNames = ['aum', 'nav']
const riskKpiNames = ['volatilityFund', 'volatilityBenchmark']
const performanceKpiNames = ['performanceFund', 'benchmarkFund']
function _getKpiValue(name, period, navKpis, performanceKpis, riskIndicatorsKpis) {
  if (tempKpis[name]) return tempKpis[name]
  if (navKpiNames.includes(name)) return navKpis[name]
  if (performanceKpiNames.includes(name)) return getPeriodKpi(name, period, performanceKpis)
  if (riskKpiNames.includes(name)) return getPeriodKpi(name, period, riskIndicatorsKpis)
}
function getKpi(kpi, navKpis, performanceKpis, riskIndicatorsKpis) {
  const [name, period] = kpi.split(':')
  const value = _getKpiValue(name, period, navKpis, performanceKpis, riskIndicatorsKpis)
  const formattedValue = formats[name] ? format(formats[name])(value) : value
  console.log(name, value, formats[name], formattedValue)
  return {
    key: kpi,
    value: formattedValue,
  }
}
function getPeriodKpi(name, period, data) {
  if (!period) return 'Needs period'
  return data[period] && data[period][name]
}

export default {
  setup() {
    const config = useEmbedComponent('kpi.kpiList')
    const _kpis = unref(config.kpis)
    const kpiList = ref()
    async function getData() {
      const performanceKpis = await getPerformanceKpis('IFM_111') //.then(d => (data.value = d))
      const navKpis = await getNav('IFM_111')
      const riskIndicatorsKpis = await getRiskIndicators('IFM_111')
      kpiList.value = _kpis.map(kpi => getKpi(kpi, navKpis, performanceKpis, riskIndicatorsKpis))
    }
    getData()
    return {
      kpiList,
      ...config,
    }
  },
}
</script>
