<template lang="pug">
wait-for(:wait="loaded")
  .bg-white.flex.flex-col.min-h-full(class="sm:p-4")
    dx-xy-chart(:data="data" :options="options")
      template(v-slot:tooltip="{tooltip}")
        table-tooltip(v-if="tooltip.shown" :title="tooltip.title" :rows="formatRows(tooltip.rows)" :titleColor="tooltip.titleColor" :titleBorderColor="tooltip.titleBorderColor")
</template>

<script>
import WaitFor from "../../../components/WaitFor.vue";
import usePerformanceMocks from "../../../composables/usePerformanceMocks";
import {ref} from "vue";

export default {
  components: {WaitFor},
  methods: {
    formatRows(rows) {
      return rows.map(r => {
        return {
          ...r,
          label: this.t[r.label] || r.label,
          value: format('.2f')(r.value),
        }
      })
    }
  },
  setup() {
    const loaded = ref(true)
    const data = ref({})
    data.value = {
      data1: Array(50).fill(0).map(_ => ({x: Math.random() * 100, y: Math.random() * 100})),
      data2: Array(30).fill(0).map(_ => ({x: Math.random() * 100, y: Math.random() * 100})),
    }
    const options = {
      datasets: [{
        label: 'Dataset 1',
        key: 'data1',
      }, {
        label: 'Dataset 2',
        key: 'data2',
      }],
    }

    return {
      data,
      options,
      loaded,
    }
  },
}
</script>
