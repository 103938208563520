<template lang="pug">
widget-wrapper(:wait="allocation" :portfCode="portfCode" :portfShareCode="portfShareCode")
  .flex.flex-col.bg-white.p-4.gap-4
    .flex.flex-row.items-center.justify-between.mb-4
      .flex.flex-row.items-center.justify-start
        h2 {{ $root.t['Fund allocation for'] }} :
        select.border.border-gray-200.rounded-md.pl-4.ml-4.py-1.text-lg(style="height: min-content" class="focus:ring-primary" v-model="activeDimension")
          option(v-for="dim in dimensions" :value="dim") {{ translateDimension(dim, dimensions) }}
      .flex.flex-row.items-center.gap-8
        date-picker.left(v-model="activeDate" :dates="calendarDates" @update:modelValue="ev => persistDate(portfCode, ev)")
          template(#default="{ date, showModal }")
            .flex.flex-row.items-center.gap-2.cursor-pointer.py-1.px-2.border.border-gray-200.rounded-md(style="height: 38px;" @click="showModal")
              span.font-bold {{ date.format('day, mon, year', $root.lang) }}
              svg-icon.text-gray-400.fill-current(name="ic_date_range")
        download-button(:items="downloadItems")
    .flex.flex-col.h-full.items-center.justify-center.flex-1(v-if="['init', 'loading_breakdown'].includes(state)")
      loader
    .flex.flex-row(v-else)
      dice-pie-chart.flex-1(:data="pieData" @hover="ev => hover = ev.i" @chart="ev => chart = ev" :metadata="{ valueKey: 'ptfWeight' }")
      .flex.flex-col.items-start.p-4.justify-start(style="flex: 1.61;")
        dice-table.small.table(:metadata="legendMetadata" :columns="legendColumns" :data="sortedAllocation" style="max-width: 560px;")
          template(#body="{ tableData, metadata, columns, getCell, expands, expanded }")
            //- div {{ tableData }}
            template(v-for="row, idx in tableData" :key="idx")
              .parent-row.flex.flex-col.border-l-4.cursor-pointer(
                :style="{ 'border-left-color' : metadata?.borderLeftColor(row, idx) }" 
                :class="{ active: metadata?.isRowActive?.(row, idx), expanded: expanded === idx }" 
                @mouseover="onRowHover(row, row.idx)"
                @mouseleave="onRowHover(null, row.idx)"
                @click="focusedRow = row;"
              )    
                tr.main-content
                  template(v-for="col, colIdx in columns" :key="colIdx")
                    td.px-4.py-2.whitespace-nowrap.default(v-if="col.key === 'key'")
                      .flex.flex-row.gap-2.items-center
                        .text-gray-400.text-right(style="min-width: 20px;") {{ idx+1 }}.
                        .color.w-4.h-4.rounded-sm.bg-black(:style="{ background: row.color }")
                        span.flex-1(v-html="getCell(col, row)")
                    //- td.px-6.py-2(colspan="2" v-else-if="isHovered(row) && colIdx === 1")
                    //-   .text-right.text-gray-600.underline {{$root.t['Click to see history']}}
                    td.text-center.px-6.py-2.whitespace-nowrap.default(v-else)
                      span.text-center(v-html="getCell(col, row)")
                //- tr.hovered.grid-cols-1
                //-   td(colspan="3")
                //-     .flex.flex-col.gap-1(class="w-3/4")
                //-       .flex.flex-row.h-4.items-center.gap-1
                //-         div(style="min-width: 20px;")
                //-         div(style="min-width: 64px;") {{ $root.t.ptfWeight }}
                //-         .bar.h-full(:style="{ background: row.color, width: getBarWidth(row) }")
                //-         div {{ format('.2%')(row.ptfWeight) }}
                //-       .flex.flex-row.h-4.items-center.gap-1
                //-         div(style="min-width: 20px;")
                //-         div(style="min-width: 64px;") Index
                //-         .bar.h-full.bg-gray-300(:style="{ width: getBarWidth(row, true) }")
                //-         div {{ format('.2%')(row.bmkWeight) }}   
        .totals.text-sm.font-bold.py-2(v-if="totals")
          .px-2.text-right {{ $root.t.total }}:
          .text-center {{ format('.2%')(totals.ptfWeight) }}
          .text-center(v-if="hasBenchmark") {{ format('.2%')(totals.bmkWeight) }} 
        .chart-disclaimer.w-full(style="max-width: 560px;") {{ $root.t['Weight in % of AUM']}}
    .chart-disclaimer.flex.flex-col
      div {{ $root.t.breakdown_disclaimer }}
      div {{ $root.t.transparency_disclaimer }}
      div {{ $root.t.breakdown_disclaimer_2 }}
      div(v-if="masterPortfCode") {{ $root.t.master_fund_disclaimer }} ({{ masterName }})
      div(v-if="isRating") {{ $root.t.rating_disclaimer }}
      div(v-if="benchmarkComposition") {{ $root.t.comparison_index }}: {{ benchmarkComposition}}
    //- transition(name="modal-slide" appear)
    //-   widget-modal(v-model="showHistory")
    //-     .chart.p-4.flex.flex-col.h-full(v-if="focusedRow")
    //-       h1.mb-4 {{ $root.t['Allocation history for'] }} {{ focusedRow.key }}
    //-       cjs-wrapper.no-tooltip.bg-white.h-full.flex-1(v-if="historyMetadata" :data="historyData" :metadata="historyMetadata")

</template>
<script>
import { useEmbedComponent, useStoredValues } from '@/composables/embed.js'
import { getFundMetadata, getAllocationDates, getAllocationBreakdown } from '@/composables/api'
// import { getAllocationBreakdown } from '@/composables/bone'
import { getCssVar, findNearestDate } from '@/composables/utils'

import { computed, ref, watch, reactive } from 'vue'

function getBarWidth(row, benchmark = false) {
  const max = Math.max(row.ptfWeight || 0, row.bmkWeight || 0)
  // const min = Math.max(row.ptfWeight || 0, row.bmkWeight || 0)
  if (max === 0) return 0
  const key = benchmark ? 'bmkWeight' : 'ptfWeight'
  return format('.2%')(row[key])
  return format('.2%')(row[key] / max)
}
// State
// Config from yml
// data async
// ref and computed displayed in template
export default {
  setup() {
    const state = ref('init') // init - ready - loading_breakdown - loading_history
    const hover = ref()
    const chart = ref()
    const config = useEmbedComponent('widgets.breakdown')
    const { activeDimension, portfCode, watcher, portfShareCode } = config
    const { getDate, persistDate } = useStoredValues()
    const dimensions = ref()
    const calendarDates = ref()
    const activeDate = ref()
    const benchmarkType = ref()
    const benchmarkName = ref()
    const masterPortfCode = ref()
    const masterName = ref()
    const isRating = computed(() => activeDimension.value.includes('_RTG_'))

    const benchmarkComposition = computed(() => {
      if (benchmarkType.value !== 'comparison') return
      return benchmarkName.value
    })

    const allocation = ref()
    function sortByRating(a, b) {
      const aRating = a.key
      const bRating = b.key
      const aFirst = aRating[0]
      const bFirst = bRating[0]
      if (aFirst > bFirst) return 1
      if (bFirst > aFirst) return -1
      const aClean = aRating.replace(/[+-]/, '')
      const bClean = bRating.replace(/[+-]/, '')
      const aLength = aClean.length
      const bLength = bClean.length
      if (aLength > bLength) return -1
      if (bLength > aLength) return 1
      const aLast = aRating[aRating.length - 1]
      const bLast = bRating[bRating.length - 1]
      const order = ['+', aFirst, '-']
      return order.indexOf(aLast) - order.indexOf(bLast)
    }
    function sortByCapitalization(a, b) {
      const aCapi = a.key
      const bCapi = b.key
      if (aCapi.includes('<')) return -1
      if (aCapi.includes('>')) return 1
      const getValue = d =>
        +d
          .replace(/[^0-9\.\s]+/g, '')
          .trim()
          .split(' ')[0]
      const aValue = getValue(aCapi)
      const bValue = getValue(bCapi)
      return aValue - bValue
    }
    function sortByMaturity(a, b) {
      const aMaturity = a.key
      const bMaturity = b.key
      if (aMaturity.includes('<') || bMaturity.includes('>')) return -1
      if (aMaturity.includes('>') || bMaturity.includes('<')) return 1
      const aValue = +aMaturity.split('-')[0]
      const bValue = +bMaturity.split('-')[0]
      return aValue > bValue ? 1 : -1
    }
    const sortedAllocation = computed(() => {
      const specialFields = ['OPC', 'other', 'Trésorerie', 'Liquidités', 'NR', 'Cash & cash equivalent']
      const isSpecial = key => {
        return specialFields.includes(key) || key.toLowerCase().split(' ').includes('cash')
      }
      return allocation?.value
        ?.map((d, i) => {
          d.ptfWeight = d.ptfWeight || 0
          return d
        })
        .sort((a, b) => {
          const aSpecial = isSpecial(a.key)
          const bSpecial = isSpecial(b.key)
          if (aSpecial && bSpecial) {
            return specialFields.indexOf(a.key) - specialFields.indexOf(b.key)
          }
          if (aSpecial) return 1
          if (bSpecial) return -1
          if (activeDimension.value.includes('_RTG_')) return sortByRating(a, b)
          if (activeDimension.value.includes('_CAPI_')) return sortByCapitalization(a, b)
          if (activeDimension.value.includes('_MATURITE_')) return sortByMaturity(a, b)
          return b.ptfWeight - a.ptfWeight
          // (['Trésorerie', 'Liquidités'].includes(d.key) ? 0 : -d.ptfWeight || 1)
        })
        .map((d, i) => {
          d.color = `var(--cat${i + 1})`
          d.idx = i
          return d
        })
    })
    const totals = computed(() => {
      if (!allocation.value) return
      const ptfWeight = allocation.value.sum('ptfWeight')
      const bmkWeight = allocation.value.sum('bmkWeight')
      return { ptfWeight, bmkWeight }
    })
    const pieData = computed(() => {
      return sortedAllocation?.value
      // ?.filter(d => d?.ptfWeight)
      // .map(d => {
      //   return {
      //     key: d.key,
      //     value: d.ptfWeight,
      //   }
      // })
    })
    const legendColumns = computed(() => {
      let columns = [
        { key: 'key', name: $root.t[activeDimension.value] || activeDimension.value, size: 3 },
        { key: 'ptfWeight', name: 'ptfWeight', format: d => format('.2%')(d || 0) },
      ]
      if (hasBenchmark.value)
        columns = columns.concat({
          key: 'bmkWeight',
          name: benchmarkType.value === 'comparison' ? 'comparison_index' : 'bmkWeight',
          format: d => format('.2%')(d || 0),
        })
      return columns
    })
    const legendMetadata = {
      borderLeftColor: (row, idx) => (idx === hover.value ? row.color : 'transparent'),
      isRowActive: (row, idx) => idx === hover.value,
      max: 9,
    }
    function onRowHover(row, idx) {
      if (!chart?.value) return
      if (row) {
        if (hover.value === idx) return
        hover.value = idx
        chart.value?.handleMouseOver(row, idx)
        return
      }
      chart.value?.handleMouseOut(row, idx)
      hover.value = null
    }
    let lastDimension, lastDate, lastDateAvailable
    async function init() {
      // activeDate.value = new Date('2021-12-31')
      const fundMetadata = await getFundMetadata(portfCode.value, portfShareCode.value)
      // TEMP
      dimensions.value = fundMetadata.allocationDimensions
      benchmarkType.value = fundMetadata.benchmarkType
      benchmarkName.value = fundMetadata.benchmarkName || fundMetadata.bench_name
      masterPortfCode.value = fundMetadata.masterPortfCode
      masterName.value = fundMetadata.masterName
      calendarDates.value = await getAllocationDates(masterPortfCode.value || portfCode.value)
      lastDateAvailable = new Date(calendarDates.value[calendarDates.value.length - 1])
      const startingDate = getDate(portfCode.value)
      activeDate.value = startingDate ? findNearestDate(startingDate, calendarDates.value) : lastDateAvailable
      await getData()
    }
    async function getData() {
      if (!portfCode.value) return
      state.value = 'loading_breakdown'
      if (lastDimension === activeDimension.value && lastDate === activeDate.value) return
      activeDimension.value = activeDimension.value || dimensions.value[0]
      lastDimension = activeDimension.value
      lastDate = activeDate.value
      // allocation.value = null
      let allocationData
      const _portfCode = masterPortfCode.value || portfCode.value
      try {
        allocationData = await getAllocationBreakdown(_portfCode, activeDimension.value, activeDate.value, $root.lang)
      } catch (error) {
        allocation.value = { error }
        // drilldownTable.value = null
        return
      }
      allocation.value = Object.entries(allocationData).reduce((acc, entry) => {
        const [key, value] = entry
        acc.push({ key, ...value })
        return acc
      }, [])
      state.value = 'ready'
      // drilldownTableData.value = drilldownData
    }
    const hasBenchmark = computed(() => {
      return allocation?.value?.some(d => d.bmkWeight)
    })

    const showHistory = ref(false)
    const focusedRow = ref()
    watch(focusedRow, () => {
      if (focusedRow.value) showHistory.value = true
    })
    watch(showHistory, () => {
      if (!showHistory.value) focusedRow.value = null
    })

    let previous = 0
    function randomWalk(a, b) {
      if (previous < a || previous > b) previous = 0
      // 1/2 chance tos tay the same
      if (previous && Math.random() < 0.5) return previous
      const rand = Math.random()
      const range = b - a
      const value = rand * range + a
      previous = value
      return previous
    }
    const historyData = [1, 2, 3, 4, 5, 6, 7, 8].map(d => {
      const date = 2013 + d + '-01-01'
      const ptfWeight = randomWalk(0.13, 0.16)
      const bmkWeight = randomWalk(0.12, 0.17)
      return { date, ptfWeight, bmkWeight }
    })
    const historyMetadata = computed(() => {
      if (!focusedRow.value) return
      const plots = [
        {
          type: 'line',
          yAxisKey: 'ptfWeight',
          color: getCssVar(focusedRow?.value?.color),
          options: {
            tension: 0,
            radius: 4,
            borderWidth: 4,
          },
        },
        {
          type: 'line',
          yAxisKey: 'bmkWeight',
          color: 'rgba(209, 213, 219)',
          options: {
            tension: 0,
            radius: 4,
          },
        },
      ]

      return {
        xAxisKey: 'date',
        xAxisType: 'time',
        yAxisFormat: format('.2%'),
        plots,
        grid: {
          x: {
            color: 'rgba(0,0,0,0.1)',
            style: 'dash',
          },
          y: {
            color: 'rgba(0,0,0,0.1)',
            style: 'dash',
          },
        },
        zoom: {
          onZoom: d => console.log('zoom', d),
        },
        guideline: true,
        options: {
          animation: {
            duration: 0,
          },
        },
      }
    })

    init()
    watcher(getData)
    watch(activeDate, getData)
    function isHovered(row) {
      return row.idx === hover.value
    }
    const downloadItems = computed(() => {
      const data = sortedAllocation.value.map(({ key, ptfWeight, bmkWeight }) => {
        return {
          [$root.t[activeDimension.value]]: key,
          ptfWeight,
          bmkWeight,
        }
      })
      return [{ name: $root.t.allocation, data }]
    })

    function translateDimension(dim, dimensions) {
      if (dimensions.includes('DIG_ACT_SECT_MSCI_2018_MV') && dimensions.includes('DIG_OBL_SECT_BARCLAYS_MB_MV')) {
        const dimension = dim.replace('DIG_ACT_SECT_MSCI_2018_MV', 'sector_equity').replace('DIG_OBL_SECT_BARCLAYS_MB_MV', 'sector_bonds')
        return $root.t[dimension] || dimension
      }
      return $root.t[dim] || dim
    }
    return {
      state,
      chart,
      onRowHover,
      isHovered,
      hover,
      allocation,
      sortedAllocation,
      pieData,
      persistDate,
      translateDimension,
      ...config,
      activeDimension,
      legendColumns,
      legendMetadata,
      getBarWidth,
      activeDate,
      showHistory,
      focusedRow,
      historyData,
      historyMetadata,
      downloadItems,
      dimensions,
      calendarDates,
      totals,
      benchmarkType,
      benchmarkComposition,
      hasBenchmark,
      isRating,
      masterName,
    }
  },
}
</script>
<style scoped>
.table tr,
.totals {
  display: grid;
  grid-template-columns: minmax(120px, 3fr) repeat(auto-fit, minmax(100px, 1fr));
  width: 100%;
  max-width: 560px;
}

.table tr.active {
  box-shadow: 0 -2px 4px -1px rgba(0, 0, 0, 0.1), 0 -1px 2px -1px rgba(0, 0, 0, 0.06);
}
.table td span {
  max-width: 220px;
}
.table .parent-row .hovered td {
  /* opacity: 0; */
  /* transition: padding-top 0.15s ease-in, padding-bottom 0.15s ease-in, max-height 0.15s ease-in; */
  transition: opacity 0s;
  max-height: 0;
  overflow: hidden;
  @apply p-0;
}
.table .parent-row:hover,
.table .parent-row.active {
  @apply shadow-md;
}
/* .table .parent-row:hover tr.main-content,
.table .parent-row.active tr.main-content {
  grid-template-columns: 2fr 2fr;
} */
.table .parent-row:hover .hovered td,
.table .parent-row.active .hovered td {
  /* opacity: 1; */
  /* transition: opacity 0.2s ease-in, max-height 0s; */
  max-height: 400px;
  @apply p-2;
}
.table .parent-row .hovered td .bar {
  max-width: 0;
  transition: max-width 0.3s ease-out;
}
.table .parent-row:hover .hovered td .bar,
.table .parent-row.active .hovered td .bar {
  max-width: 100%;
}
.wait-transition-enter-active,
.wait-transition-leave-active {
  overflow: hidden;
  transition: none 2s;
  /* transition-delay: 2s; */
}
.wait-transition-enter,
.wait-transition-leave-to {
  opacity: 0;
}
.slide-bar-enter-active,
.slide-bar-leave-active,
.slide-bar-enter-to {
  overflow: hidden;
  transition: opacity 2s;
  opacity: 1;
  /* max-width: 400px; */
}
.slide-bar-enter,
.slide-bar-leave-to {
  opacity: 0;
}
.slide-bar-enter-active .bar,
.slide-bar-leave-active .bar {
  overflow: hidden;
  transition: opacity 2s;
  /* max-width: 400px; */
}
.slide-bar-enter .bar,
.slide-bar-leave-to .bar {
  opacity: 0;
}

.modal-slide-leave-active,
.modal-slide-enter-active {
  transition: all 0.2s ease-in-out;
}

.modal-slide-leave-to,
.modal-slide-enter-from {
  @apply border-l border-gray-500;
  transform: translateX(100%);
  overflow: hidden;
}
</style>
