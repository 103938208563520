import { useRoute } from '@100-m/hauru'
// import useEvents from './events'
import { ref, watch, reactive } from 'vue'

let components
function parseList(value) {
  if (Array.isArray(value)) return value
  return value && value.split(',')
}
function parseDate(value) {
  if (value === 'latest') return new Date().minus('1 month').end('month')
  return new Date(value)
}
function parseParam(value, type) {
  if (type === 'boolean') return value && JSON.parse(value)
  if (type === 'list') return parseList(value)
  if (type === 'integer') return value && parseInt(value)
  if (type === 'date') return value && parseDate(value)
  return value
}
export function useEmbedComponent(path) {
  const route = useRoute()
  if (route.query.lang) $root.lang = route.query.lang
  // const { listenEvent } = useEvents()
  const component = path.split('.').__.reduce((acc, v) => {
    return acc[v]
  }, components)
  const params = component?.params.__.map((v, k) => {
    // listenEvent(k, data => uquery({ [k]: parseParam(data, v.type) }))
    return ref(parseParam(route.query[k] || v.default, v.type))
    //   get: () => parseParam(route.query[k] || v.default, v.type),
    //   set: val => {
    //     if (route.query[k] !== val) uquery({ [k]: val })
    //     return val
    //   },
    // })
  })
  const watcher = fn => {
    return watch(reactive(params), fn)
  }
  return {
    ...params,
    watcher,
  }
}

export function setComponents(value) {
  components = value
}

export function useStoredValues() {
  function getDate(portfCode) {
    const storedDate = sessionStorage.getItem(`${portfCode}:date`)
    return storedDate && new Date(storedDate)
  }
  function persistDate(portfCode, date) {
    sessionStorage.setItem(`${portfCode}:date`, new Date(date).format('YYYY-MM-DD'))
    console.log('Setting starting date in storage', `${portfCode}:date`, new Date(date).format('YYYY-MM-DD'))
  }
  // startingDate.value = storedDate && new Date(storedDate)
  // watch(startingDate, () => {
  //   if (!startingDate.value) return
  //   sessionStorage.setItem('startingDate', startingDate.value.format('YYYY-MM-DD'))
  //   console.log('Setting starting date in storage', startingDate.value)
  // })
  return { getDate, persistDate }
}
