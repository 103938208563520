<template lang="pug">
widget-wrapper(:wait="riskTableData" :portfCode="portfCode" :portfShareCode="portfShareCode")
  .flex.flex-col.bg-white.p-4.gap-5.min-h-full
    .flex.flex-col.gap-3.mb-2
      .flex.flex-row.items-center.justify-between
        .flex.flex-row.items-center.gap-4
          h2 {{ titleize($root.t.performance_periods) }}
          date-picker.left(v-model="activeDate" :dates="calendarDates" @update:modelValue="ev => persistDate(portfCode, ev)")
            template(#default="{ date, showModal }")
              .flex.flex-row.items-center.gap-2.cursor-pointer.py-1.px-2.border.border-gray-200.rounded-md(style="height: 38px;" @click="showModal")
                span.font-bold {{ date.format('day, mon, year', $root.lang) }}
                svg-icon.text-gray-400.fill-current(name="ic_date_range")
        .flex.flex-row.items-center.gap-4
          radio-toggle(:options="performanceModes" v-model="performanceMode")
          download-button.ml-auto(:items="downloadItems")
    .flex.flex-col.h-full.items-center.justify-center.flex-1(v-if="['init', 'loading'].includes(state)")
      loader
    template(v-else)
      dice-table.risk-table.performance-table.small(:data="periodsTableData" :columns="periodsTableColumns" @plotclick="log")
      .flex.flex-col
        .chart-disclaimer {{ $root.t.performance_disclaimer }}
        .chart-disclaimer(v-if="benchmarkComposition") {{ $root.t.comparison_index }}: {{ benchmarkComposition}}
      .flex.flex-col.h-full.items-center.justify-center.flex-1(v-if="['loading_indicators'].includes(state)")
        loader
      .flex.flex-col.gap-3(v-else)
        h2.mb-2 {{ $root.t.risk_measures }}*
        dice-table.risk-table.small(:columns="riskTableColumns" :data="riskTableData")
          template(#cell-name="{ col, row, idx, cell }")
            span {{ cell }}<sup>{{ disclaimerStars[row.name] }}</sup>
        .chart-disclaimer.flex.flex-col
          div *  {{ $root.t.see_glossary }}
          div ** {{ $root.t.no_risk_info }}

      .flex.flex-col.relative.gap-2(style="height: 240px;")
        .flex.flew-row.items-center.justify-between
          h3 {{ barTitle }} (%)
          select.border.border-gray-200.rounded.text-gray-800.text-sm(class="focus:ring-primary" :class="{ active: !!activePeriod }" v-model="activePeriod")
            option(:value="null") {{ $root.t.yearly }}
            option(v-for="period in yearlyPeriods" :value="period") {{ t[period] || period }}
        cjs-wrapper.bg-white.h-full.no-tooltip(:data="historicalPerformance" :metadata="barMetadata" @plotclick="onHistoricalClick" style="min-height: 200px;")
      .chart-disclaimer(v-if="benchmarkComposition") {{ $root.t.comparison_index }}: {{ benchmarkComposition}}
      .chart-disclaimer(v-html="fundMetadata?.disclaimer?.global[$root.lang]")
      .chart-disclaimer(v-html="fundMetadata?.disclaimer?.share[$root.lang]")
</template>
<script>
import { ref, computed, watch } from 'vue'
import { useRoute } from '@100-m/hauru'
import { getFundMetadata, getRiskIndicators, getPerformance, getPerformanceKpis } from '@/composables/api'
import { analytics } from '@/composables/analytics'
import { cellFormatter, titleize, getCssVar, filterPeriods, findNearestDate } from '@/composables/utils'
import { useEmbedComponent, useStoredValues } from '@/composables/embed.js'

const formats = {
  volatilityFund: '.2%',
  volatilityBenchmark: '.2%',
  alpha: '.2%',
  r2: '.2f',
  trackingError: '.2%',
}

function computeGroupPerformance(grp, key) {
  const sorted = grp.sort('date')
  if (sorted.length < 2) return
  const first = sorted[0]
  const last = sorted[sorted.length - 1]
  return ['fund', 'benchmark', 'risk_free'].reduce(
    (acc, v) => {
      acc[v] = last[v] / first[v] - 1
      return acc
    },
    { date: key, realDate: grp[0].date },
  )
}

export default {
  setup() {
    const state = ref('init') // init - loading - ready
    const route = useRoute()
    const config = useEmbedComponent('widgets.riskIndicators')
    const { kpis, watcher, portfShareCode, portfCode } = config
    const periods = ref()
    const calendarDates = ref()
    const activeDate = ref()
    const riskIndicators = ref()
    const performance = ref()
    const perfType = ref()
    const performanceKpisData = ref()
    const fundMetadata = ref()
    const riskTableColumns = computed(() => {
      if (!periods.value) return
      return [{ key: 'name', size: 1.5 }].concat(
        periods.value.map(d => ({
          key: d,
          name: titleize($root.t[d]),
          size: d === 'inception' ? 1.5 : 1,
        })),
      )
    })
    const disclaimerStars = {
      volatilityFund: '',
      volatilityBenchmark: '',
      sharpeFund: '**',
      sharpeBenchmark: '',
      informationRatio: '',
      alpha: '',
      beta: '',
      trackingError: '',
      maxDrawdown: '',
      maxDrawdownDate: '',
      maxDrawdownDuration: '',
      r2: '',
    }

    const riskTableData = ref()
    // const riskTableMetadata = {
    //   borderLeftColor: (row, idx) => `var(--cat${idx + 1})`,
    //   // isRowActive: (row, idx) => this.hover === idx,
    // }
    const benchmarkType = ref()
    const benchmarkName = ref()
    const { getDate, persistDate } = useStoredValues()

    async function init() {
      performance.value = await getPerformance(portfCode.value, portfShareCode.value)
      if (!performance.value || !performance.value.length) {
        riskTableData.value = []
        return
      }
      calendarDates.value = performance.value.map(d => d.date)
      const lastDate = new Date(performance.value.last().date)
      const startingDate = getDate(portfCode.value)
      activeDate.value = startingDate ? findNearestDate(startingDate, calendarDates.value) : lastDate
      fundMetadata.value = await getFundMetadata(portfCode.value, portfShareCode.value)
      perfType.value = fundMetadata.value.perfType
      periods.value = filterPeriods(fundMetadata.value.periods, calendarDates.value)
      benchmarkType.value = fundMetadata.value.benchmarkType
      benchmarkName.value = fundMetadata.value.benchmarkName
      await getData()
    }
    const benchmarkComposition = computed(() => {
      if (benchmarkType.value !== 'comparison') return
      return benchmarkName.value
    })
    function excludePeriod(period) {
      if (perfType.value === 'short_term') return ['1w', '1m', 'mtd'].includes(period)
      return ['1w', '1m', 'mtd', '3m', '6m', 'ytd'].includes(period)
    }
    async function getData() {
      state.value = 'loading_indicators'
      const lastDate = new Date(performance.value.last().date)
      if (!periods.value) return
      const data = await getRiskIndicators(portfCode.value, portfShareCode.value, activeDate.value, periods.value)
      if (performance.value.error || data.error) {
        riskTableData.value = { error: data.error || performance.value.error || 'service_unavailable' }
        return
      }
      riskTableData.value = kpis.value.map(name => {
        const row = { name }
        periods.value.map(period => {
          if (excludePeriod(period)) {
            row[period] = '-'
            return
          }
          row[period] = data[period] ? format(formats[name] || '.2f')(data[period][name]) : '-'
        })
        return row
      })
      performanceKpisData.value = await getPerformanceKpis(portfCode.value, portfShareCode.value, activeDate.value, periods.value)
      state.value = 'ready'
    }

    const getPerf = (metric, data, annualized = false) => {
      const row = { metric }
      const _metric = annualized ? metric + '_annualized' : metric
      periods.value.map(period => {
        if (perfType.value !== 'short_term' && ['1m', '3m', '6m', 'ytd'].includes(period) && annualized) return '-'
        row[period] = data[period] && data[period][_metric]
      })
      return row
    }
    const performanceKpis = computed(() => {
      if (!performanceKpisData.value) return
      const annualized = performanceMode.value === 'annualized'
      return ['fund', 'benchmark', 'delta'].map(metric => {
        return getPerf(metric, performanceKpisData.value, annualized)
      })
    })

    const metrics = ['fund', 'benchmark', 'diff']
    const periodsTableColumns = computed(() => {
      if (!periods.value) return
      return [
        {
          key: 'metric',
          name: null,
          size: 1.5,
          format: metric => {
            return benchmarkType.value === 'comparison' && (metric.toLowerCase().includes('index') || metric.toLowerCase().includes('indicateur'))
              ? $root.t['comparison_index']
              : metric
          },
        },
      ].concat(
        periods.value.map(period => ({
          key: period,
          name: titleize($root.t[period]),
          format: cellFormatter(format('.2%')),
          size: period === 'inception' ? 1.5 : 1,
        })),
      )
    })
    const activePerformance = computed(() => {
      if (!activeDate.value) return performance.value
      const date = activeDate.value.format('YYYY-MM-DD')
      return performance.value.filter(d => d.date <= date)
    })
    const periodsTableData = computed(() => {
      // TEMP if route.query.source === 'cdr' use the usual
      const source = (route.query?.source || '').toLowerCase()
      if (!performance.value) return
      if (source === 'cdr') {
        const indicator = performanceMode.value === 'annualized' ? 'performance_annualized' : 'performance'
        return metrics.map(metric => {
          return periods.value.reduce(
            (acc, period) => {
              acc[period] = analytics(activePerformance.value, indicator, metric, period)
              return acc
            },
            { metric },
          )
        })
      }
      if (!performanceKpis.value) return
      return performanceKpis.value
    })
    const performanceModes = ['cumulative', 'annualized']
    const performanceMode = ref('cumulative')

    const activePeriod = ref(null)
    const barTitle = computed(() => titleize(!activePeriod.value ? $root.t.yearly_performance : $root.t.monthly_performance))
    const yearlyPeriods = computed(() => {
      if (!performance.value) return
      return activePerformance.value.map(d => new Date(d.date).format('YYYY')).unique()
    })
    const historicalPerformance = computed(() => {
      if (!performance.value) return
      if (!activePeriod.value) {
        const endOfYears = activePerformance.value
          .group(d => new Date(d.date).format('YYYY'))
          .__.map((grp, key) => grp[grp.length - 1])
          .__.v()
        const firstDate = activePerformance.value[0]
        return endOfYears.map((d, i) => {
          const first = i === 0 ? firstDate : endOfYears[i - 1]
          return ['fund', 'benchmark', 'risk_free'].reduce(
            (acc, v) => {
              acc[v] = d[v] / first[v] - 1
              return acc
            },
            { date: new Date(d.date).format('YYYY'), realDate: new Date(d.date).format('YYYY') },
          )
        })
      }
      const yearlyData = activePerformance.value.filter(d => new Date(d.date).format('YYYY') === activePeriod.value)
      const endOfMonths = yearlyData
        .group(d => new Date(d.date).format('mon', $root.lang))
        .__.map((grp, key) => grp[grp.length - 1])
        .__.v()
      const firstDate = activePerformance.value.reverse().find(d => d.date < yearlyData[0].date)
      return endOfMonths.map((d, i) => {
        const first = i === 0 ? firstDate : endOfMonths[i - 1]
        return ['fund', 'benchmark', 'risk_free'].reduce(
          (acc, v) => {
            acc[v] = d[v] / first[v] - 1
            return acc
          },
          { date: new Date(d.date).format('mon', $root.lang), realDate: new Date(d.date).format('mon', $root.lang) },
        )
      })
    })
    const barMetadata = computed(() => {
      return {
        xAxisKey: 'date',
        yAxisFormat: d => format('.1f')(d * 100),
        datalabels: true,
        plots: [
          {
            type: 'bar',
            yAxisKey: 'fund',
            color: getCssVar('--fund'),
            options: {
              borderRadius: 4,
              borderSkipped: 'bottom',
            },
          },
          {
            type: 'bar',
            yAxisKey: 'benchmark',
            name: benchmarkType.value === 'comparison' ? $root.t['comparison_index'] : $root.t['reference_index'],
            color: getCssVar('--benchmark'),
            options: {
              borderRadius: 4,
              borderSkipped: 'bottom',
            },
          },
        ],
        grid: {
          y: {
            color: 'rgba(0,0,0,0.1)',
            style: 'dash',
          },
        },
        options: {
          layout: {
            padding: {
              top: 25,
            },
          },
        },
      }
    })
    const onHistoricalClick = datum => {
      const year = new Date(datum.realDate).format('YYYY')
      if (!activePeriod.value) return (activePeriod.value = year)
    }
    const downloadItems = computed(() => {
      return [
        { name: titleize($root.t.performance_periods), data: periodsTableData.value },
        { name: titleize($root.t.risk_measures), data: riskTableData.value },
        {
          name: titleize(barTitle.value),
          data: historicalPerformance.value.map(d => {
            const columns = Object.keys(d).filter(key => key !== 'risk_free')
            return columns.reduce((acc, col) => {
              acc[col] = d[col]
              return acc
            }, {})
          }),
        },
      ]
    })

    init()
    watch(activeDate, getData)
    watcher(getData)
    return {
      state,
      activeDate,
      calendarDates,
      riskIndicators,
      riskTableColumns,
      riskTableData,
      periodsTableColumns,
      periodsTableData,
      performanceMode,
      performanceModes,
      ...config,
      titleize,
      barTitle,
      yearlyPeriods,
      historicalPerformance,
      barMetadata,
      persistDate,
      onHistoricalClick,
      activePeriod,
      downloadItems,
      disclaimerStars,
      benchmarkComposition,
      fundMetadata,
    }
  },
}
</script>
<style>
.risk-table {
  table-layout: fixed;
}
.risk-table thead th:first-of-type,
.risk-table tbody tr td:first-of-type {
  width: 240px;
  padding-left: 4px;
  padding-right: 6px;
}
</style>
