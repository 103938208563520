<template lang="pug">
section.p-6.bg-gray-100.w-full.h-full
  .flex.flex-row
    .flex.flex-col.p-4
      h2.mb-2 Fond actif
      fieldset.period.flex.flex-col.mb-4
        label.text-gray-500.mb-1.text-sm Choisissez un fond
        select.border.border-gray-200.rounded-full(class="focus:ring-primary" v-model="activeFundId")
          option(v-for="fund, fundId in funds" :value="fundId") {{ fund.name }}
    .flex.flex-col.flex-1.p-4(v-if="activeFund")
      h2.mb-2 Caractéristiques du fond
      div(v-for="key in ['name', 'type']") {{ t[key] || key.titleize() }}: {{ activeFund[key] }}
      h3.my-2 Valeurs par défaut
      div(v-for="v,k in activeFund.defaults") {{ k }}: {{ v }}
  .flex.flex-col.justify-center.items-center
    h2.mb-2 Performance
    iframe#test.w-full.bg-gray-400.border.max-w-6xl.mb-4(ref="performanceWidget" style="height: 700px;" :src="`${rootLocation}/embed/performance/historical`")
    h2.mb-2 Allocation breakdown portfolio
    iframe.w-full.bg-gray-400.border.max-w-6xl.mb-4(ref="allocationWidget" :src="`${rootLocation}/embed/allocation/allocationBreakdown?dimensions=asset_class,sector,country,currency,maturity,region,zone`")
    h2.mb-2 Allocation breakdown benchmark
    iframe.w-full.bg-gray-400.border.max-w-6xl.mb-4(ref="allocationWidget" :src="`${rootLocation}/embed/allocation/allocationBreakdown?dimensions=asset_class,sector,country,currency,maturity,region,zone&axis=benchmark`")

    //- h1.mb-4 Mobile version
    //- .flex.flex-row
    //-   .flex.flex-col
    //-     h2.mb-2 Performance
    //-     iframe#test.w-full.bg-gray-400.border.max-w-6xl.mb-4.mobile-widget(ref="performanceWidget" style="height: 700px;" :src="`${rootLocation}/embed/performance/historical`")
    //-   .flex.flex-col
    //-     h2.mb-2 Allocation breakdown
    //-     iframe.w-full.bg-gray-400.border.max-w-6xl.mb-4.mobile-widget(ref="allocationWidget" :src="`${rootLocation}/embed/allocation/allocationBreakdown`")
</template>
<script>
import funds from './funds.yml'
import { ref, computed, watch } from 'vue'
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
export default {
  setup() {
    const activeFundId = ref('IFM_111')
    const activeFund = computed(() => funds[activeFundId.value])
    const rootLocation = window.location.origin
    const performanceWidget = ref()
    const allocationWidget = ref()
    const components = [performanceWidget] //, allocationWidget]
    // window.allocationWidget = allocationWidget
    function postEvent(name, data) {
      console.log('posting', name, data)
      components.map(component => component.value.contentWindow.postMessage({ name, data }, '*'))
    }
    watch(activeFundId, () => {
      postEvent('portfCode', activeFundId.value)
      const fund = funds[activeFundId.value]
      fund?.defaults?.__.map((v, k) => postEvent(k, v))
      // components.map(component => component.value.contentWindow.postMessage({ name: 'portfCode', data: activeFundId.value }, '*'))
    })
    return {
      rootLocation,
      funds,
      activeFundId,
      activeFund,
      performanceWidget,
      allocationWidget,
    }
  },
}
</script>
<style>
.mobile-widget {
  min-width: 375px;
  min-height: 667px;
  max-width: 375px;
  max-height: 667px;
}
</style>
