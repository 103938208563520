<template lang="pug">
demo-component(:path="path" :key="path")
</template>
<script>
import { useRoute } from '@100-m/hauru'
import { computed } from 'vue'
export default {
  setup() {
    const route = useRoute()
    const path = computed(() => `${route.params.group}/${route.params.component}`)
    return { path }
  },
}
</script>