import axios from 'axios'
// import { useRoute } from '@100-m/hauru'
export async function apiCall(endpoint, params = {}) {
  // const route = useRoute()
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('token')
  const source = urlParams.get('source')
  const BACKEND_URL = (localStorage.BACKEND_URL || 'https://digitalreportingdice.dev.backend.natixis.com') + '/api'
  // const BACKEND_URL = endpoint.includes('bone') ? 'https://digitalreportingdice.qua.backend.natixis.com/api' : 'http://localhost:1112/api'
  const headers = {
    Authorization: 'Bearer ' + token,
  }
  if (Object.values(params).some(param => param === 'undefined') || endpoint.split('/').some(d => d === 'undefined')) return
  const _params = { source, ...params }
  return await axios
    .get(BACKEND_URL + endpoint, { params: _params, headers })
    .then(r => r.data)
    .catch(e => ({ error: 'service_unavalaible' }))
}

export async function getFundMetadata(portfCode, portfShareCode) {
  if (!portfShareCode) {
    return await apiCall('/fund/' + portfCode)
  }
  return await apiCall(`/fund/${portfCode}/${portfShareCode}`)
}

export async function getPerformance(portfCode, portfShareCode) {
  return await apiCall(`/performance/${portfCode}/${portfShareCode}`)
}

export async function getPerformanceAudit(portfCode, portfShareCode) {
  return await apiCall(`/audit/performance/${portfCode}/${portfShareCode}`)
}

export async function getFunds() {
  return await apiCall(`/getFunds`)
}

export async function getFundsAudit() {
  return await apiCall(`/audit/getFunds`)
}

export async function getPerformanceCached(portfCode, portfShareCode) {
  return await apiCall(`/performanceCached/${portfCode}/${portfShareCode}`)
}

export async function getRiskIndicators(portfCode, portfShareCode, date, periods) {
  const params = {
    date: date.format('YYYY-MM-DD'),
    periods: periods.join(','),
  }
  return await apiCall(`/riskIndicators/${portfCode}/${portfShareCode}`, params)
}

export async function getHoldings(portfCode, date, lang) {
  const params = {
    date: date.format('YYYY-MM-DD'),
  }
  return await apiCall(`/holdings/${portfCode}`, params)
}

export async function getHoldingsAudit(portfCode, date, lang) {
  const params = {
    date: date.format('YYYY-MM-DD'),
  }
  return await apiCall(`/audit/holdings/${portfCode}`, params)
}

export async function getTransactions(portfCode, date, lang) {
  const params = {
    date: date.format('YYYY-MM-DD'),
  }
  return await apiCall(`/transactions/${portfCode}`, params)
}

export async function getPerformanceKpis(portfCode, portfShareCode, date, periods) {
  const params = {
    date: date.format('YYYY-MM-DD'),
    periods: periods.join(','),
  }
  return await apiCall(`/performanceKpis/${portfCode}/${portfShareCode}`, params)
}

export async function getCalendarDates(portfCode, portfShareCode) {
  return await apiCall(`/dates/${portfCode}/${portfShareCode}`)
}

export async function getAllocationDates(portfCode) {
  return await apiCall(`/allocationDates/${portfCode}`)
}

export async function getAllocationBreakdown(portfCode, componentName, date, lang) {
  const params = {
    date: date.format('YYYY-MM-DD'),
  }
  const rawData = await apiCall(`/breakdown/${portfCode}/${componentName}`, params)
  const axes = ['ptfWeight', 'bmkWeight']
  function isOther(v, idx) {
    if (componentName.includes('_PAYS_') && idx >= 10) return true
    const otherFields = ['Autre', 'Inconnu', 'Unassigned Group', 'Unknown', 'NA']
    if (axes.every(axis => !v[axis] || v[axis] < 0.0005)) return true
    if (otherFields.includes(v.dimension) || v.dimension.includes('Autre') || v.dimension.includes('Other')) return true
    if ((!v.ptfWeight || v.ptfWeight < 0.00009) && v.bmkWeight <= 0.01) return true
    return false
  }
  const data = Object.values(rawData)
    .sort('-ptfWeight')
    .filter(d => axes.some(axis => d[axis]))
    .group((v, idx) => {
      if (isOther(v, idx)) return 'other'
      return v.dimension === 'Unassigned Group' ? 'NA' : v.dimension
    })
  return data.__.map((values, dim) => {
    return axes.reduce((acc, axis) => {
      acc[axis] = values.sum(d => d[axis] || 0)
      return acc
    }, {})
  })
}

export async function getAllocationBreakdownAudit(portfCode, componentName, date, lang) {
  const params = {
    date: date.format('YYYY-MM-DD'),
  }
  const res = await apiCall(`/audit/breakdown/${portfCode}/${componentName}`, params)
  const axes = ['ptfWeight', 'bmkWeight']
  function isOther(v) {
    const otherFields = ['Autre', 'Inconnu', 'Unassigned Group', 'Unknown', 'NA']
    if (axes.every(axis => !v[axis] || v[axis] < 0.0005)) return true
    if (otherFields.includes(v.dimension) || v.dimension.includes('Autre') || v.dimension.includes('Other')) return true
    if ((!v.ptfWeight || v.ptfWeight < 0.00009) && v.bmkWeight <= 0.01) return true
    return false
  }
  const breakdown = Object.values(res.data)
    .filter(d => axes.some(axis => d[axis] && d[axis] >= 0.0000001))
    .group(v => {
      if (isOther(v)) return 'other'
      return v.dimension === 'Unassigned Group' ? 'NA' : v.dimension
    })
  const data = breakdown.__.map((values, dim) => {
    return axes.reduce((acc, axis) => {
      acc[axis] = values.sum(d => d[axis] || 0)
      return acc
    }, {})
  })
  return { data, enrichedHoldings: res.enrichedHoldings }
}

export async function getDrilldown(portfCode, date, lang) {
  const params = {
    date: date.format('YYYY-MM-DD'),
  }
  const { data, mergedHoldings } = await apiCall(`/drilldown/${portfCode}`, params)
  return { drilldownData: data, drilldownDetails: mergedHoldings }
}

export async function getDrilldownAudit(portfCode, date, lang) {
  const params = {
    date: date.format('YYYY-MM-DD'),
  }
  const { data, mergedHoldings } = await apiCall(`/audit/drilldown/${portfCode}`, params)
  return { drilldownData: data, drilldownDetails: mergedHoldings, lastLevel: 4 }
}
