// import { useAxios } from '@100-m/hauru/src/utils/axios.js'
import {
  pertNetFeeStructure,
  navStructure,
  riskIndicatorsStructure,
  getAllocationParams,
  getDrilldownParams,
  getPerformanceParams,
  getRiskIndicatorsParams,
  getHoldingsParams,
  holdingsStructure,
  getTransactionsParams,
  transactionsStructure,
  getRiskFreeParams,
  riskFreeStructure,
} from './boneConfig'
import { apiCall } from './api'
const mappingDimensions = {
  // asset_class: 'AA_RC_S_STR_PTF_GLOB_ABS_MAN_MV',
  asset_class: 'AssetClass',
}

const breakdownLevels = {
  // 'DIG_STR_PTF_GLOB_ABS (code manuel)_MV': 3,
  // 'DIG_OBL_PAYS_SOUV_MAT_LT-code manuel_MV': 2,
  // DIG_ACT_SECT_MSCI_2018_MV: 2,
  // DIG_ALL_DEV_MV: 1,
  // DIG_OBL_ZONESGEO_MV: 3,
  // DIG_OBL_MATURITE_MV: 2,
  // RC_SV_OBL_RTG_SP: 2,
}

// function sleep(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }
// LOCAL FAKE API STUFF
function getFileFromEndpoint(endpoint, { portfCode, dimension }) {
  if (endpoint === '/getComputedAssetAllocationFromReportSettingComponentResult') {
    return `/content/data/${portfCode}/getComputedAssetAllocationFromReportSettingComponentResult${dimension}.json`
  }
  if (endpoint === 'performance') return `/content/data/${portfCode || 'IFM_111'}/getNavAndBenchmarkLevelsFromConfigTyped.json`
  return `/content/data/${portfCode}/${endpoint}.json`
}
export function getClassification(data, level = 1) {
  return data
    .filter(d => d.classification.level === level)
    .map(d => {
      d.dimension = d.classification['level' + level]
      return d
    })
}
async function fetchData(endpoint, params, local = false) {
  if (local) {
    const path = getFileFromEndpoint(endpoint, params)
    const data = await fetch(path, {
      headers: {
        'Content-Type': 'text/plain; charset=iso-8859-1',
      },
    })
      .then(function (response) {
        return response.arrayBuffer()
      })
      .then(function (buffer) {
        const decoder = new TextDecoder('iso-8859-1')
        const text = decoder.decode(buffer)
        return JSON.parse(text)
      })
    return data
  }
  console.log('Calling', endpoint, params)
  return await apiCall('/bone/proxy/' + endpoint, params)
}

export async function boneRequest(endpoint, params, structure, local = false) {
  try {
    const data = await fetchData(endpoint, params, local)
    console.log('Got', data)
    if (data.error) return { error: data.error }
    if (!structure) return data
    return parseData(data, structure)
  } catch (e) {
    return { error: 'service_unavalaible' }
  }
}

export async function getPerformance(portfShareCode, fromDate = new Date().minus('10 years'), toDate = new Date()) {
  // fromDate = fromDate.format('YYYYMMDD')
  // toDate = toDate.format('YYYYMMDD')
  const params = getPerformanceParams({ portfShareCode, fromDate, toDate })
  const performance = await boneRequest('getNavAndBenchmarkLevelsFromConfigTyped', params)
  // const riskFreeParams = getRiskFreeParams()
  // const riskFree = await boneRequest('getRunDataExtractTemplate', riskFreeParams, riskFreeStructure).then(data => {
  //   return data.reduce((acc, v) => {
  //     const date = v.date.format('YYYY-MM-DD')
  //     acc[date] = v
  //     return acc
  //   }, {})
  // })
  if (performance.error) return performance
  // TODO what is sourceHoldingId: 'NR'/'NATPERF' ?
  return performance
    .filter(d => d.sourceHoldingId === 'NR')
    .map(datum => {
      const { date, netValueLevel, referenceLevel } = datum
      const diff = netValueLevel - referenceLevel
      return {
        date,
        fund: netValueLevel,
        benchmark: referenceLevel,
        diff,
        // risk_free: riskFree[date]?.value || 100,
        risk_free: 100,
      }
    })
    .sort('date')
}

function getAxis(axis) {
  if (axis === 'benchmark') return 'bmkWeight'
  return 'ptfWeight'
}
export function getDimension(dimension) {
  return mappingDimensions[dimension] || dimension.titleize()
}
export function getBreakdownLevel(dimension, data) {
  const level = breakdownLevels[dimension]
  if (level) return level
  if (!data || !data.length) return 2
  const lastLevel = data[data.length - 1].classification.level
  return lastLevel
}
export async function getHoldings(portfCode, date, lgCode = 'fr') {
  // const params = getAllocationParams({ portfCode, dimension, date })
  // date = new Date('2021-06-30')
  const params = getHoldingsParams({ portfCode, date, lgCode })
  const data = await boneRequest('getRunDataExtractTemplate', params, holdingsStructure)
  if (data.error) return { error: data.error }
  const linesFund = data.filter(d => d.weight).length
  const filteredData = data.filter(d => d.assetClass !== 'Liquidités comptant')
  const top10Fund = filteredData
    .filter(d => d.weight)
    .sort('-weight')
    .slice(0, 10)
  return { top10Fund, linesFund }
}
export async function getTransactions(portfCode, date, lgCode = 'fr') {
  // const params = getAllocationParams({ portfCode, dimension, date })
  // date = new Date('2021-06-30')
  const params = getTransactionsParams({ portfCode, date, lgCode })
  const data = await boneRequest('getRunDataExtractTemplate', params, transactionsStructure)
  if (data.error) return { error: data.error }
  const filteredData = data.filter(d => d.amount && d.assetClass !== 'Liquidités comptant')
  const top5Purchase = filteredData
    .filter(d => d.transactionType === 'purchase')
    .sort('-amount')
    .slice(0, 5)
  const top5Sale = filteredData
    .filter(d => d.transactionType === 'sale')
    .sort('+amount')
    .slice(0, 5)
  return { top5Purchase, top5Sale }
}

export async function getAllocation(portfCode, _dimension, date) {
  const dimension = getDimension(_dimension)
  const level = getBreakdownLevel(dimension)
  console.log('level', level)
  const rawData = await boneRequest('getComputedAssetAllocationFromReportSettingComponentResult', { portfCode, dimension }, null, true)
  window.rawData = rawData
  const axes = ['ptfWeight', 'bmkWeight']
  if (rawData.error) return { error: rawData.error }
  const data = getClassification(rawData, level)
    .filter(d => axes.some(axis => d[axis] && d[axis] >= 0))
    .group(v => (v.dimension === 'Unassigned Group' ? 'NA' : v.dimension))
  const allocationData = data.__.map((values, dim) => {
    return axes.reduce((acc, axis) => {
      acc[axis] = values.sum(axis)
      return acc
    }, {})
  })
  return { allocationData }
}

export async function getDrilldown(portfCode, date, lgCode) {
  // const data = {}
  const params = await getDrilldownParams({ portfCode, reportComponentSettingCode: 'DIG_AA_ALL_STR_SECT_NO_INSTR_FOR_BENCH', date, lgCode })
  const rawData = await boneRequest('getComputedAssetAllocationFromReportSettingComponentResult', params, null)
  const instrumentParams = await getDrilldownParams({ portfCode, reportComponentSettingCode: 'DIG_AA_ALL_STR_SECT_INSTR_LEVEL_FOR_FUND', date, lgCode })
  const instrumentDataRaw = await boneRequest('getComputedAssetAllocationFromReportSettingComponentResult', instrumentParams, null)
  const instrumentLevel = Math.max(...instrumentDataRaw.map(d => d.classification.level))
  const instrumentData = instrumentDataRaw.filter(d => d.classification.level === instrumentLevel)
  const lastLevel = Math.max(...rawData.map(d => d.classification.level))

  const data = rawData.filter(d => d.classification.level === lastLevel)
  const firstLevel = rawData.filter(d => d.classification.level === 1)
  let drilldownLevels = (1).upto(lastLevel)
  // if (firstLevel.length <= 1 && drilldownLevels.length > 2) drilldownLevels = drilldownLevels.slice(1)
  function getInstrumentLevel(dimensions) {
    return instrumentData
      .filter(d => {
        return Object.keys(dimensions).every(key => d.classification[key] === dimensions[key])
      })
      .map(d => {
        const { ptfWeight, instrument } = d
        const bmkWeight = '-'
        const deltaWeight = '-'
        const instrumentLevel = true
        return { instrument, ptfWeight, bmkWeight, deltaWeight, instrumentLevel }
      })
      .sort('-ptfWeight')
  }
  function groupDrilldown(levels, parents, dimension) {
    const currentLevel = levels[0]
    const agg = rawData
      .filter(d => {
        if (d.classification.level !== currentLevel) return false
        if (!dimension) return true
        return d.classification['level' + (currentLevel - 1)] === dimension
      })
      // .group(d => d.classification[levels[0]])
      .map(d => {
        // const { calculDate, classification, instrument } = group[0]
        const ptfWeight = d.ptfWeight || 0
        const bmkWeight = d.bmkWeight || 0
        const deltaWeight = ptfWeight - bmkWeight
        const parentDimension = d.classification['level' + currentLevel]
        const parentDimensions = { ...parents, ['level' + currentLevel]: parentDimension }
        const children = levels[1] ? groupDrilldown(levels.slice(1), parentDimensions, parentDimension) : getInstrumentLevel(parentDimensions)
        const instrumentLevel = false
        return { dimension: parentDimension, ptfWeight, bmkWeight, deltaWeight, children: children.length && children, parents, instrumentLevel }
      })
    return agg
  }
  const drilldownData = groupDrilldown(drilldownLevels, {})
  return { drilldownData, lastLevel }
}

export async function getAllocationBreakdown(portfCode, reportComponentSettingCode, date, lgCode) {
  // const dimension = getDimension(reportComponentSettingCode)
  const params = getAllocationParams({ portfCode, reportComponentSettingCode, date, lgCode })
  const rawData = await boneRequest('getComputedAssetAllocationFromReportSettingComponentResult', params, null)
  const level = getBreakdownLevel(reportComponentSettingCode, rawData)
  window.rawData = rawData
  const axes = ['ptfWeight', 'bmkWeight']
  if (rawData.error) return { error: rawData.error }
  function isOther(v) {
    const otherFields = ['Autre', 'Inconnu', 'Unassigned Group']
    if (axes.every(axis => !v[axis] || v[axis] < 0.0005)) return true
    if (otherFields.includes(v.dimension) || v.dimension.includes('Autre') || v.dimension.includes('Other')) return true
    if ((!v.ptfWeight || v.ptfWeight < 0.00009) && v.bmkWeight <= 0.01) return true
    return false
  }
  const data = getClassification(rawData, level)
    .filter(d => axes.some(axis => d[axis] && d[axis] >= 0.0000001))
    .group(v => {
      if (isOther(v)) return 'other'
      return v.dimension === 'Unassigned Group' ? 'NA' : v.dimension
    })
  const allocationData = data.__.map((values, dim) => {
    return axes.reduce((acc, axis) => {
      acc[axis] = values.sum(d => d[axis] || 0)
      return acc
    }, {})
  })
  return { allocationData }
}

export async function getCrossfilterAllocation(portfCode, dimensions, date) {
  // const axis = getAxis(_axis)
  const data = {}
  const raw = {}
  try {
    await Promise.all(
      dimensions.map(async reportComponentSettingCode => {
        // const dimension = getDimension(_dimension)
        const params = getAllocationParams({ portfCode, reportComponentSettingCode, date })
        // Networked
        // const rawData = await boneRequest('getComputedAssetAllocationFromReportSettingComponentResult', params, null)
        // Local
        const rawData = await boneRequest('getComputedAssetAllocationFromReportSettingComponentResult', { reportComponentSettingCode, ...params }, null)
        const level = getBreakdownLevel(reportComponentSettingCode, rawData)
        const maxLevel = Math.max(...rawData.map(d => d.classification.level))
        // const datum = rawData.filter(d => d.classification.level === maxLevel)[0]
        // data[dimension] = datum
        raw[reportComponentSettingCode] = rawData.filter(d => d.classification.level === maxLevel)
        rawData
          .filter(d => d.classification.level === maxLevel)
          .group('instrumentCode')
          .__.map((group, instrumentCode) => {
            const { calculDate, classification, instrument } = group[0]
            const ptfWeight = group.sum('ptfWeight') || 0
            const bmkWeight = group.sum('bmkWeight') || 0
            if (!data[instrumentCode]) data[instrumentCode] = { instrumentCode, ptfWeight, bmkWeight, instrument, calculDate }
            if (data[instrumentCode].ptfWeight && data[instrumentCode].ptfWeight !== ptfWeight) {
              // console.warn('different weights', data[instrumentCode].ptfWeight, ptfWeight)
            }
            data[instrumentCode][reportComponentSettingCode] = classification['level' + level]
          })
        //   .map(d => {
        //     // if ()
        //     d.dimension = d.classification['level' + level]
        //     return d
        //   })
      }),
    )
  } catch (e) {
    return { data: { error: 'service_unavailable' } }
  }
  return { data: Object.values(data), raw }
}

const mappingPeriods = {
  '1m': '1MOIS',
  '3m': '3MOIS',
  '1y': '12MOIS',
  '3y': '3ANS',
  '5y': '5ANS',
  inception: 'OUVERTURE',
  ytd: 'DEB_ANNEE',
}

function parseType(type) {
  if (type === 'string') return d => (d ? '' + d : '-')
  if (type === 'number') return d => +d
  if (type === 'date') return d => new Date(d)
  return d => d
}
function parseData(data, structure) {
  return data.map(d => {
    return structure.__.map((definition, k) => {
      const { key, fn, type } = definition
      if (key) return parseType(type)(d[key])
      return parseType(type)(fn(d))
    })
  })
}

function formatDataExtractParams(params) {
  return Object.entries(params)
    .reduce((acc, entries, index) => {
      const [key, value] = entries
      acc += `param${index}=${key}&param${index}=${value}`
      return acc
    }, '')
    .slice(0, -1)
}

// TODO date
export async function getPerformanceKpis(portfCode, periods) {
  const data = await boneRequest('getRunDataExtractTemplate_PERF_NET_FEE', { portfCode }, pertNetFeeStructure, true)
  return data.reduce((acc, v) => {
    acc[v.period] = v
    return acc
  }, {})
}
// TODO date
export async function getNav(portfCode) {
  const data = await boneRequest('getRunDataExtractTemplate_NAV', { portfCode }, navStructure, true)
  return data[0]
}

export async function getRiskIndicators(portfShareCode, date, periods, lgCode) {
  const params = getRiskIndicatorsParams({ portfShareCode, date, lgCode, periods })
  const data = await boneRequest('getRunDataExtractTemplate', params, riskIndicatorsStructure)
  return data.reduce((acc, v) => {
    acc[v.period] = v
    return acc
  }, {})
}
