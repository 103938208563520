import { getMaxDrawdownValue } from './drawdown'
// Use standard date utils or at least import them instead of relying on global methods Date.minus etc...
export function getWeeklyData(arr, period, day) {
  // Note sortedArr elements need to have a "date" property in the string format "YYYY-MM-DD"
  const sortedArr = arr.sort('-date')
  const lastDay = sortedArr[0]
  const lastDate = new Date(lastDay.date)
  const firstDay = sortedArr[sortedArr.length - 1]
  const firstDate = new Date(firstDay.date)
  const targetDates = [lastDate]
  let currentDate = lastDate
  while (currentDate > firstDate) {
    currentDate = currentDate.minus('7 days')
    targetDates.push(currentDate)
  }
  function findClosestDay(targetDate, sortedArr, i = 0) {
    if (i >= 5) {
      // console.log('Could not find day ', targetDate)
      return
    }
    if (targetDate < firstDate) return
    const stringTargetDate = targetDate.format('YYYY-MM-DD')
    const targetDay = sortedArr.find(d => d.date === stringTargetDate)
    if (!targetDay) return findClosestDay(targetDate.minus('1 day'), sortedArr, i + 1)
    return targetDay
  }
  return targetDates.map(d => findClosestDay(d, sortedArr)).filter(Boolean)
}

export function getMean(arr) {
  return arr.reduce((acc, v) => acc + v, 0) / arr.length
}

export function getVolatility(arr, metric, period, method = 'log') {
  const filteredArr = getWeeklyData(arr, period)
  if (!filteredArr) return 0
  const returns = getReturns(filteredArr, metric, method)
  return getStd(returns, 1) * Math.sqrt(52) || '-'
}

export function getReturns(arr, metric, method = 'log') {
  if (!arr) return []
  const methods = {
    log: (d, i) => Math.log(d[metric] / arr[i - 1][metric]),
    'no-log': (d, i) => d[metric] / arr[i - 1][metric] - 1,
  }
  return arr.map((d, i) => (i === 0 ? null : methods[method](d, i))).filter(d => d !== null)
}

// Lol
export function getStd(arr, normalization = 1) {
  // normalisation: 0 = Pearson, 1 = Standard
  const mean = getMean(arr)
  const variance = arr.map(d => (d - mean) ** 2)
  return Math.sqrt(variance.sum() / (variance.length - normalization))
}

export function getPerformance(arr, metric, method = 'no-log') {
  if (method === 'log') return Math.log(+arr.last()[metric] / +arr.first()[metric])
  return +arr.last()[metric] / +arr.first()[metric] - 1
}

// export function getAnnualizedPerformance(arr, metric) {
//   const numberOfDays = (new Date(arr.last().date) - new Date(arr.first().date)) / (1000 * 60 * 60 * 24)
//   if (numberOfDays <= 365) return (+arr.last()[metric] / +arr.first()[metric]) * (360 / numberOfDays) - 1
//   return Math.pow(+arr.last()[metric] / +arr.first()[metric], 365 / numberOfDays) - 1
// }
export function getAnnualizedPerformance(arr, metric) {
  const numberOfDays = (new Date(arr[arr.length - 1].date) - new Date(arr[0].date)) / (1000 * 60 * 60 * 24)
  if (numberOfDays < 365) {
    return (+arr[arr.length - 1][metric] / +arr[0][metric] - 1) * (360 / numberOfDays)
  }
  return Math.pow(+arr[arr.length - 1][metric] / +arr[0][metric], 365 / numberOfDays) - 1
}

function getNumberOfMonths(d1, d2) {
  let months = (d2.getFullYear() - d1.getFullYear()) * 12
  months -= d1.getMonth()
  months += d2.getMonth()
  return months <= 0 ? 0 : months
}
export function getAnnualizedPerformanceCalendar(arr, metric) {
  const numberOfMonths = getNumberOfMonths(new Date(arr.first().date), new Date(arr.last().date))
  return Math.pow(+arr.last()[metric] / +arr.first()[metric], 12 / numberOfMonths) - 1
}

export function getTrackingError(arr, period, metric = 'benchmark', method = 'log') {
  // TO DO : refacto with returns
  // return 0
  const filteredArr = getWeeklyData(arr, period)
  if (!filteredArr) return 0
  const methods = {
    log: (d, i) => Math.log(d.fund / filteredArr[i - 1].fund) - Math.log(d[metric] / filteredArr[i - 1][metric]),
    'no-log': (d, i) => d.fund / filteredArr[i - 1].fund - d[metric] / filteredArr[i - 1][metric],
  }
  const values = filteredArr.map((d, i) => (i === 0 ? null : methods[method](d, i))).filter(d => d !== null)
  return getStd(values, 1) * Math.sqrt(52) || '-'
}

// TODO annualisée => linéaire
// Array.performance_annualized = (arr, metric) => {
//   const number_of_days = (new Date(arr.last().date) - new Date(arr.first().date)) / (1000 * 60 * 60 * 24)
//   if (new Date(arr.last().date).minus('1 year') < new Date(arr.first().date)) return null
//   return Math.pow(+arr.last()[metric] / +arr.first()[metric], 365 / number_of_days) - 1
// }

export function getInformationRatio(arr, period, metric = 'benchmark') {
  const trackingError = getTrackingError(arr)
  if (trackingError === '-') return '-'
  const annualizedPerfFund = arr.performance_annualized('fund') || arr.performance('fund')
  const annualizedPerfBenchmark = arr.performance_annualized(metric) || arr.performance(metric)
  const realYears = Math.round(((new Date(arr.last().date) - new Date(arr.first().date)) / (365 * 24 * 60 * 60 * 1000)) * 100) / 100
  const informationRatio = realYears >= 1 ? (annualizedPerfFund - annualizedPerfBenchmark) / trackingError : '-'
  return informationRatio
}

function getDomainFromPeriod(dates, period) {
  if (Array.isArray(period)) return period.map(d => new Date(d))
  const firstDate = new Date(dates[0])
  const lastDate = new Date(dates[dates.length - 1])
  if (!period) return [firstDate, lastDate]
  if (period === 'ytd') return [lastDate.minus('1 year').end('year').start('day'), lastDate]
  if (period === 'mtd') return [lastDate.minus('1 month').end('month').start('day'), lastDate]
  if (period === '1m') return [lastDate.minus('1 month').start('day'), lastDate]
  if (period === '3m') return [lastDate.minus('3 month').start('day'), lastDate]
  if (period === '6m') return [lastDate.minus('6 month').start('day'), lastDate]
  if (period === '1y') return [lastDate.minus('1 year').start('day'), lastDate]
  if (period === '3y') return [lastDate.minus('3 years').start('day'), lastDate]
  if (period === '5y') return [lastDate.minus('5 years').start('day'), lastDate]
  if (period === '10y') return [lastDate.minus('10 years').start('day'), lastDate]
  if (period.startsWith('years-')) {
    return [
      new Date((lastDate.getFullYear() - period.split('-')[1]).toString()).minus('year').end('year'),
      new Date((lastDate.getFullYear() - period.split('-')[1] + 1).toString()).minus('year').end('year'),
    ]
  }
  if (period === 'inception') return [firstDate, lastDate]
  // if (period === 'domain') return [new Date(domain[0]), lastDate]
  return [firstDate, lastDate]
}

// TODO: had this because mb we needed a before d0 date but maybe we dont ?
function filterByDomain(arr, domain) {
  const _domain = domain.map(d => d.format('YYYY-MM-DD'))
  return arr.filter(d => {
    return _domain[0] <= d.date && d.date <= _domain[1]
  })
}
// TODO optimize this
export function getPeriodData(arr, period) {
  const sortedArr = arr.sort((a, b) => a.date - b.date)
  if (!period) return sortedArr
  const domain = getDomainFromPeriod(
    sortedArr.map(d => d.date),
    period,
  )
  return filterByDomain(sortedArr, domain)
}
const mappingFns = {
  volatility: getVolatility,
  performance: getPerformance,
  performance_annualized: getAnnualizedPerformance,
  performance_annualized_calendar: getAnnualizedPerformanceCalendar,
  max_drawdown_value: getMaxDrawdownValue,
  tracking_error: getTrackingError,
  information_ratio: getInformationRatio,
}
export function analytics(data, indicator, metric = 'fund', period = 'inception') {
  // const [fn, period, metric, arg1, arg2] = key.split('.')
  // If no period given take the whole dataset
  // period = period || [data[0], data[data.length - 1]].map(d => new Date(d.date))
  // debugger
  if (data.length === 0) return
  const filteredData = getPeriodData(data, period)
  if (metric === 'diff') {
    const fund = analytics(data, indicator, 'fund', period)
    const benchmark = analytics(data, indicator, 'benchmark', period)
    if (benchmark === '-') return '-'
    return fund - benchmark
  }
  if (
    ['performance', 'performance_annualized', 'performance_annualized_calendar', 'performance_weekly', 'volatility', 'max_drawdown_value'].includes(indicator)
  ) {
    return mappingFns[indicator](filteredData, metric, period)
  }
  if (['tracking_error', 'information_ratio', 'sharpe_ratio', 'alpha', 'beta'].includes(indicator)) {
    // if (metric) return mappingFns[indicator](filteredData, period, metric)
    return mappingFns[indicator](filteredData, period)
  }
  // if (['var_X'].includes(fn)) return data[fn](metric, period, arg1)
  // if (['var_X_Y_days'].includes(fn)) return data[fn](metric, period, arg1, arg2)
}
