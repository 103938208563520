import './assets/css/tailwind.css'
import './assets/css/platform/platform.css'
import './assets/css/white-theme.css'
import './assets/css/natixis-theme.css'
import './assets/css/natixis-dice.css'
import './assets/css/transitions.css'
import baseApp from './App.vue'

import { createPlatformApp } from '@100-m/hauru'
import auth0Login from './auth0-login.js'
import config from './config.yml'
import { setComponents } from '@/composables/embed.js'
import useEnv from './env.js'

import VueMarkdownIt from 'vue3-markdown-it'

const components = import.meta.glob('./components/**/*.vue')
window.hmm = components
const pages = import.meta.globEager('./pages/**/*.vue')
const translations = import.meta.globEager('./translations/**/*.yml')
// Auth0 login
const authConfig = auth0Login({
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  project: import.meta.env.VITE_PROJECT,
  urls: ['http://127.0.0.1:1112', import.meta.env.VITE_API_AUTH],
})

const rename = obj => Object.fromEntries(Object.entries(obj).__.map(([k, v]) => [k.replace('./pages', '').replace('.yml', ''), v]))
config.components = {}
rename(import.meta.globEager('./pages/embed/**/*.yml')).__.map((v, path) => {
  const [a, key, name] = path.split('/').__.filter(d => d)
  const componentDoc = { path, ...v.default, shortName: name }
  if (!config.components[key]) config.components[key] = {}
  config.components[key][name] = componentDoc
})
setComponents(config.components)
const cssvar = {
  icon: 'url(/logo-natixis-white.png)',
  logo: 'url(/logo-natixis.jpg)',
  nav: 'url(/logo-natixis.jpg)',
}
useEnv().then(() => {
  const app = createPlatformApp({ pages, components, translations, routerMode: 'history', authConfig, config, cssvar, baseApp })
  app.component('VueMarkdownIt', VueMarkdownIt)
  window.$root = app.config.globalProperties.$root = app.mount('#app')
})
