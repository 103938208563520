<template lang="pug">
loader(v-if="!wait")
.w-full.h-full.flex.content-center.justify-center.align-center.items-center.text-xl(v-else-if="wait.error") {{ t[wait.error] || wait.error.titleize() }}
slot(v-else)
</template>
<script>
export default {
  props: ['wait'],
}
</script>
