<template lang="pug">
section.px-5.py-24.bg-white.w-full.h-full
  .mx-auto.flex.flex-col.justify-between.items-center.h-full(class="lg:w-4/5")
    h1.text-black Error: Unauthorized
</template>
<script>
export default {
  setup() {
    return {}
  },
}
</script>
<style></style>
