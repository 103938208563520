import './utils/raw-addon.js'
import './components/layout/tooltip.js'
import App from './App.vue'
import vuePlugin from './utils/vue.js'
import { createApp, defineComponent, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'
import { setTranslation } from './features/translation.js'
import axiosPlugin from './utils/axios.js'
import useAuth from './features/auth.js'
import { initRouter, initAppsRouter, parsePageGlob } from './utils/router.js'
import { DatePicker } from 'ant-design-vue'

function parseComponentGlob(componentGlob) {
  return Object.entries(componentGlob).__.reduce((acc, [componentPath, component]) => {
    const name = componentPath.split('/').slice(-1)[0].split('.vue')[0]
    acc[name] = defineAsyncComponent(component)
    return acc
  }, {})
}

function parseDirectiveGlob(directiveGlob) {
  return Object.entries(directiveGlob).__.reduce((acc, [directivePath, directive]) => {
    const name = directivePath.split('/').slice(-1)[0].split('.js')[0].replace(/^v-/, '')
    acc[name] = defineComponent(directive.default)
    return acc
  }, {})
}

function parseTranslationGlob(translationGlob) {
  return Object.entries(translationGlob).__.reduce((acc, [translationPath, translations]) => {
    const lang = translationPath.slice(-6, -4)
    acc[lang] = { ...acc[lang], ...translations.default }
    return acc
  }, {})
}
function evalDatasets(datasets) {
  return datasets.__.map(ds => {
    const fns = ['inc_fn', 'url_fn', 'post_fn'].__.reduce((acc, v) => {
      if (!ds[v]) return acc
      acc[v] = _evalIfString(ds[v])
      return acc
    }, {})
    const structure = ds.structure?.__.map((type, field) => {
      if (type === 'number' || type === 'float' || type === 'int') return d => +d[field] || 0
      if (type === 'string' || type === 'date') return d => d[field]
      return _evalIfString(type)
    })
    return { ...ds, ...fns, structure }
  })
}
function _evalIfString(fn) {
  // eslint-disable-next-line no-eval
  if (typeof fn === 'string') return eval(fn)
  return fn
}

const baseComponents = import.meta.glob('./components/**/*.vue')
const baseDirectives = import.meta.globEager('./directives/**/*.js')
const basePages = import.meta.globEager('./pages/**/*.vue')
const baseTranslations = import.meta.globEager('./translations/**/*.yml')

function initApp(appConfig) {
  if (appConfig.apps) return initMultiApp(appConfig)
  return initSingleApp(appConfig)
}

function initSingleApp({ pages, components, directives, translations, mixins, authConfig, routerMode, config, cssvar, baseApp }) {
  baseApp = baseApp || App
  baseApp.mixins = baseApp.mixins.concat(mixins || [])
  const app = createApp(baseApp)
  window.pages = pages = parsePageGlob({
    ...basePages,
    ...pages,
  })
  window.components = components = parseComponentGlob({
    ...baseComponents,
    ...components,
  })
  window.directives = directives = parseDirectiveGlob({
    ...baseDirectives,
    ...directives,
  })
  window.translations = translations = parseTranslationGlob({
    ...baseTranslations,
    ...translations,
  })
  setTranslation(translations)
  config = config || {}
  if (config.datasets) config.datasets = evalDatasets(config.datasets)
  const router = initRouter(pages, routerMode)
  return { app, router, components, directives, config, translations }
}
function initMultiApp({ pages, components, directives, translations, mixins, authConfig, routerMode, config, cssvar, apps, baseApp }) {
  baseApp = baseApp || App
  baseApp.mixins = baseApp.mixins.concat(mixins || [])
  const app = createApp(baseApp)
  window.pages = pages = parsePageGlob({
    ...basePages,
    ...pages,
  })
  const router = initAppsRouter(pages, apps, routerMode)

  // TODO warn if duplicate components
  const appComponents = Object.assign({}, ...Object.values(apps).__.map(appConfig => appConfig.components))
  window.components = components = parseComponentGlob({
    ...baseComponents,
    ...components,
    ...appComponents,
  })
  const appDirectives = Object.assign({}, ...Object.values(apps).__.map(appConfig => appConfig.directives))
  window.directives = directives = parseDirectiveGlob({
    ...baseDirectives,
    ...directives,
    ...appDirectives,
  })

  // TODO app specific translations
  window.translations = translations = parseTranslationGlob({
    ...baseTranslations,
    ...translations,
  })
  setTranslation(translations)
  Object.entries(apps).forEach(([app, appConfig]) => {
    const appTranslations = parseTranslationGlob({
      ...baseTranslations,
      ...appConfig.translations,
    })
    setTranslation(appTranslations, app)
  })
  config = config || {}
  if (config.datasets) config.datasets = evalDatasets(config.datasets)
  config.apps = Object.fromEntries(
    Object.entries(apps).__.map(([key, app]) => {
      if (app?.config?.datasets) app.config.datasets = evalDatasets(app.config.datasets)
      return [key, app]
    }),
  )
  return { app, router, components, directives, config, translations }
}
// { pages, components, directives, translations, authConfig, routerMode, config, cssvar, apps }
function createPlatformApp(appConfig) {
  const { app, router, components, directives, config, translations } = initApp(appConfig)
  app.use(vuePlugin, { config })
  app.mixin({
    computed: {
      t() {
        return this.$root.t.__.map(trad => trad?.split('|')[0])
      },
    },
  })
  app.use(axiosPlugin)
  const { guard, authPlugin } = useAuth()
  app.use(authPlugin, appConfig.authConfig || {})
  Object.entries(components).map(([name, component]) => app.component(name, component))
  Object.entries(directives).map(([name, directive]) => app.directive(name, directive))

  router.beforeEach(guard)
  app.use(router)
  app.use(DatePicker)

  // TEMP stuff
  app.config.globalProperties.$cssvar = appConfig.cssvar
  app.config.globalProperties.$config = config
  app.config.devtools = import.meta.env.MODE !== 'production'
  return app
}

export { createPlatformApp, useRoute }
