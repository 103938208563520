import { createRouter, createWebHashHistory, createWebHistory, useRoute } from 'vue-router'
import { resolveComponent, h } from 'vue'
import PageBuilder from '../pages/builder.vue'

export function initRouter(routes, mode = 'hash') {
  const history = mode === 'history' ? createWebHistory() : createWebHashHistory()
  return createRouter({
    strict: true,
    history,
    routes,
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact',
  })
}

function getRootComponent(rootComponent) {
  if (rootComponent) return rootComponent
  return {
    name: 'Root',
    setup() {
      const routerView = resolveComponent('router-view')
      // return () => h('section', {}, h(routerView))
      return () => h(routerView)
    },
  }
}

export function initAppsRouter(routes, apps, mode = 'hash') {
  const history = mode === 'history' ? createWebHistory() : createWebHashHistory()
  const appRoutes = Object.entries(apps).__.map(([app, appConfig]) => {
    const rootComponent = getRootComponent(appConfig.rootComponent)
    const children = parsePageGlob(appConfig.pages, true).__.map(route => {
      route.meta.app = app
      route.path = route.path.replace(/^\//, '')
      return route
    })
    const appRoute = {
      path: '/' + app + '/',
      component: rootComponent,
      children: children,
      meta: { app, root: true },
    }
    return appRoute
  })
  window.appRoutes = appRoutes
  const router = createRouter({
    strict: true,
    history,
    routes: [...routes, ...appRoutes],
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact',
  })
  router.beforeEach(to => {
    if (to.meta.app) router.app = to.meta.app
    return true
  })
  return router
}

export function _parseRawPath(rawPath, relative) {
  const cleanPath = '/' + rawPath.split('/pages/').slice(1).join()
  const path = cleanPath
    .replace('/index.vue', '')
    .split('/')
    .__.map(path => {
      if (path.startsWith('_')) path = path.replace('_', ':')
      return path.replace('slash.vue', '').split('.vue')[0]
    })
    .join('/')
  if (relative) return path.slice(1)
  return path
}
export function parsePageGlob(routeGlob, relative = false) {
  return Object.entries(routeGlob)
    .__.map(([rawPath, component]) => {
      const path = _parseRawPath(rawPath, relative)
      const name = path.split('/').last()
      let access = path === '/login' ? 'anonymous' : 'user'
      if (component?.additions?.props) {
        console.log('TODO: props in component additions should go component itself', component)
      }
      const { datasets, icon = '', theme = false, secured = false } = component.default
      access = secured ? 'admin' : access
      return { path, component: component.default, meta: { name, access, datasets, icon, theme, secured, ...component.additions } }
    })
    .concat({ path: '/:userflow/:screen/builder', component: PageBuilder, meta: { access: 'user', builder: true } })
    .concat({ path: '/logout', component: () => $root.$auth.logout(), meta: { access: null } })
    .concat({ path: '/:catchAll(.*)', redirect: '/', meta: { access: null } })
}
