<template lang="pug">
audit-tabs
.flex.flex-col.p-4.gap-2
  h2 Funds
  .flex.flex-row.items-center.justify-between
    fieldset.flex.flex-col.gap-1.max-w-xs.flex-1
      label filter by name, portfCode...
      input.p-1(v-model="search")
    button(@click="download") Download data
  dice-table.risk-table.performance-table.small(v-if="filteredFunds" :data="filteredFunds" :columns="fundsColumns")
  div(v-else) Loading all funds
</template>
<script>
import { ref, computed } from 'vue'
import { pickBy } from 'lodash'
import { getFundsAudit, getFunds } from '@/composables/api'

export default {
  setup() {
    const funds = ref()
    const search = ref()
    const state = ref('init')
    async function init() {
      const data = await getFunds()
      funds.value = Object.values(data)
        .sort(d => d.name)
        .map(d =>
          d.shares.map(share => {
            const datum = pickBy(d, (v, k) => !['shares'].includes(k))
            return { ...datum, ...share }
          }),
        )
        .flat()
      state.value = 'ready'
      //.sort(d => ['Mirova Europe Sustainable Economy Fund', 'Natixis Diversified Solution'].indexOf(d.name))
    }
    init()
    const fundsColumns = [
      { key: 'name', name: 'name' },
      { key: 'portfCode', name: 'portfCode' },
      { key: 'portfShareCode', name: 'portfShareCode' },
      { key: 'isin', name: 'ISIN' },
      // { key: 'hasHoldings', name: 'has holdings', format: d => (d ? 'Yes' : 'No') },
      { key: 'hasPerf', name: 'has performance', format: d => (d ? 'Yes' : 'No') },
    ]
    const filteredFunds = computed(() => {
      if (!funds.value) return
      if (!search.value) return funds.value
      const regexp = new RegExp(search.value, 'i')
      return funds.value.filter(d => regexp.test(d.name) || regexp.test(d.portfCode) || regexp.test(d.hasPerf ? 'Yes' : 'No'))
    })

    function download() {
      if (!funds.value) return
      funds.value.dlCSV('fr', 'funds.csv')
    }

    return {
      funds,
      search,
      state,
      fundsColumns,
      filteredFunds,
      download,
    }
  },
}
</script>
