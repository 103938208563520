<template lang="pug">
h1.mb-4 Introduction
.mb-4 Neoxam components in iframes that can communicate with each other through the <code>window.postMessage</code> api
ul.list-disc.text-xl
  li
    router-link(to="/docs/getting-started") Getting started
  li
    router-link(to="/docs/embed/performance") Performance components
</template>
<script>
export default {
  setup() {
  },
}
</script>
<style>
</style>