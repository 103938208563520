<template lang="pug">
audit-tabs
.flex.flex-row
  .flex.flex-col.gap-2.p-4.flex-1.max-w-md
    .flex.flex-col.gap-2
      fieldset.flex.flex-col
        label Fund
        select(v-model="activeFund")
          option(v-for="fund in funds" :value="fund") {{ fund.name }}
      fieldset.flex.flex-col(v-if="activeFund")
        label Share
        select(v-model="activeShare")
          option(v-for="share in activeFund.shares" :value="share.portfShareCode") {{ share.isin }}
    button.ml-auto(@click="getData") Valider
.flex.flex-col.p-4.gap-2
  h2 Details
  .flex.flex-row.items-center.justify-end.gap-4
    button(@click="downloadRaw") Download raw data
    button(@click="download") Download data
  dice-table.risk-table.performance-table.small(:data="performance" :columns="perfColumns")
</template>
<script>
import { ref, computed } from 'vue'
import { sortBy } from 'lodash'
import { getPerformanceAudit, getFunds } from '@/composables/api'

export default {
  setup() {
    const activeFund = ref()
    const activeShare = ref()
    const date = ref('2022-02-28')
    const performance = ref()
    const rawPerformance = ref()
    const search = ref()
    const state = ref('ready')
    const funds = ref()
    async function init() {
      const data = await getFunds()
      funds.value = Object.values(data)
        .map(d => {
          const shares = d.shares.filter(share => share.hasPerf)
          return { ...d, shares }
        })
        .filter(d => d.shares.length)
    }
    init()
    async function getData() {
      state.value = 'loading'
      if (!activeFund.value) return
      const portfCode = activeFund.value.portfCode
      const portfShareCode = activeShare.value
      const res = await getPerformanceAudit(portfCode, portfShareCode)
      performance.value = res.performance
      rawPerformance.value = res.rawPerformance.sort((a, b) => new Date(a.nav_date) - new Date(b.nav_date))
      state.value = 'ready'
    }
    const perfColumns = [
      { key: 'date', name: 'Date' },
      { key: 'fund', name: 'Fund' },
      { key: 'benchmark', name: 'Benchmark' },
    ]
    function downloadRaw() {
      if (!rawPerformance.value) return
      const rawPerf = rawPerformance.value.map(d => {
        const nav_date = new Date(d.nav_date).format('DD/MM/YYYY')
        return { ...d, nav_date }
      })
      rawPerf.dlCSV('fr', 'rawPerformance.csv')
    }
    function download() {
      if (!performance.value) return
      performance.value.dlCSV('fr', 'performance.csv')
    }
    return {
      getData,
      date,
      performance,
      rawPerformance,
      search,
      download,
      downloadRaw,
      activeFund,
      activeShare,
      funds,
      perfColumns,
    }
  },
}
</script>
