<template lang="pug">
wait-for(:wait="loaded")
  .bg-white.flex.flex-col.min-h-full(class="sm:p-4")
    cjs-bar-chart(:data="performances" :options="options")
      template(v-slot:tooltip="{tooltip}")
        table-tooltip(v-if="tooltip.shown" :title="tooltip.title" :rows="formatRows(tooltip.rows)")
</template>

<script>
import WaitFor from '../../../components/WaitFor.vue'
import usePerformanceMocks from '../../../composables/usePerformanceMocks'

export default {
  components: { WaitFor },
  methods: {
    log: console.log,
    formatTitle(title) {
      return new Date(title).format('day, mon, year', $root.lang).titleize()
    },
    formatRows(rows) {
      return rows.map(r => {
        return {
          label: this.t[r.label] || r.label,
          value: format('.2f')(r.value) + '%',
          color: r.color,
        }
      })
    },
  },
  setup() {
    const { performances, loaded } = usePerformanceMocks('perfByYear')
    const options = {

      labelKey: 'date',
      datasets: [
        {
          label: 'Fund',
          key: 'fund',
        },
        {
          label: 'Benchmark',
          key: 'benchmark',
        },
      ],
      formatY: value => format('.2')(value) + '%',
      labelFormatter: value => format('.1f')(value) + '%',
    }

    return {
      performances,
      options,
      loaded,
    }
  },
}
</script>

