<template lang="pug">
section.main.px-5.py-24.bg-white.w-full.min-h-full.bg-gray-50.max-w-full.overflow-hidden(v-if="$root.$route.fullPath.startsWith('/docs')")
  .mx-auto(class="lg:w-4/5")
    router-view
nav-left(v-if="$root.$route.fullPath.startsWith('/docs')")
router-view(v-else)
toast(:messages="messages")
</template>

<script>
import context from '@100-m/hauru/src/features/context.js'
// import xfilter from '@100-m/hauru/src/features/xfilter.js'
import { useTranslation } from '@100-m/hauru/src/features/translation.js'
import { provide, ref } from 'vue'
export default {
  mixins: [
    context,
    // xfilter,
  ],
  setup() {
    const progress = ref(0)
    provide('progress', progress)
    return {
      ...useTranslation(),
      progress,
    }
  },
  mounted() {
    this.$auth.initAuth()
  },
}
</script>
<style>
section.main {
  padding-left: calc(var(--menu-left) + 1.25rem);
}
</style>
