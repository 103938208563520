<template lang="pug">
audit-tabs
loading-indicator.mt-16(v-if="state === 'init'") Loading available funds...
.flex.flex-col(v-else)
  .flex.flex-row
    .flex.flex-col.gap-2.p-4.flex-1.max-w-md
      fieldset.flex.flex-col.gap-1
        label.font-bold portfCode
        input.input-select.p-2(v-model="portfCode" list="funds")
        datalist#funds
          option(v-for="fund in funds" :value="fund.portfCode") {{ fund.name }}
      .flex.flex-col.gap-1
        .font-bold Date
        .flex.flex-row.items-center.gap-8(v-if="calendarDates")
          date-picker.bg-white(v-model="activeDate" :dates="calendarDates")
            template(#default="{ date, showModal }")
              .flex.flex-row.items-center.gap-2.cursor-pointer.py-1.px-2.border.border-gray-200.rounded-md(style="height: 38px;" @click="showModal")
                span.font-bold {{ date.format('day, mon, year', $root.lang) }}
                svg-icon.text-gray-400.fill-current(name="ic_date_range")
        div(v-else-if="state === 'loading_dates'") Loading...
        div(v-else) Choose a portfCode to see available dates
      //- fieldset.flex.flex-col.gap-1
      //-   label date
      //-   input.p-2(type="date" v-model="date")
      fieldset.flex.flex-col.gap-1 
        label.font-bold componentCode
        select(v-model="componentCode")
          option(v-for="componentName in componentNames" :value="componentName") {{ $root.t[componentName] }} ({{ componentName }})
      button.ml-auto(@click="getData") Valider
    dice-table.risk-table.performance-table.small(:data="allocationTable" :columns="allocationColumns")
  .flex.flex-col.p-4.gap-2
    h2 Details
    .flex.flex-row.items-center.justify-between
      fieldset.flex.flex-col.gap-1.max-w-xs.flex-1
        label filter by name or dimension
        input.p-1(v-model="search")
      button(@click="download") Download data
    dice-table.risk-table.performance-table.small(:data="filteredHoldings" :columns="holdingsColumns")
</template>
<script>
import { ref, computed, watch } from 'vue'
import { getAllocationBreakdownAudit, getFunds, getAllocationDates } from '@/composables/api'
import { pickBy } from 'lodash'

const componentNames = [
  'DIG_OBL_RTG_INTERV_NAMM_MV',
  'DIG_ACT_ZONESGEO_MV',
  'DIG_DIV_PAYS_MV',
  'DIG_AA_ALL_STR_SECT_INSTR_LEVEL_FOR_FUND',
  'DIG_AA_ALL_STR_SECT_NO_INSTR_FOR_BENCH',
  'DIG_DIV_ZONES_GEO_MV',
  'DIG_OBL_MATURITE_MV',
  'DIG_OBL_PAYS_MV',
  'DIG_OBL_RTG_MIROVA_MV',
  'DIG_OBL_SECT_BARCLAYS_MB_MV',
  'DIG_OBL_ZONESGEO_MV',
  'DIG_ALL_DEV_MV',
  'DIG_OBL_RTG_SP_MV',
  'DIG_STR_PTF_GLOB_ABS (code manuel)_MV',
  'DIG_ACT_PAYS_MV',
  'DIG_ACT_SECT_MSCI_2018_MV',
  'DIG_ACT_TAILLE_CAPI_MIDLARGE_MV',
  'DIG_ACT_TAILLE_CAPI_NIMSA_MV',
  'DIG_ACT_TAILLE_CAPI_THEMATICS_MV',
]
const demoFunds = [
  { name: 'Loomis Sayles Global Growth Equity Fund', portfCode: 'IFM_3537' },
  { name: 'Mirova Euro Green&Sustainable Bond Fund', portfCode: 'IFM_3040' },
  { name: 'Mirova Euro Sustainable Equity Fund', portfCode: 'IFM_3039' },
  { name: 'Mirova Europe Sustainable Economy Fund', portfCode: 'IFM_3794' },
  { name: 'Natixis Diversified Solution', portfCode: 'IFM_111' },
  { name: 'Ostrum SRI Cash Plus', portfCode: 'IFM_1820' },
  { name: 'Ostrum SRI Euro Souvereign Bonds', portfCode: 'IFM_372' },
  { name: 'SEEYOND Global MinVol', portfCode: 'IFM_3035' },
  { name: 'Seeyond Volatility Strategy', portfCode: 'IFM_3036' },
  { name: 'IFM_3257', portfCode: 'IFM_3257' },
  { name: 'IFM_3264', portfCode: 'IFM_3264' },
]
export default {
  setup() {
    const portfCode = ref()
    const activeDate = ref('2022-02-28')
    const calendarDates = ref()
    const componentCode = ref()
    const holdings = ref()
    const allocation = ref()
    const search = ref()
    const funds = ref()
    let portfCodes = []
    const state = ref('init')
    async function init() {
      const data = await getFunds()
      funds.value = Object.values(data).sort(d => d.name)
      state.value = 'ready'
      portfCodes = funds.value.map(d => d.portfCode).concat(['IFM_3257', 'IFM_3264'])
    }
    init()
    async function getData() {
      state.value = 'loading'
      const res = await getAllocationBreakdownAudit(portfCode.value, componentCode.value, activeDate.value)
      holdings.value = res.enrichedHoldings.map(row => {
        const datum = pickBy(row, (v, k) => !['StaticAssetRef', 'File'].includes(k))
        return { ...row.StaticAssetRef, ...datum }
      })
      allocation.value = res.data
      state.value = 'ready'
    }
    const filteredHoldings = computed(() => {
      if (!search.value) return holdings.value
      const regexp = new RegExp(search.value, 'i')
      return holdings.value.filter(d => regexp.test(d.name) || regexp.test(d.dimension))
    })
    const allocationTable = computed(() => {
      return (
        allocation.value &&
        Object.entries(allocation.value).map(([k, v]) => {
          return {
            name: k,
            ...v,
          }
        })
      )
    })

    const allocationColumns = [
      {
        key: 'name',
        name: 'name',
      },
      {
        key: 'ptfWeight',
        name: 'ptfWeight',
        format: d => format('.2%')(d),
      },
      {
        key: 'bmkWeight',
        name: 'bmkWeight',
        format: d => format('.2%')(d),
      },
    ]

    function download() {
      filteredHoldings.value.dlCSV('fr', 'holdings.csv')
    }
    // "dimension": "Unknown",
    // "id": 1544,
    // "nav_date": "2022-01-31T00:00:00.000Z",
    // "portfCode": "IFM_111",
    // "portfId": 50106,
    // "fuid": 50023,
    // "currency": "USD",
    // "price": null,
    // "fixing": 1.121000000072,
    // "market_value": 6838542.31,
    // "exposure": 6838542.31,
    // "weight_fund": 0.008292057036,
    // "holding_type": "TE",
    // "createdAt": "2022-05-24T08:19:57.710Z",
    // "updatedAt": "2022-05-24T08:19:57.749Z",
    // "fileId": 27,
    // "StaticAssetRef": {
    //   "fuid": 50023,
    //   "code": "108",
    //   "isin_code": null,
    //   "modification_date": "2021-01-18T11:33:08.000Z",
    //   "name": "DOLLAR USA",
    //   "instrument_type_code": "9",
    //   "instrument_type_name": "Liquidités comptant",
    //   "country_code": "NR",
    //   "country_name": null,
    //   "currency_code": "USD",
    //   "currency_name": "DOLLAR USA",
    //   "disactivation_flag_1act_2inact": null,
    //   "createdAt": "2022-05-24T08:17:28.566Z",
    //   "updatedAt": "2022-05-24T08:17:29.018Z",
    //   "fileId": 4
    const holdingsColumns = ref([
      {
        key: 'fuid',
        size: 0.5,
        name: 'FUID',
      },
      {
        key: 'name',
        name: 'name',
      },
      {
        key: 'weight_fund',
        name: 'Weight fund',
        format: d => format('.2%')(d),
      },
      { key: 'dimension', name: 'Dimension' },
      { key: 'instrument_type_name', name: 'Asset Class' },
      { key: 'groupId', name: 'Group ID' },
    ])

    watch(portfCode, async () => {
      if (!portfCode.value || !portfCodes.includes(portfCode.value)) {
        calendarDates.value = null
        return
      }
      state.value = 'loading_dates'
      calendarDates.value = await getAllocationDates(portfCode.value)
      activeDate.value = new Date(calendarDates.value[calendarDates.value.length - 1])
      state.value = 'ready'
    })
    return {
      state,
      portfCode,
      activeDate,
      calendarDates,
      componentCode,
      holdings,
      allocation,
      getData,
      holdingsColumns,
      search,
      filteredHoldings,
      download,
      componentNames,
      allocationTable,
      allocationColumns,
      demoFunds,
      funds,
    }
  },
}
</script>
<style>
.input-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}
</style>
