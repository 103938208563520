const mappingPeriods = {
  '1w': '1SEMAINE',
  '1m': '1MOIS',
  '3m': '3MOIS',
  '6m': '6MOIS',
  '1y': '12MOIS',
  '3y': '3ANS',
  '5y': '5ANS',
  inception: 'OUVERTURE',
  ytd: 'DEB_ANNEE',
  mtd: 'DEB_MOIS',
}
function reverseObject(obj) {
  return Object.entries(obj).reduce((acc, entries) => {
    const [key, value] = entries
    acc[value] = key
    return acc
  }, {})
}

export const pertNetFeeStructure = {
  shareCode: {
    key: 'PSH_CODE',
    type: 'string',
  },
  date: {
    key: 'CALCUL_DATE',
    type: 'date',
  },
  performanceFund: {
    key: 'PPERF_NET',
    type: 'number',
  },
  performanceBenchmark: {
    key: 'BPERF_NET',
    type: 'number',
  },
  period: {
    fn: d => reverseObject(mappingPeriods)[d.FREQUENCY],
    type: 'string',
  },
  fundAnnualized: {
    key: 'NET_ANNUALIZED_RETURN',
    type: 'number',
  },
  benchmarkAnnualized: {
    key: 'REF_NET_ANNUALIZED_RETURN',
    type: 'number',
  },
  startDate: {
    key: 'PERF_BEGIN_DATE',
    type: 'date',
  },
}

export const navStructure = {
  aum: {
    key: 'TOTAL_NET_ASSET',
    type: 'number',
  },
  nav: {
    key: 'NAV_PER_SHARE',
    type: 'number',
  },
}

export const riskIndicatorsStructure = {
  volatilityFund: {
    key: 'POIND_VOLATILITY',
    type: 'number',
  },
  volatilityBenchmark: {
    key: 'POIND_BENCH_VOLATILITY',
    type: 'number',
  },
  sharpeFund: {
    key: 'POIND_SHARPE_RATIO',
    type: 'number',
  },
  sharpeBenchmark: {
    key: 'REF_SHARPE_RATIO',
    type: 'number',
  },
  informationRatio: {
    key: 'POIND_INFO_RATIO',
    type: 'number',
  },
  alpha: {
    key: 'POIND_ALPHA',
    type: 'number',
  },
  beta: {
    key: 'POIND_BETA',
    type: 'number',
  },
  trackingError: {
    key: 'TRACKING_ERROR',
    type: 'number',
  },
  date: {
    key: 'CALCUL_DATE',
    type: 'date',
  },
  period: {
    fn: d => reverseObject(mappingPeriods)[d.STATS_FREQUENCY_ID],
    type: 'string',
  },
  shareCode: {
    key: 'PSH_CODE',
    type: 'string',
  },
  maxDrawdown: {
    key: 'PORTF_MAX_DRAWDOWN',
    type: 'number',
  },
  maxDrawdownDate: {
    key: 'PORTF_MAX_DRAW_DOWN_DATE',
    type: 'date',
  },
  maxDrawdownDuration: {
    key: 'PORTF_DRAWDOWN_DURATION',
    type: 'number',
  },
  r2: {
    key: 'R2',
    type: 'number',
  },
}
// [{"INSTRUMENT_NAME":"M.EUR.SU.EQ.SIANPF",
// "Type d'instrument (Nom)":"OPC Actions","Signe":"+","AMOUNT":5031114.93}
export const transactionsStructure = {
  name: {
    key: 'INSTRUMENT_NAME',
    type: 'string',
  },
  assetClass: {
    fn: d => d["Type d'instrument (Nom)"] || d['Instrument Type (Name)'],
    type: 'string',
  },
  transactionType: {
    fn: d => ((d.Signe || d.Sign) === '+' ? 'purchase' : 'sale'),
    type: 'string',
  },
  amount: {
    key: 'AMOUNT',
    type: 'number',
  },
}
// {"Share Class ISIN":"LU0914732671",
// "Holding Date":"2021-04-30",
// "Name of Share Class":"MIROVA EUROPE ENVIRONMENTAL EQUITY FUND - I (C) EUR",
// "ISIN":"NR","Name":"010166NM001C","Weight":0,
// "Instrument Type (Code)":"101","Instrument Type (Name)":"Prêt/Emp. de titre contre tit."},
// {"Date":"2021-12-31","Portfolio Name":"Croissance Diversifiée","ISIN Code":"CASHUSD00000",
// "Instrument Name":"DOLLAR USA","Currency":"USD","Weight":0.012916812582986773,"Instr. Type":"Liquidités comptant"}
export const holdingsStructure = {
  isin: {
    fn: d => d['ISIN Code'] || d.ISIN,
    type: 'string',
  },
  date: {
    fn: d => d.Date || d['Holding Date'],
    type: 'date',
  },
  name: {
    fn: d => d['Instrument Name'] || d.Name,
    type: 'string',
  },
  assetClass: {
    fn: d => d['Instrument Type (Name)'] || d['Instr. Type'] || d["Type d'instrument (Nom)"],
    type: 'string',
  },
  weight: {
    key: 'Weight',
    type: 'number',
  },
}
// "PERF_FREQUENCY_CODE": "WEEKLY",
// "REF_ENTITY_TYPE_CODE": "TPortfConfig",
// "POIND_BEGIN_DATE": "2021-02-22",

const DIMENSIONS = {
  Sector: 'DIG_RC_SV_OBL_SECT_BARCLAYS_MV',
  AssetClass: 'DIG_RC_S_STR_PTF_GLOB_ABS_MV',
  Country: 'DIG_RC_SE_ACT_ZONES_PAYS_MV',
  Maturity: 'DIG_RC_SE_OBL_MATURITE_DIV_MV',
  Rating: 'DIG_RC_SV_OBL_RTG_SP_MV',
  Currency: 'DIG_RC_SV_ALL_DEV_MV',
}
function normalizePortfCode(portfCode) {
  return portfCode.startsWith('IFM_') ? portfCode : 'IFM_' + portfCode
}
function normalizePortfShareCode(portfShareCode) {
  return portfShareCode.endsWith('_') ? portfShareCode : portfShareCode + '_'
}
export function getAllocationParams({ portfCode, reportComponentSettingCode, date, lgCode }) {
  if (!portfCode) throw new Error('Missing portfCode')
  if (!date) throw new Error('Missing date')
  // if (!dimension) throw new Error('Missing dimension')
  const _portfCode = normalizePortfCode(portfCode)
  const _date = new Date(date).format('YYYYMMDD')
  const configCode = 40
  const _lgCode = lgCode || 'fr'
  // const reportComponentSettingCode = DIMENSIONS[dimension]
  // if (!reportComponentSettingCode) throw new Error('Unknow dimension ' + dimension)
  return {
    configCode,
    lgCode: _lgCode,
    reportComponentSettingCode,
    date: _date,
    portfCode: _portfCode,
  }
}

export function getDrilldownParams({ portfCode, reportComponentSettingCode, date, lgCode }) {
  if (!portfCode) throw new Error('Missing portfCode')
  if (!date) throw new Error('Missing date')
  // if (!dimension) throw new Error('Missing dimension')
  const _portfCode = normalizePortfCode(portfCode)
  const _date = new Date(date).format('YYYYMMDD')
  const configCode = reportComponentSettingCode === 'DIG_AA_ALL_STR_SECT_NO_INSTR_FOR_BENCH' ? 40 : 41
  const _lgCode = lgCode || 'fr'
  // const reportComponentSettingCode = DIMENSIONS[dimension]
  // if (!reportComponentSettingCode) throw new Error('Unknow dimension ' + dimension)
  return {
    configCode,
    lgCode: _lgCode,
    reportComponentSettingCode,
    date: _date,
    portfCode: _portfCode,
  }
}

export function getPerformanceParams({ portfShareCode, fromDate, toDate, lgCode, currencyCode }) {
  if (!portfShareCode) throw new Error('Missing portfShareCode')
  const _fromDate = new Date(fromDate).format('YYYYMMDD')
  const _toDate = new Date(toDate).format('YYYYMMDD')
  const _portfShareCode = portfShareCode.endsWith('_') ? portfShareCode : portfShareCode + '_'
  const configCode = 5
  const _lgCode = lgCode || 'fr'
  const _currencyCode = currencyCode || 'EUR'
  const samplingFrequencyType = 'elementary'

  return {
    configCode,
    lgCode: _lgCode,
    fromDate: _fromDate,
    toDate: _toDate,
    portfShareCode: _portfShareCode,
    samplingFrequencyType,
    currencyCode: _currencyCode,
  }
}
// ?templateCode=RISK_INDICATORS&lgCode=fr
// &param0[]=SHARE_CODE&param0[]=dataType.string&param0[]=42826_&
// param1[]=CALCUL_DATE&param1[]=dataType.date&param1[]=30%2F04%2F2021&
// param2[]=REFERENCE_ID&param2[]=dataType.integer&param2[]=5&
// param3[]=REF_ENTITY_TYPE_ID&param3[]=dataType.integer&param3[]=30&
// param4[]=FREQUENCIES&param4[]=dataType.string&param4[]=5ANS,3ANS,12MOIS,3MOIS,1MOIS,DEB_ANNEE,OUVERTURE"
// function createDataExtractParam(name, type, value, index) {
//   return
// }
function formatDataExtractDate(date) {
  // return '30/04/2021'
  return new Date(date).format('DD/MM/YYYY')
}
export function getRiskIndicatorsParams({ portfShareCode, date, lgCode, periods }) {
  const param0 = ['SHARE_CODE', 'dataType.string', normalizePortfShareCode(portfShareCode)]
  const param1 = ['CALCUL_DATE', 'dataType.date', formatDataExtractDate(date)]
  const param2 = ['FREQUENCIES', 'dataType.string', periods.map(period => mappingPeriods[period]).join(',')]
  return {
    templateCode: 'DICE_RISK_INDICATORS',
    lgCode: 'fr',
    param0,
    param1,
    param2,
  }
}
// getRunDataExtractTemplate?templateCode=Fund_Holdings_Allianz%20GI_3038&
// lgCode=en&
// param0=Valuation%20Date&param0=dataType.date&param0=30%2F04%2F2021&
// param1=Portfolio%20Code&param1=dataType.string&param1=IFM_111
export function getHoldingsParams({ portfCode, date, lgCode }) {
  const templateCode = 'DICE_HOLDINGS_TOP10'
  const param0 = ['Valuation Date', 'dataType.date', formatDataExtractDate(date)]
  const param1 = ['Portfolio Code', 'dataType.string', normalizePortfCode(portfCode)]
  return {
    templateCode,
    lgCode: lgCode || 'fr',
    param0,
    param1,
  }
}

// templateCode=Transac_1_Mois&lgCode=en&
// param0[]=PORTF_CODE&param0[]=dataType.string&param0[]=IFM_3039&
// param1[]=TRANSACTION_DATE&param1[]=dataType.date&param1[]=14%2F12%2F2021
export function getTransactionsParams({ portfCode, date, lgCode }) {
  const templateCode = 'Transac_1_Mois'
  const param0 = ['PORTF_CODE', 'dataType.string', normalizePortfCode(portfCode)]
  const param1 = ['TRANSACTION_DATE', 'dataType.date', formatDataExtractDate(date)]
  return {
    templateCode,
    lgCode: lgCode || 'fr',
    param0,
    param1,
  }
}
// TODO Non euro risk free
// ,{"Code":"7975","Nom":"EONIA-ESTR","Devise (Code ISO)":"EUR","Date":"2021-12-02","Cotation":172.75681731664218,"Taux":-0.0057799999999999995}
export const riskFreeStructure = {
  date: {
    key: 'Date',
    type: 'date',
  },
  value: {
    key: 'Cotation',
    type: 'number',
  },
}
// templateCode=DICE_TSR&lgCode=fr&
// param0=Instrument%20Data%20Source&param0=dataType.string&param0=APIDATA&
// param1=Index%20List&param1=dataType.string&param1=7975&
// param2=Date&param2=dataType.date&param2=31%2F12%2F1993"

export function getRiskFreeParams() {
  const templateCode = 'DICE_TSR'
  const param0 = ['Instrument Data Source', 'dataType.string', 'APIDATA']
  const param1 = ['Index List', 'dataType.string', 7975]
  const param2 = ['Date', 'dataType.date', formatDataExtractDate('1993-12-31')]
  return {
    templateCode,
    lgCode: 'fr',
    param0,
    param1,
    param2,
  }
}
