export function cellFormatter(formatter = val => format('.2f')(val * 100)) {
  return d => {
    if (!d || d === '-') return '-'
    const className = d >= 0 ? 'positive' : 'negative'
    return `
      <div class="flex flex-row items-center justify-center gap-1 perf-cell ${className}">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 chevron" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
        </svg>
        <span>${formatter(d)}</span>
      </div>
    `
  }
}

export function titleize(sentence) {
  if (!sentence.split) return sentence
  const _titleizeWord = function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }
  const result = []
  sentence.split(' ').forEach(function (w) {
    result.push(_titleizeWord(w))
  })
  return result.join(' ')
}

function _getVar(cssVar) {
  const styleSheet = getComputedStyle(document.body)
  return styleSheet.getPropertyValue(cssVar)
}
export function getCssVar(cssVar) {
  if (cssVar.startsWith('var(--')) return _getVar(cssVar.replace('var(--', '--').replace(')', ''))
  if (cssVar.startsWith('--')) return _getVar(cssVar)
  return cssVar
}

export function computeTextColor(r, g, b, lightColor, darkColor) {
  const uicolors = [r / 255, g / 255, b / 255]
  const c = uicolors.map(col => {
    if (col <= 0.03928) {
      return col / 12.92
    }
    return Math.pow((col + 0.055) / 1.055, 2.4)
  })
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
  return L > 0.179 ? darkColor : lightColor
}

export function filterPeriods(periods, dates) {
  let newPeriods = periods
  const years = (new Date(dates.last()) - new Date(dates.first())) / (365 * 3600 * 24 * 1000)
  if (periods.includes('5y') && years < 5) newPeriods = newPeriods.filter(d => d !== '5y' && d !== 'inception').concat('inception')
  if (periods.includes('3y') && years < 3) newPeriods = newPeriods.filter(d => d !== '3y')
  return newPeriods
}

export function findNearestDate(date, dates) {
  const stringDate = date.format('YYYY-MM-DD')
  const nearestDate = dates.reverse().find(d => d <= stringDate)
  if (!nearestDate) return new Date(dates[0])
  return new Date(nearestDate)
}
