<template lang="pug">
.min-h-full.w-full.bg-white.pt-2
  wait-for(:wait="riskTableData")
    .container.p-6
      h2.mb-2 {{ t.risk_measures }}
      dice-table(:columns="riskTableColumns" :data="riskTableData")
</template>
<script>
import { getRiskIndicators } from '@/composables/bone'
import { useEmbedComponent } from '@/composables/embed.js'
import { ref, unref } from 'vue'

// function _getKpiValue(name, period, navKpis, performanceKpis, riskIndicatorsKpis) {
//   if (tempKpis[name]) return tempKpis[name]
//   if (navKpiNames.includes(name)) return navKpis[name]
//   if (performanceKpiNames.includes(name)) return getPeriodKpi(name, period, performanceKpis)
//   if (riskKpiNames.includes(name)) return getPeriodKpi(name, period, riskIndicatorsKpis)
// }
// function getKpi(kpi, navKpis, performanceKpis, riskIndicatorsKpis) {
//   const [name, period] = kpi.split(':')
//   const value = _getKpiValue(name, period, navKpis, performanceKpis, riskIndicatorsKpis)
//   const formattedValue = formats[name] ? format(formats[name])(value) : value
//   console.log(name, value, formats[name], formattedValue)
//   return {
//     key: kpi,
//     value: formattedValue,
//   }
// }
// function getPeriodKpi(name, period, data) {
//   if (!period) return 'Needs period'
//   return data[period] && data[period][name]
// }

const formats = {
  volatilityFund: '.2%',
  volatilityBenchmark: '.2%',
  alpha: '.2%',
}

export default {
  setup() {
    const config = useEmbedComponent('kpi.riskIndicators')
    const { periods, kpis, watcher } = config
    const riskIndicators = ref()
    const riskTableColumns = [{ key: 'name' }].concat(
      periods.value.map(d => ({
        key: d,
        name: $root.t[d],
      })),
    )
    const riskTableData = ref()
    // const riskTableMetadata = {
    //   borderLeftColor: (row, idx) => `var(--cat${idx + 1})`,
    //   // isRowActive: (row, idx) => this.hover === idx,
    // }
    async function getData() {
      const data = await getRiskIndicators('')
      riskTableData.value = kpis.value.map(name => {
        const row = { name }
        periods.value.map(period => {
          row[period] = format(formats[name] || '.2f')(data[period][name])
        })
        return row
      })
    }

    getData()
    watcher(getData)
    return {
      riskIndicators,
      riskTableColumns,
      riskTableData,
      ...config,
    }
  },
}
</script>
