<template lang="pug">
widget-wrapper(:wait="holdings" :portfCode="portfCode" :portfShareCode="portfShareCode")
  .holdings-widget.flex.flex-col.p-4.items-center.justify-center.h-full.flex-1(v-if="holdings === 'no_data_embargo'") {{ $root.t.no_data_embargo_disclaimer }}
  .holdings-widget.flex.flex-col.p-4(v-else)
    .flex.flex-row.items-center.justify-between.mb-4
      h1 {{ $root.t.holdings }}
      .flex.flex-row.items-center.gap-8
        date-picker.left(v-model="activeDate" :dates="calendarDates" @update:modelValue="ev => persistDate(portfCode, ev)")
          template(#default="{ date, showModal }")
            .flex.flex-row.items-center.gap-2.cursor-pointer.py-1.px-2.border.border-gray-200.rounded-md(style="height: 38px;" @click="showModal")
              span.font-bold {{ date.format('day, mon, year', $root.lang) }}
              svg-icon.text-gray-400.fill-current(name="ic_date_range")
    .text-lg.mb-4(v-if="holdings !== 'no_show_embargo'") {{ $root.t['Number of lines'] }}: {{ linesFund }}   
    .flex.flex-col.items-center.justify-center.flex-1(v-if="state === 'loading'")
        loader
    .flex.flex-row.flex-wrap.gap-4(v-else)
      .flex.flex-col.max-w-xl(class="lg:max-w-xl xl:max-w-2xl 2xl:max-w-3xl" style="min-width: 660px;" v-if="top10Data")
        h2.mb-2 {{ top10Title }}
        dice-table.holdings-widget.table.small.max-w-xl(class="lg:max-w-xl xl:max-w-2xl 2xl:max-w-3xl" :columns="top10Columns" :data="top10Data")
          template(#cell-isin="{ col, row, idx, cell }")
            .text-xs.text-gray-400.font-normal(v-html="cell")
          .flex.flex-row.justify-end.items-center.font-bold.text-lg.gap-2.py-1.px-2
            div Total:
            div {{ format('.2%')(top10Total) }}
      .flex.flex-col.items-center.justify-center.flex-1.p-6(v-else-if="holdings === 'no_show_embargo'" style="min-width: 500px; max-width: 620px;") {{ $root.t.no_show_embargo_disclaimer }}
      .flex.flex-col.items-center.justify-center.flex-1(v-else)
        .mb-2 Loading holdings
        loader
      //- .flex.flex-col.items-center.justify-center.flex-1.p-6(v-if="noTransactionValo === true" style="min-width: 500px; max-width: 620px;") {{ $root.t.no_transactions_disclaimer_valo }}
      .flex.flex-col.items-center.justify-center.flex-1.p-6(v-if="isThematics" style="min-width: 500px; max-width: 620px;") {{ $root.t.no_show_embargo_disclaimer }}
      .flex.flex-col.items-center.justify-center.flex-1.p-6(v-else-if="!top5Purchase.length && !top5Sale.length" style="min-width: 500px; max-width: 620px;") {{ $root.t.no_transactions_disclaimer }}
      .flex.flex-col(v-else-if="top5Purchase.length || top5Sale.length")
        h2.mb-2 {{ $root.t['Top 5 purchases']}}
        dice-table.table.small.max-w-xl(class="lg:max-w-xl xl:max-w-2xl 2xl:max-w-3xl" :columns="transactionsColumns" :data="top5Purchase" v-if="top5Purchase.length")
          template(#cell-isin="{ col, row, idx, cell }")
            .text-xs.text-gray-400.font-normal( v-html="cell")
        div(v-else) {{ $root.t.no_purchases }}
        h2.my-2 {{ $root.t['Top 5 sales'] }}
        dice-table.holdings-widget.table.small.max-w-xl(class="lg:max-w-xl xl:max-w-2xl 2xl:max-w-3xl" :columns="transactionsColumns" :data="top5Sale" v-if="top5Sale.length")
          template(#cell-isin="{ col, row, idx, cell }")
            .text-xs.text-gray-400.font-normal(v-html="cell")
        div(v-else) {{ $root.t.no_sales }}
      .flex.flex-col.items-center.justify-center.flex-1(v-else-if="state === 'loading_transactions'")
        .mb-2 Loading transactions
        loader
      .flex.flex-col.items-center.justify-center.flex-1.p-6(v-else style="min-width: 500px; max-width: 620px;") {{ $root.t.no_transactions_disclaimer }}
    .chart-disclaimer.flex.flex-col
      div(v-if="masterPortfCode") {{ $root.t.master_fund_disclaimer }} ({{ masterName }})
      div {{ $root.t.holdings_disclaimer }}
      div {{ $root.t.transparency_disclaimer }}
    //- div {{ holdings }}
</template>
<script>
import { computed, ref, watch, reactive } from 'vue'
import { useEmbedComponent, useStoredValues } from '@/composables/embed.js'
import { getTransactions, getHoldings, getFundMetadata, getAllocationDates } from '@/composables/api'
import { findNearestDate } from '@/composables/utils'

export default {
  setup() {
    const state = ref('init')
    const config = useEmbedComponent('widgets.holdings')
    const { portfCode, portfShareCode, watcher } = config
    const activeDate = ref()
    const calendarDates = ref()
    // const embargoDays = ref()

    const holdings = ref()
    const activeTab = ref('portfolio')
    const linesFund = ref()
    const top10Fund = ref()
    const top10Benchmark = ref()
    const top5Delta = ref()
    const bottom5Delta = ref()
    const top5Purchase = ref([])
    const top5Sale = ref([])
    const transactions = ref()
    const masterPortfCode = ref()
    const masterName = ref()
    const valuer = ref()
    const noTransactionValo = ref()
    const isThematics = ref()
    let lastDate

    const { getDate, persistDate } = useStoredValues()
    async function init() {
      calendarDates.value = await getAllocationDates(portfCode.value)
      const fundMetadata = await getFundMetadata(portfCode.value, portfShareCode.value)
      masterPortfCode.value = fundMetadata.masterPortfCode
      masterName.value = fundMetadata.masterName
      // debugger
      isThematics.value = fundMetadata.sdg === 'THEMATICS ASSET MANAGEMENT'
      const embargoDays = isThematics.value ? 30 : fundMetadata.embargo
      if (!embargoDays) {
        state.value = 'no_data_embargo'
        holdings.value = 'no_data_embargo'
        return
      }
      const embargoDate = new Date().minus(embargoDays + ' days')
      lastDate = findNearestDate(embargoDate, calendarDates.value)
      const startingDate = getDate(portfCode.value)
      const date = startingDate && startingDate < embargoDate ? startingDate : embargoDate
      calendarDates.value = calendarDates.value.filter(d => new Date(d) <= embargoDate)
      valuer.value = fundMetadata.valuer
      activeDate.value = findNearestDate(date, calendarDates.value)
    }
    const actualPortfCode = computed(() => {
      return masterPortfCode.value || portfCode.value
    })
    async function getData() {
      if (!portfCode.value) return
      if (holdings.value === 'no_data_embargo') return
      state.value = 'loading'
      const holdingsData = await getHoldings(actualPortfCode.value, activeDate.value, $root.lang)
      if (holdingsData && !Object.keys(holdingsData).length) {
        state.value = 'ready'
        holdings.value = 'no_data_embargo'
        return
      }
      if (holdingsData && holdingsData.error) {
        holdings.value = holdingsData.error
        // drilldownTable.value = null
        return
      }
      linesFund.value = holdingsData.linesFund
      top10Fund.value = holdingsData.top10Fund
      top10Benchmark.value = holdingsData.top10Benchmark
      top5Delta.value = holdingsData.top5Delta
      bottom5Delta.value = holdingsData.bottom5Delta
      holdings.value = holdingsData
      if (valuer.value === 'BROWN BROTHERS HARRIMAN COMPTA') {
        noTransactionValo.value = true
        state.value = 'ready'
        return
      }
      state.value = 'loading_transactions'
      const transactionsData = await getTransactions(actualPortfCode.value, activeDate.value, $root.lang)
      top5Purchase.value = transactionsData.top5Purchase
      top5Sale.value = transactionsData.top5Sale
      transactions.value = transactionsData
      state.value = 'ready'
    }
    init()
    watch(activeDate, getData)
    const top10Data = computed(() => {
      if (activeTab.value === 'benchmark') return top10Benchmark.value
      return top10Fund.value
    })
    const top10Total = computed(() => {
      if (!top10Data.value) return
      const key = activeTab.value === 'benchmark' ? 'bmkWeight' : 'ptfWeight'
      return top10Data.value.sum(key)
    })
    const top10Columns = computed(() => {
      // const key = activeTab.value === 'benchmark' ? 'bmkWeight' : 'weight'
      return [
        { key: 'name', name: 'name', size: 2.5 },
        { key: 'isin', name: 'ISIN', size: 1 },
        { key: 'assetClass', name: 'assetClass', size: 2.1, format: d => $root.t[d] || d },
        { key: 'weight', name: 'weight', format: format('.2%'), size: 0.7 },
      ]
    })
    const deltaColumns = [
      { key: 'name', name: 'name' },
      { key: 'isin', name: 'ISIN' },
      { key: 'assetClass', name: 'assetClass', size: 2 },
      { key: 'deltaWeight', name: 'weight', format: format('.2%') },
    ]
    const formatAmount = value => {
      const fmtString = format(',.0f€', $root.lang)(value)
      if ($root.lang === 'fr') return fmtString
      return fmtString.replace(/\,/g, ' ')
    }
    const transactionsColumns = [
      { key: 'name', name: 'name', size: 2.2 },
      { key: 'assetClass', name: 'assetClass', size: 2 },
      { key: 'amount', name: 'amount', format: formatAmount },
    ]
    const top10Title = computed(() => {
      if (activeTab.value === 'delta') return `${$root.t['Top 5 lines']} ${$root.t['delta_fund_benchmark']}`
      if (isThematics.value === true) return `${$root.t['Top 5 lines']} ${$root.t[activeTab.value]}`
      return `${$root.t['Top 10 lines']} ${$root.t[activeTab.value]}`
    })
    const downloadItems = computed(() => {
      return [
        { name: top10Title.value, data: top10Data.value },
        { name: $root.t['Top 5 purchases'], data: top5Purchase.value },
        { name: $root.t['Top 5 sales'], data: top5Sale.value },
      ]
    })
    return {
      holdings,
      portfCode,
      portfShareCode,
      persistDate,
      linesFund,
      top10Fund,
      top10Benchmark,
      top5Delta,
      bottom5Delta,
      top10Columns,
      top10Data,
      top10Total,
      top10Title,
      deltaColumns,
      activeTab,
      activeDate,
      transactions,
      transactionsColumns,
      top5Purchase,
      top5Sale,
      state,
      downloadItems,
      calendarDates,
      masterPortfCode,
      masterName,
      actualPortfCode,
      valuer,
      noTransactionValo,
      isThematics,
    }
  },
}
</script>
<style>
/* .holdings-widget :not(thead) tr {
  @apply border-l border-r;
} */
</style>
