<template lang="pug">
h1.mb-6 {{ group.titleize() }} components
ul.list-disc
  li(v-for="component, name in components")
    router-link(:to="`/docs/embed/${group}/${name}`") {{ component.name }}
</template>
<script>
import { useRoute } from '@100-m/hauru'
import { computed } from 'vue'

export default {
  setup() {
    const route = useRoute()
    const group = computed(() => route.params.group)
    const components = computed(() => $root.config.components[group.value])
    return {
      route,
      components,
      group
    }
  },
}
</script>
<style>
</style>