import './raw.js'

// Sugar equivalent functions
Object.extend.format = (fn, ...args) => {
  if (fn === Date.format.fn && typeof args[2] === 'string' && args[2].includes('_')) args[2] = args[2].replace('_', '-')
  if (['Invalid Date', 'NaN', 'null', 'undefined'].includes('' + args[0])) return '-'
  return fn(...args)
}
Object.extend.group = (fn, ...args) => {
  if (args[1] instanceof Array)
    return args[0].reduce((acc, v) => {
      args[1].reduce(
        (a, p, i, ds) => (a[Object.access(v, p)] = i === ds.length - 1 ? (a[Object.access(v, p)] || []).concat([v]) : a[Object.access(v, p)] || {}),
        acc,
      )
      return acc
    }, {})
  return fn(...args)
}
Object.v = Object.values
Object.find = Object.findIndex // TO REMOVE
Object.extend.natives = ['Object.keys', 'Object.v', 'Array.map', 'Array.reduce', 'Array.filter', 'Array.find', 'Array.findIndex', 'Array.sort', 'Array.reverse']
if (typeof window !== 'undefined') window.eq = Object.equal
if (typeof window !== 'undefined') window.access = Object.access
Date.iso = d => d.toISOString()
Date.getDayNumber = date => Math.floor((new Date(date) - new Date('1970-01-05')) / (1000 * 60 * 60 * 24))
Number.upto = (a, b) =>
  Array(Math.abs(b - a || 0) + 1)
    .fill()
    .map((_, i) => a + Math.sign(b - a) * i)

String.titleize = str => String.format(str, 'title')
String.camelize = str => String.format(str, 'pascal')
String.escapeHTML = str => {
  const div = document.createElement('div')
  div.innerText = str
  return div.innerHTML
}
String.unescapeHTML = str => new DOMParser().parseFromString(str, 'text/html').documentElement.textContent
Array.at = (arr, at) => at.map(i => arr[i])
Array.min = (arr, fn = x => x) => arr.sort(fn).first()
Array.max = (arr, fn = x => x) => arr.sort(fn).last()
Array.sum = (arr, fn = x => x) => arr.map(fn).reduce((acc, v) => acc + +v, 0)
Array.toggle = (arr, item, items = [].concat(item)) => arr.filter(v => !items.includes(v)).concat(items.filter(v => !arr.includes(v)))
Array.first = arr => arr[0]
Array.last = arr => arr.slice(-1)[0]
Array.shuffle = (arr, r) => ((arr = arr.slice()), arr.forEach((v, i) => ((r = Math.floor(Math.random() * i)), ([arr[i], arr[r]] = [arr[r], arr[i]]))), arr) // eslint-disable-line
Array.transpose = arr => arr[0].map((col, i) => arr.map(row => row[i]))

// Utility functions
String.hex = str => color2hex(str)
String.hsl = str => color2hsl(str)
String.rgb = str => color2rgb(str)
String.s = str => {
  try {
    return $root.config.translation[$root.lang][str].split('|').last()
  } catch (e) {
    return str.slice(0, 6)
  }
}
String.md5 = str => md5(str)
String.avatar = str => 'https://s.gravatar.com/avatar/' + md5(str) + '?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2F' + str.slice(0, 2) + '.png'
String.download = (str, filename = 'download.txt') => {
  const blob = new Blob(['\ufeff', str])
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('style', 'display: none;')
  link.setAttribute('download', filename)
  document.body.appendChild(link) // Required for FF
  link.click()
  document.body.removeChild(link)
}
Array.toCSV = (arr, lang, sep = lang === 'fr' ? ';' : ',') => {
  const escape = s => {
    if (type(s) === 'number' && lang === 'fr') return ('' + s).replace('.', ',')
    if (type(s) === 'string' && s.indexOf(sep) > -1) return '"' + s + '"'
    return s
  }
  const keys = (Array.isArray(arr[0]) ? arr[0] : arr[0].__.keys()).map(d => escape($root.t[d] || d))
  const values = (Array.isArray(arr[0]) ? arr.slice(1) : arr).map(o => o.__.v().map(d => escape(d)))
  return [keys]
    .concat(values)
    .map(d => d.join(sep))
    .join('\n')
}
Array.dlCSV = (arr, lang, filename = 'table.csv') => arr.toCSV(lang).download(filename)
Array.chunk = (arr, size) =>
  Array(Math.ceil(arr.length / size))
    .fill()
    .map((_, i) => arr.slice(i * size, i * size + size))

// Analytics functions
// https://mathjs.org/docs/reference/functions.html
Array.std = (arr, normalization = 1) => {
  // normalisation: 0 = Pearson, 1 = Standard
  const mean = arr.mean()
  const variance = arr.map(d => (d - mean) ** 2)
  return Math.sqrt(variance.sum() / (variance.length - normalization))
}
Array.performance = (arr, metric) => {
  const method = ($root.config.analytics && $root.config.analytics.performance_log) || 'no-log'
  if (method === 'log') return Math.log(+arr.last()[metric] / +arr.first()[metric])
  return +arr.last()[metric] / +arr.first()[metric] - 1
}
Array.performance_annualized = (arr, metric) => {
  const number_of_days = (new Date(arr.last().date) - new Date(arr.first().date)) / (1000 * 60 * 60 * 24)
  if (new Date(arr.last().date).minus('1 year') < new Date(arr.first().date)) return null
  return Math.pow(+arr.last()[metric] / +arr.first()[metric], 365 / number_of_days) - 1
}

// const n = { daily: 1, monthly: 12, weekly: 52, annualy: 252 }[period]
Array.weekly_data = (arr, period, day = 5) => {
  // group by day number, take last day of the week.
  const periods = { '1y': 52, '3y': 156, '5y': 260 }
  const last_dow = arr.find(d => new Date(d.date).getDay() === day)
  if (!last_dow) return 0
  const d0 = new Date(last_dow.date)
  const d1 = new Date(arr.last().date)
  const dates = arr.__.group('date').__.map(g => g[0])
  return Array(Math.ceil((d1 - d0 + 1) / 604800000)) // (1000 * 60 * 60 * 24 * 7)
    .fill()
    .map((_, i) => {
      const date = d0.plus(i * 7 + 'days').format()
      return (
        dates[date] || {
          ...dates[
            dates
              .__.keys()
              .reverse()
              .find(k => k <= date)
          ],
          date,
        }
      )
    })
    .slice(-(periods[period] + 1))
}

// const n = { daily: 1, monthly: 12, weekly: 52, annualy: 252 }[period]
Array.volatility = (arr, metric, period) => {
  const filtered_arr = arr.weekly_data(period)
  if (!filtered_arr) return 0
  const method = ($root.config.analytics && $root.config.analytics.volatility_log) || 'log'
  return filtered_arr.returns(metric, method).std(1) * Math.sqrt(52) || 0
}

Array.returns = (arr, metric, method) => {
  if (!arr) return []
  const methods = {
    log: (d, i) => Math.log(d[metric] / arr[i - 1][metric]),
    'no-log': (d, i) => d[metric] / arr[i - 1][metric] - 1,
  }
  return arr.map((d, i) => (i === 0 ? null : methods[method](d, i))).filter(d => d !== null)
}

Array.percentile = (arr, k) => {
  // from formula.js
  arr = arr.sort()
  const n = arr.length
  const l = k * (n + 1) - 1
  const fl = Math.floor(l)
  return l === fl ? arr[l] : arr[fl] + (l - fl) * (arr[fl + 1] - arr[fl])
}

Array.var_X = (arr, metric, period, X = 99) => {
  const x = Math.round((1 - X / 100) * 100) / 100
  const method = ($root.config.analytics && $root.config.analytics.volatility_log) || 'log'
  return arr.returns(metric, method).percentile(x)
}

Array.var_X_Y_days = (arr, metric, period, X = 99, days = 7) => {
  return arr.var_X(metric, period, X) * Math.sqrt(+days)
}

Array.tracking_error = (arr, period, metric = 'benchmark') => {
  // TO DO : refacto with returns
  const filtered_arr = arr.weekly_data(period)
  if (!filtered_arr) return 0
  const method = ($root.config.analytics && $root.config.analytics.tracking_error_log) || 'log'
  const methods = {
    log: (d, i) => Math.log(d.fund / filtered_arr[i - 1].fund) - Math.log(d[metric] / filtered_arr[i - 1][metric]),
    'no-log': (d, i) => d.fund / filtered_arr[i - 1].fund - d[metric] / filtered_arr[i - 1][metric],
  }
  return (
    filtered_arr
      .map((d, i) => (i === 0 ? null : methods[method](d, i)))
      .filter(d => d !== null)
      .std(1) * Math.sqrt(52) || 0
  )
}

Array.sharpe_ratio_weekly = (arr, period) => {
  const weekly_data = arr.weekly_data(period)
  if (!weekly_data) return 0
  const annualized_perf_fund = weekly_data.performance_annualized('fund') || weekly_data.performance('fund')
  const annualized_perf_rf = weekly_data.performance_annualized('risk_free') || weekly_data.performance('risk_free')
  const volatility = weekly_data.volatility('fund')
  return (annualized_perf_fund - annualized_perf_rf) / volatility
}

Array.sharpe_ratio = (arr, period) => {
  if ($root.config.analytics && $root.config.analytics.sharpe_ratio === 'weekly') return arr.sharpe_ratio_weekly(period)
  const volatility = arr.volatility('fund')
  const perf_arr = arr.filter(d => new Date(arr[0].date).plus('7day').format() <= d.date)
  if (!perf_arr.length) return 0

  const annualized_perf_fund = perf_arr.performance_annualized('fund') || perf_arr.performance('fund')
  const annualized_perf_rf = perf_arr.performance_annualized('risk_free') || perf_arr.performance('risk_free')
  const real_years = Math.round(((new Date(perf_arr.last().date) - new Date(perf_arr.first().date)) / (365 * 24 * 60 * 60 * 1000)) * 100) / 100
  const sharpe_ratio = real_years >= 1 ? (annualized_perf_fund - annualized_perf_rf) / volatility : '-'
  return sharpe_ratio
}

Array.information_ratio = (arr, period, metric = 'benchmark') => {
  const tracking_error = arr.tracking_error()
  const annualized_perf_fund = arr.performance_annualized('fund') || arr.performance('fund')
  const annualized_perf_benchmark = arr.performance_annualized(metric) || arr.performance(metric)
  const real_years = Math.round(((new Date(arr.last().date) - new Date(arr.first().date)) / (365 * 24 * 60 * 60 * 1000)) * 100) / 100
  const information_ratio = real_years >= 1 ? (annualized_perf_fund - annualized_perf_benchmark) / tracking_error : '-'
  return information_ratio
}

Array.drawdown_details = (series, metric) => {
  const { argmin, argmax, max_drawdown } = series.max_drawdown(metric)
  const begin = (series[argmax] || {}).date
  const end = (series[argmin] || {}).date
  const begin_nav = (series[argmax] || {})[metric]
  const recovery = (
    series
      .filter(d => d.date > end)
      .filter(d => d[metric] >= begin_nav)
      .first() || {}
  ).date
  const duration = recovery ? parseInt((new Date(recovery) - new Date(begin)) / 86400000) : '-'
  return {
    drawdown: max_drawdown,
    end: end,
    end_nav: (series[argmin] || {})[metric],
    begin: begin,
    begin_nav: begin_nav,
    recovery: recovery || 'not_achieved',
    duration: duration,
  }
}

Array.alpha = (arr, period, metric = 'benchmark') => {
  const weekly_data = arr.weekly_data(period)
  if (!weekly_data) return 0
  const beta = arr.beta(period)
  const perf_fund = weekly_data.performance_annualized('fund') || weekly_data.performance('fund')
  const perf_benchmark = weekly_data.performance_annualized(metric) || weekly_data.performance(metric)
  const perf_rf = weekly_data.performance_annualized('risk_free') || weekly_data.performance('risk_free')
  return perf_fund - (perf_rf + beta * (perf_benchmark - perf_rf))
}

Array.beta = (arr, period, metric = 'benchmark') => {
  const weekly_data = arr.weekly_data(period)
  const fund_data = weekly_data.map((d, i) => (i === 0 ? null : d.fund / weekly_data[i - 1].fund - 1)).filter(d => d !== null)
  const benchmark_data = weekly_data.map((d, i) => (i === 0 ? null : d[metric] / weekly_data[i - 1][metric] - 1)).filter(d => d !== null)
  return fund_data.covariance(benchmark_data) / benchmark_data.variance()
}

Array.max_drawdown_value = (series, metric) => {
  return series.drawdown_details(metric).drawdown
}

Array.maxgain_details = (series, metric) => {
  const { argmin, argmax, max_gain } = series.max_gain(metric)
  const begin = (series[argmin] || {}).date
  const end = (series[argmax] || {}).date
  const begin_nav = (series[argmin] || {})[metric]
  const recovery = (
    series
      .filter(d => d.date > end)
      .filter(d => d[metric] >= begin_nav)
      .first() || {}
  ).date
  const duration = recovery ? (new Date(recovery) - new Date(begin)) / 86400000 : '-'
  return {
    maxgain: max_gain,
    end: end,
    end_nav: (series[argmin] || {})[metric],
    begin: begin,
    begin_nav: begin_nav,
    recovery: recovery || '-',
    duration: duration,
  }
}

Array.max_drawdown = (series, metric) => {
  const values = series.map(metric)
  let max_drawdown = 0.0
  let max = values.first()
  let min = values.first()
  let argmax = 0
  let argmin = 0
  values.map((d, i) => {
    if (d >= max) {
      max = min = d
    }
    if (d < min) {
      min = d
      if (min / max - 1 < max_drawdown) {
        max_drawdown = min / max - 1
        argmin = i
        argmax = values.findIndex(max)
      }
    }
    return null
  })
  return { argmin, argmax, max_drawdown }
}

Array.max_gain = (series, metric) => {
  const values = series.map(metric)
  let max_gain = 0.0
  let max = values.first()
  let min = values.first()
  let argmax = 0
  let argmin = 0
  values.map((d, i) => {
    if (d <= min) {
      max = min = d
    }
    if (d > max) {
      max = d
      if (max / min - 1 > max_gain) {
        max_gain = max / min - 1
        argmax = i
        argmin = values.findIndex(min)
      }
    }
    return null
  })
  return { argmin, argmax, max_gain }
}

Array.std_var = series => {
  const mean = series.mean()
  const deviation = series.map(d => (d - mean) ** 2)
  return Math.sqrt(d3.sum(deviation) / deviation.length)
}

Array.variance = series => {
  const mean = series.mean()
  const deviation = series.map(d => (d - mean) ** 2)
  return d3.sum(deviation) / (deviation.length - 1)
}

Array.covariance = (serie1, serie2) => {
  if (serie1.length !== serie2.length) return null
  const means1 = serie1.mean()
  const means2 = serie2.mean()
  const deviation = serie1.map((d, k) => (d - means1) * (serie2[k] - means2))
  return d3.sum(deviation) / (deviation.length - 1) // NB: covariance.p deviation.length or covariance.s deviation.length - 1
}

Object.extend(true)
function color2hsl(color) {
  const c = d3.hsl(color)
  let a = c.opacity
  a = isNaN(a) ? 1 : Math.max(0, Math.min(1, a))
  return (
    (a === 1 ? 'hsl(' : 'hsla(') +
    parseInt(c.h || 0) +
    ', ' +
    parseInt((c.s || 0) * 100) +
    '%, ' +
    parseInt((c.l || 0) * 100) +
    '%' +
    (a === 1 ? ')' : ', ' + a + ')')
  )
}
function _hex(value) {
  value = Math.max(0, Math.min(255, Math.round(value) || 0))
  return (value < 16 ? '0' : '') + value.toString(16)
}
function color2hex(color) {
  const c = d3.color(color).rgb()
  return '#' + _hex(c.r) + _hex(c.g) + _hex(c.b)
}
function color2rgb(color) {
  const c = d3.color(color).rgb()
  let a = c.opacity
  a = isNaN(a) ? 1 : Math.max(0, Math.min(1, a))
  return (
    (a === 1 ? 'rgb(' : 'rgba(') +
    Math.max(0, Math.min(255, Math.round(c.r) || 0)) +
    ', ' +
    Math.max(0, Math.min(255, Math.round(c.g) || 0)) +
    ', ' +
    Math.max(0, Math.min(255, Math.round(c.b) || 0)) +
    (a === 1 ? ')' : ', ' + a + ')')
  )
}
function color(str, to) {
  if (to === 'hex') return color2hex(str)
  if (to === 'rgb') return color2rgb(str)
  if (to === 'hsl') return color2hsl(str)
  return str
}

// MD5 Utils
function md5cycle(x, k) {
  let a = x[0]
  let b = x[1]
  let c = x[2]
  let d = x[3]

  a = ff(a, b, c, d, k[0], 7, -680876936)
  d = ff(d, a, b, c, k[1], 12, -389564586)
  c = ff(c, d, a, b, k[2], 17, 606105819)
  b = ff(b, c, d, a, k[3], 22, -1044525330)
  a = ff(a, b, c, d, k[4], 7, -176418897)
  d = ff(d, a, b, c, k[5], 12, 1200080426)
  c = ff(c, d, a, b, k[6], 17, -1473231341)
  b = ff(b, c, d, a, k[7], 22, -45705983)
  a = ff(a, b, c, d, k[8], 7, 1770035416)
  d = ff(d, a, b, c, k[9], 12, -1958414417)
  c = ff(c, d, a, b, k[10], 17, -42063)
  b = ff(b, c, d, a, k[11], 22, -1990404162)
  a = ff(a, b, c, d, k[12], 7, 1804603682)
  d = ff(d, a, b, c, k[13], 12, -40341101)
  c = ff(c, d, a, b, k[14], 17, -1502002290)
  b = ff(b, c, d, a, k[15], 22, 1236535329)

  a = gg(a, b, c, d, k[1], 5, -165796510)
  d = gg(d, a, b, c, k[6], 9, -1069501632)
  c = gg(c, d, a, b, k[11], 14, 643717713)
  b = gg(b, c, d, a, k[0], 20, -373897302)
  a = gg(a, b, c, d, k[5], 5, -701558691)
  d = gg(d, a, b, c, k[10], 9, 38016083)
  c = gg(c, d, a, b, k[15], 14, -660478335)
  b = gg(b, c, d, a, k[4], 20, -405537848)
  a = gg(a, b, c, d, k[9], 5, 568446438)
  d = gg(d, a, b, c, k[14], 9, -1019803690)
  c = gg(c, d, a, b, k[3], 14, -187363961)
  b = gg(b, c, d, a, k[8], 20, 1163531501)
  a = gg(a, b, c, d, k[13], 5, -1444681467)
  d = gg(d, a, b, c, k[2], 9, -51403784)
  c = gg(c, d, a, b, k[7], 14, 1735328473)
  b = gg(b, c, d, a, k[12], 20, -1926607734)

  a = hh(a, b, c, d, k[5], 4, -378558)
  d = hh(d, a, b, c, k[8], 11, -2022574463)
  c = hh(c, d, a, b, k[11], 16, 1839030562)
  b = hh(b, c, d, a, k[14], 23, -35309556)
  a = hh(a, b, c, d, k[1], 4, -1530992060)
  d = hh(d, a, b, c, k[4], 11, 1272893353)
  c = hh(c, d, a, b, k[7], 16, -155497632)
  b = hh(b, c, d, a, k[10], 23, -1094730640)
  a = hh(a, b, c, d, k[13], 4, 681279174)
  d = hh(d, a, b, c, k[0], 11, -358537222)
  c = hh(c, d, a, b, k[3], 16, -722521979)
  b = hh(b, c, d, a, k[6], 23, 76029189)
  a = hh(a, b, c, d, k[9], 4, -640364487)
  d = hh(d, a, b, c, k[12], 11, -421815835)
  c = hh(c, d, a, b, k[15], 16, 530742520)
  b = hh(b, c, d, a, k[2], 23, -995338651)

  a = ii(a, b, c, d, k[0], 6, -198630844)
  d = ii(d, a, b, c, k[7], 10, 1126891415)
  c = ii(c, d, a, b, k[14], 15, -1416354905)
  b = ii(b, c, d, a, k[5], 21, -57434055)
  a = ii(a, b, c, d, k[12], 6, 1700485571)
  d = ii(d, a, b, c, k[3], 10, -1894986606)
  c = ii(c, d, a, b, k[10], 15, -1051523)
  b = ii(b, c, d, a, k[1], 21, -2054922799)
  a = ii(a, b, c, d, k[8], 6, 1873313359)
  d = ii(d, a, b, c, k[15], 10, -30611744)
  c = ii(c, d, a, b, k[6], 15, -1560198380)
  b = ii(b, c, d, a, k[13], 21, 1309151649)
  a = ii(a, b, c, d, k[4], 6, -145523070)
  d = ii(d, a, b, c, k[11], 10, -1120210379)
  c = ii(c, d, a, b, k[2], 15, 718787259)
  b = ii(b, c, d, a, k[9], 21, -343485551)

  x[0] = add32(a, x[0])
  x[1] = add32(b, x[1])
  x[2] = add32(c, x[2])
  x[3] = add32(d, x[3])
}

function cmn(q, a, b, x, s, t) {
  a = add32(add32(a, q), add32(x, t))
  return add32((a << s) | (a >>> (32 - s)), b)
}

function ff(a, b, c, d, x, s, t) {
  return cmn((b & c) | (~b & d), a, b, x, s, t)
}

function gg(a, b, c, d, x, s, t) {
  return cmn((b & d) | (c & ~d), a, b, x, s, t)
}

function hh(a, b, c, d, x, s, t) {
  return cmn(b ^ c ^ d, a, b, x, s, t)
}

function ii(a, b, c, d, x, s, t) {
  return cmn(c ^ (b | ~d), a, b, x, s, t)
}

function md51(s) {
  const txt = ''
  const n = s.length
  const state = [1732584193, -271733879, -1732584194, 271733878]
  let i
  for (i = 64; i <= s.length; i += 64) {
    md5cycle(state, md5blk(s.substring(i - 64, i)))
  }
  s = s.substring(i - 64)
  const tail = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  for (i = 0; i < s.length; i++) tail[i >> 2] |= s.charCodeAt(i) << (i % 4 << 3)
  tail[i >> 2] |= 0x80 << (i % 4 << 3)
  if (i > 55) {
    md5cycle(state, tail)
    for (i = 0; i < 16; i++) tail[i] = 0
  }
  tail[14] = n * 8
  md5cycle(state, tail)
  return state
}

function md5blk(s) {
  /* I figured global was faster.   */
  const md5blks = []
  let i /* Andy King said do it this way. */
  for (i = 0; i < 64; i += 4) {
    md5blks[i >> 2] = s.charCodeAt(i) + (s.charCodeAt(i + 1) << 8) + (s.charCodeAt(i + 2) << 16) + (s.charCodeAt(i + 3) << 24)
  }
  return md5blks
}

const hex_chr = '0123456789abcdef'.split('')

function rhex(n) {
  let s = ''
  let j = 0
  for (; j < 4; j++) s += hex_chr[(n >> (j * 8 + 4)) & 0x0f] + hex_chr[(n >> (j * 8)) & 0x0f]
  return s
}

function hex(x) {
  for (let i = 0; i < x.length; i++) x[i] = rhex(x[i])
  return x.join('')
}

function md5(s) {
  return hex(md51(s))
}

function add32(a, b) {
  return (a + b) & 0xffffffff
}

if (md5('hello') !== '5d41402abc4b2a76b9719d911017c592') {
  function add32(x, y) { // eslint-disable-line
    const lsw = (x & 0xffff) + (y & 0xffff)
    const msw = (x >> 16) + (y >> 16) + (lsw >> 16)
    return (msw << 16) | (lsw & 0xffff)
  }
}
